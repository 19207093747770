import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { ModesoLibXyzFeTemplateComponent } from './components/example/modeso-lib-xyz-fe-template.component';
import { TsfBackButtonComponent } from './components/tsf-back-button/tsf-back-button.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';
@NgModule({
  declarations: [
    ModesoLibXyzFeTemplateComponent,
    TsfBackButtonComponent,
    ExpansionPanelComponent
  ],
  imports: [
    CommonModule,
    InjectedTextFeautureModule, 
    MatExpansionModule
  ],
  exports: [
    ModesoLibXyzFeTemplateComponent,
    TsfBackButtonComponent,
    ExpansionPanelComponent
  ]
})
export class TsfLibCoreFeModule { }

<tsf-core-expansion-panel
[title]="getIsOpenPosStatus() | injectedText | async">
    <ng-container *ngFor="let item of openingHours | keyvalue">
        <div class="open-hours-container"  [ngStyle]="todayDay === +item.key ?
         {'font-weight': '500'} :  {'font-weight': 'normal'}">
            <span class="open-hours-key">
                {{ item.key | dayFromIndex | injectedText | async }}
            </span>
            <span class="open-hours-value-container">
                <ng-container *ngFor="let period of item.value">
                    <span class="open-hours-value" *ngIf="period.open && period.close">
                        {{ period.open | hourFormat}} - {{ period.close | hourFormat }} 
                    </span>
                    <span class="open-hours-value" *ngIf="period.open && !period.close">
                        {{'tsf_pos_details_open_24h' | injectedText | async }} 
                    </span>
                    <span class="open-hours-value" *ngIf="period.close && !period.open">
                        {{'tsf_pos_details_view_closed' | injectedText | async}}
                    </span>
                </ng-container>
            </span>
           
        </div>
    </ng-container>
</tsf-core-expansion-panel>
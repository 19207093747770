<div class="container" (click)="hideFilter($event)"  [ngClass]="{'mobile': isMobile, 
'mobile-search-triggered':((isMobile && searchQuery !== '') || (isMobile && showFilter)), 
'desktop-filter-triggered': !isMobile && showFilter}">

    <div class="search" [ngClass]="{'mobile': isMobile}">
       <div [ngClass]="{'search-wrapper': !isMobile, 'search-wrapper-filtered': showFilter}">
        <div class="search-bar" 
        [ngClass]="{'mobile-style': isMobile, 'mobile-search-triggered': 
        ((isMobile && searchQuery !== '') || (isMobile && showFilter)) }">
                <mat-icon style="padding: 0 10px;">search</mat-icon>
            <input style="width: 200px;" #searchInput type="text" matInput 
                class="search-input" placeholder="{{ 'tsf_search' | injectedText | async }}"
                [ngModel]="searchQuery"  (ngModelChange)="onSearchQueryChange($event)"
                autocomplete="off" >
                <button matSuffix  mat-icon-button  aria-label="Clear" *ngIf="searchQuery" (click)="onSearchQueryChange('')">
                    <img src="assets/group.svg" >
                </button>
            <mat-divider *ngIf="!searchQuery" [vertical]="true" class="divider"></mat-divider>
            <button *ngIf="!searchQuery" mat-icon-button (click)="onShowFiltersClick()">
                <mat-icon  style="margin-right: 15px;">tune</mat-icon>
            </button>
        </div>
        <div class="pos-filter"
         [ngClass]="{'pos-filter-padding': isMobile}" 
         *ngIf="(!isMobile || (showFilter && !searchQuery))">
         <ng-container *ngIf="!hasCategory">
            <span class="title" *ngIf="isMobile">{{'tsf_pos_filter_category' | injectedText | async }}</span>
            <tsf-pos-category-filter *ngIf="isMobile && showFilter || !isMobile && !showFilter"></tsf-pos-category-filter>
         </ng-container>
        <tsf-pos-all-filters *ngIf="showFilter"></tsf-pos-all-filters>
        <div class="pos-filter__actions" *ngIf="showFilter">
            <a class="reset-filter-btn" (click)="resetFilter()">{{'tsf_pos_filter_reset_filter_btn_text' | injectedText | async}}</a>
            <a class="apply-filter-btn" (click)="applyFilter()">{{'tsf_pos_filter_view_filter_result_btn_text' | injectedText | async}}</a>
        </div>
        </div>  
       </div>
       <ng-container *ngIf="!isMobile">
        <div *ngIf="(filteredPointOfSales?.length > 1000 || nearbyLocations?.length > 1000)" 
            class="many-pos-text">
           {{'tsf_pos_excedeed_1000' | injectedText | async}}
        </div>
            <div class="many-pos-text" *ngIf="!showFilter && 
                 (!filteredPointOfSales.length && searchQuery)">
                {{'tsf_search_result_no_result' | injectedText | async}}
            </div>
       </ng-container>
   
      
    <div class="pos-wrapper" [ngClass]="{'scrollable-wrapper': isMobile, 'desktop-padding': !isMobile}">        
    <div class="container__pos-list">
        <tsf-pos-detail-view 
        *ngIf="pointOfSale && !isMobile && !showFilter && showSelectedPos"
        class="pos-detail-view"
        [pointOfSale]="pointOfSale">
            <div class="anchor-row" [ngClass]='{disabled: !locationEnabled}'>
                <img src="assets/twint-icons-directions.svg" />
                <a href="#" style="margin-left: 16px;" (click)="onRouteToPos(pointOfSale)">
                    {{ 'tsf_pos_details_desktopview_showroute_btn' | injectedText | async }}
                </a>
            </div>
        </tsf-pos-detail-view>
    </div>
    <ng-container *ngIf="!isMobile && !showFilter">
        <ng-container *ngIf="searchQuery && !showFilter; else showNearbyArrayLength;"> 
           <div *ngIf="filteredPointOfSales.length > 1" class="count">
               {{filteredPointOfSales.length}} {{ 'tsf_results' | injectedText | async }}:
           </div>
           <div *ngIf="filteredPointOfSales.length === 1" class="count">
               {{filteredPointOfSales.length}} {{ 'tsf_result' | injectedText | async }}:
           </div>
        </ng-container>     
         <ng-template #showNearbyArrayLength>
           <div *ngIf="nearbyLocations?.length > 1 && !searchQuery" class="count">
               {{nearbyLocations.length}} {{ 'tsf_results' | injectedText | async }}:
           </div>
           <div *ngIf="nearbyLocations?.length === 1 && !searchQuery" class="count">
               {{nearbyLocations.length}} {{ 'tsf_result' | injectedText | async }}:
           </div>
         </ng-template>
      </ng-container>
    <ng-container *ngIf="((filteredPointOfSales.length && searchQuery) && !showFilter); else showNearby" class="search-results">
        <ng-container *ngFor="let pointOfSale of filteredPointOfSales" >
            <tsf-search-item-result 
            (pointOfSaleClick)="onPosClicked(pointOfSale)"
            [pointOfSale]="pointOfSale"
            ></tsf-search-item-result>
        </ng-container>
    </ng-container>
    <ng-template #showNearby>
        <ng-container *ngIf="!isMobile && !showFilter && !searchQuery">
            <ng-container *ngFor="let pointOfSale of nearbyLocations" >
                <tsf-point-of-sale-item 
                (pointOfSaleClick)="onPosClicked(pointOfSale)" 
                [pointOfSale]="pointOfSale"></tsf-point-of-sale-item>
            </ng-container>
        </ng-container>
    </ng-template>  
</div>
<div class="container" *ngIf="filters && filters.length">
    <ng-container *ngFor="let filter of filters; trackBy:trackByFilterId">
        <tsf-core-expansion-panel [title]="filter.title">
            <ng-container *ngIf="filter.valueType ==='value' && filter.key!=='openingHours'">
                    <mat-checkbox
                    class="option"
                    [checked]="currentlySelectedFilter[filter.filterId]?.includes(option.filterValueId)" 
                    *ngFor="let option of filter.options" 
                    (change)="checkBoxChange(filter.filterId, option.filterValueId, $event)"
                    [value]="option.filterValueId">
                    <span class="option-title">{{option.value}}</span>  
                </mat-checkbox>    
            </ng-container>
            <mat-radio-group *ngIf="filter.filterId==='openingHours'">
                <mat-radio-button
                class="option"
                *ngFor="let option of filter.options" 
                (change)="radioChange(filter, $event.value)"
                [checked]="currentlySelectedFilter[filter.filterId] === option.filterValueId" 
                [value]="option.filterValueId">
                <span class="option-title">
                    {{ option.value | injectedText | async }}
                </span>  
                <span class="option-title">{{option.value}}</span> 
                </mat-radio-button>    
            </mat-radio-group >
            <mat-radio-group *ngIf="filter.valueType ==='yesOrNo'">
                <mat-radio-button
                class="option"
                [checked]="currentlySelectedFilter[filter.filterId] === true"
                (change)="radioChange(filter, $event.value)"
                [value]="true">
                <span class="option-title"> {{'pos_filter_yes' | injectedText | async}}</span> 
                </mat-radio-button>   
                <mat-radio-button
                class="option"
                [checked]="currentlySelectedFilter[filter.filterId] === false"
                (change)="radioChange(filter, $event.value)"
                [value]="false">
                <span class="option-title">{{'pos_filter_no' | injectedText | async}}</span> 
                </mat-radio-button>  
            </mat-radio-group>
        </tsf-core-expansion-panel>
    </ng-container>
</div>

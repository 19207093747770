<div class="list-pointofsales-container">
  <div style="text-align: end;">
    <button class="add-pointofsale" (click)="addPointofsale()">
      <span>ADD POINT OF SALE</span>
    </button>

    <button class="add-pointofsale" (click)="importPointofsales()">
      <span>IMPORT POINTS OF SALE</span>
    </button>

    <button class="add-pointofsale" (click)="exportPointofsales()">
      <span>EXPORT POINTS OF SALE</span>
    </button>
  </div>
  
  <div *ngIf="pointofsales && pointofsales.length>0">
    <table mat-table [dataSource]="dataSource" class="table">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
           Name
        </th>
        <td mat-cell *matCellDef="let pointofsale">
          <span>
            {{pointofsale.name['en-us']}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="merchantUuid">
        <th mat-header-cell *matHeaderCellDef>
          Merchant UUID
        </th>
        <td mat-cell *matCellDef="let pointofsale">
          {{pointofsale.merchantUuid}}
        </td>
      </ng-container>


      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>
          Category
        </th>
        <td mat-cell *matCellDef="let pointofsale">
          {{pointofsale.category}}
        </td>
      </ng-container>

      <ng-container matColumnDef="legal">
        <th mat-header-cell *matHeaderCellDef>
          Legal
        </th>
        <td mat-cell *matCellDef="let pointofsale">
          {{pointofsale.legal}}
        </td>
      </ng-container>


      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="actions" mat-cell *matCellDef="let pointofsale">
          <button class="states" (click)="editPointofsale(pointofsale.pointofsaleId)">Edit</button>
          <button class="states" (click)="deletePointofsale(pointofsale.pointofsaleId)">Delete</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator  #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>

  <div *ngIf="pointofsales.length===0">
    <span>There are no points of sale yet or there was an error fetching them.</span>
  </div>

</div>
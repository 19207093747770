import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private posProvider: PointOfSalesProvider) {
  }
  ngOnInit() {
  }

  delete() {
    this.posProvider.deleteFilterById(this.data.filterId)
  }
}

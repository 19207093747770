import { Component } from '@angular/core';
import { BasePage } from './pages/base.page';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BasePage {
  title = 'modeso-frontend-project-template';
}

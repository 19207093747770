import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminConfig, AdminConfigService } from './admin.config';
import * as fromReducer from './reducers/admin.reducer';
import { AdminEffects } from './effects/admin.effects';
import { AdminService } from './services/admin.service';
import { AdminManageContentService } from './services/admin-manageContent.service';
import { AdminManageContentEffects } from './effects/admin-manageContent.effect';


@NgModule({
  declarations: [],
  providers: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducer.adminFeatureKey ,fromReducer.reducer),
    EffectsModule.forFeature([AdminEffects, AdminManageContentEffects]),
  ],
  exports: []
})
export class AdminModule {
  static forRoot(config: AdminConfig): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
      providers: [
        AdminService,
        AdminManageContentService,
        {
          provide: AdminConfigService,
          useValue: config
        }
      ]
    };
  }
}

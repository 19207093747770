import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../base.page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginUser, AdminProvider , UserResponse } from '@modeso/tsf-lib-admin-fe';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { environment } from '../../../environments/environment';
import Debug from 'debug';
const debug = Debug('tsf:admin:LoginPage');
@Component({
    selector: 'app-page-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePage implements OnInit {

    loginForm!: FormGroup;
    validLogin = false;
    invalidUser = false;
    loginErrorMessage = 'User name or password is wrong!';
    serverError = 'Server Error';
    isLoginFailed = false;
    isServerError = false;
    isBadRequest = false;
    isLockedOut = false;
    errorMessage!: string | null;
    loginWithNevisUrl = environment.modeso.apiGateway + "admin/saml/login";
    showLoginForm = environment.allowLoginWithUsernameAndPassword;

    constructor(injector: Injector, private router: Router, private adminProvider: AdminProvider,
        private localStorageService: LocalStorageService,private formBuilder: FormBuilder) {
        super(injector);
        this.subscriptions.push(this.adminProvider.getError$().subscribe((errorResponse: any) => {
            if (errorResponse) {
                if (errorResponse.status === 404 ) {
                    this.errorMessage = errorResponse.error.message;
                } else if (errorResponse.status === 400) {
                    this.errorMessage = errorResponse.error.error;
                } else if (errorResponse.status === 403) {
                    this.errorMessage =
                    'You are locked out, refer to the administrator or wait for couple of minutes';
                } else if (errorResponse.status === 440) {
                } else {
                    this.errorMessage = 'Server Error';
                }

            } else {
                this.errorMessage = null;
            }
        }));
    }

    override ngOnInit() {
        this.localStorageService.removeAuthorization();
        this.loginForm = this.formBuilder.group({
            userName: this.formBuilder.control(null, { validators: [Validators.required] }),
            password: this.formBuilder.control(null, { validators: [Validators.required] })
        });
    }

    onLoginUSer() {
        if (this.loginForm.invalid) {
            return;
        }

        const userData: LoginUser = { name: this.loginForm.value.userName, password: this.loginForm.value.password };
        this.subscriptions.push(
            this.adminProvider.login$(userData).pipe(take(2)).subscribe((loginResponse: UserResponse ) => {

                if (loginResponse && loginResponse.user) {
                    this.isLoginFailed = false;
                    this.isServerError = false;
                    this.localStorageService.setAuthorization();
                    this.router.navigate(['/']);
                } else {
                    this.adminProvider.getError$().subscribe((errorResponse: any) => {
                        if (errorResponse) {
                            if (errorResponse.status?.toString() === '404') {
                                this.isLoginFailed = true;
                                this.isServerError = false;
                                this.isBadRequest = false;
                            } else if (errorResponse.status?.toString() === '400') {
                                this.isLoginFailed = false;
                                this.isBadRequest = true;
                                this.isServerError = false;
                            } else {
                                this.isServerError = true;
                                this.isLoginFailed = false;
                                this.isBadRequest = false;
                            }

                        }
                    });
                }
            })
        );

    }


    buttonStatus() {
        return this.loginForm.invalid;
    }

}

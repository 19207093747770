import { IPointOfSaleDto, OpeningHours } from '@modeso/types__tsf-ms-pointofsales';
import { IOpeningHoursVM } from  '../models/openingHoursVM.model';
import moment from 'moment';
export class PointOfSaleUtils {
  public static isOpenNow(openingHours: IOpeningHoursVM, day: number, time: string) {
      if(!openingHours) return false;
      const periodsOfDay: {open: string, close: string}[] = openingHours[day];
      if(!periodsOfDay) return null;
      const isClosed = periodsOfDay.some(period => period.close === '0000');
      if(isClosed) return false;

      const isOpen = periodsOfDay.some((period) => period.open === '0000');
      if(isOpen) return true;
      const period =  periodsOfDay.find((period) => +period.close > +time && +period.open <= +time);
      return period;
  }

  public static isOpen24hrs(openingHours: IOpeningHoursVM, day: number) {
      if(!openingHours) return false;
      const periodsOfDay: {open: string, close: string}[] = openingHours[day];
      if(!periodsOfDay) return null;
      const isClosed = periodsOfDay.some(period => period.close === '0000');
      if(isClosed) return false;
      const isOpen = periodsOfDay.some((period) => period.open === '0000');
      return isOpen;    
  }

  public static filterByOpeningHours(pointOfSales: IPointOfSaleDto[], openingHour: string): IPointOfSaleDto[] {
    let filteredPos: IPointOfSaleDto[] =  [];
    switch (openingHour) {
      case 'all':
          filteredPos = pointOfSales;
        break;
      case 'openNow': 
        filteredPos = pointOfSales.filter(pos => PointOfSaleUtils.isOpenNow
          (pos.openingHours, moment().weekday() - 1, moment().format('HHmm')));
      break;
      case 'open24hrs': 
        filteredPos = pointOfSales.filter(pos => PointOfSaleUtils.isOpen24hrs(pos.openingHours, 
          moment().weekday() - 1));
      break;
      default:
          filteredPos = pointOfSales;
        break;
    }
    return filteredPos;
  }
  public static filterPos(pointOfSales: IPointOfSaleDto[], filterCritera: any): IPointOfSaleDto[] {
    let stillMatches: any = {};
    const newFilteredArray = [];
    if(filterCritera['openingHours']) {
      pointOfSales = PointOfSaleUtils.filterByOpeningHours(pointOfSales, filterCritera['openingHours']);
    }
    for (const pos of pointOfSales){
        const pointOfSale: any = pos;
        if(!pointOfSale.filterProps) continue;
        stillMatches[pointOfSale.pointofsaleId] = true;
        for (const prop in filterCritera) {
          if(prop ==='openingHours') continue;
          let condition;
          if(Array.isArray(filterCritera[prop])) {
           
            if(Array.isArray(pointOfSale.filterProps[prop])){
                condition = filterCritera[prop].length && !pointOfSale.filterProps[prop]?.some((x: string) => filterCritera[prop].includes(x)) 
            }else{
              condition =  filterCritera[prop].length && !filterCritera[prop].includes(pointOfSale.filterProps[prop]) 
            }
            
          }else{
            condition =  filterCritera[prop] !== pointOfSale.filterProps[prop];
          }
          if(condition === true && condition !== undefined) {
            stillMatches[pointOfSale.pointofsaleId] = false;
            break;
          }
        }
        if(stillMatches[pointOfSale.pointofsaleId]) {
          newFilteredArray.push(pointOfSale);
        }
    }
    return newFilteredArray;
  }
}
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ICategory, ILegal, IPaymentPartner, IPointOfSales, IPointOfSaleDto, ICategoryFilter, ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales';
import { Observable } from 'rxjs';
import { PointOfSaleConfigService } from '../tsf-pointofsale.config';

@Injectable({
  providedIn: 'root',
})
export class PointOfSaleService {

  private server = '';
  private readonly route = '/';
  private readonly pointOfSalesMicroServiceName = 'pointofsales';
  private readonly pointOfSalesRoute = '/';
  private readonly pointOfSaleRoute = 'pointofsale';
  private readonly createpointOfSalesRoute = "create";
  private readonly categoryRoute = 'category';
  private readonly paymentPartnerRoute = 'paymentpartner';
  private readonly legalRoute = 'legal';
  private readonly importRoute = 'import';
  private readonly filterRoute = 'filters';

  constructor(
    private http: HttpClient, @Inject(PointOfSaleConfigService) private config:any) {
    this.server = config.apiGateway;
  }

  // pos
  public addOrUpdatePointOfSale(pos:IPointOfSales) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.createpointOfSalesRoute}`;
    return this.http.post<IPointOfSales>(url, pos);
  }

  public getPointOfSales() {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}`;
    return this.http.get<IPointOfSales[]>(url);
  }

  public getPointOfSalesByCategory(category:string): Observable<IPointOfSaleDto[]> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${category}`;
    return this.http.get<IPointOfSaleDto[]>(url);
  }

  public deletePointOfSale(pointofsaleId:string) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.pointOfSaleRoute}${this.route}${pointofsaleId}`;
    return this.http.delete<any>(url);
  }

  public importPointOfSale(body:any) {
    const options = {
      reportProgress: true
    };
    let formData = this.appendBodyToFormData(body);
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.importRoute}`;
    return this.http.post<any>(url, formData,options);
  }

  // categories
  public addOrUpdateCategory(category:ICategory) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.categoryRoute}`;
    return this.http.post<ICategory>(url, category);
  }

  public getCategory() {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.categoryRoute}`;
    return this.http.get<ICategory[]>(url);
  }

  public deleteCategory(categoryId:string) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.categoryRoute}${this.route}${categoryId}`;
    return this.http.delete<any>(url);
  }

  // payment partners
  public addOrUpdatePaymentPartner(paymentPartner:IPaymentPartner) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.paymentPartnerRoute}`;
    return this.http.post<IPaymentPartner>(url, paymentPartner);
  }

  public getPaymentPartner() {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.paymentPartnerRoute}`;
    return this.http.get<IPaymentPartner[]>(url);
  }

  public deletePaymentPartner(paymentPartnerId:string) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.paymentPartnerRoute}${this.route}${paymentPartnerId}`;
    return this.http.delete<any>(url);
  }

  // legal
  public addOrUpdateLegal(legal:ILegal) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.legalRoute}`;
    return this.http.post<ILegal>(url, legal);
  }

  public getLegal() {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.pointOfSalesRoute}${this.legalRoute}`;
    return this.http.get<ILegal[]>(url);
  }

  public deleteLegal(legalId:string) {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.legalRoute}${this.route}${legalId}`;
    return this.http.delete<any>(url);
  }

  private appendBodyToFormData(body:any){
    let formData = new FormData();
    formData.append('category', body.category);
    formData.append('paymentPartner', body.paymentPartner);
  
    if (body.file.fileEntry.isFile) {
      const fileEntry = body.file.fileEntry;
      fileEntry.file((file: File) => {
        formData.append('file', file);
      });
    }
    return formData;
  }
  public getCategoryFilters() {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.categoryRoute}${this.route}filters`;
    return this.http.get<ICategoryFilter[]>(url);
  }
  public getTranslatedCategories(): Observable<ITranslatedCategory[]> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.categoryRoute}${this.route}translated`;
    return this.http.get<ITranslatedCategory[]>(url);
  }

  public getFilters(): Observable<ICategoryFilter[]> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.filterRoute}`;
    return this.http.get<ICategoryFilter[]>(url);
  }
  public addOrUpdateFilters(filter: ICategoryFilter): Observable<ICategoryFilter> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.filterRoute}`;
    return this.http.post<ICategoryFilter>(url, filter);
  }
  public getFilterById(filterId: string): Observable<ICategoryFilter> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.filterRoute}${this.route}${filterId}`;
    return this.http.get<ICategoryFilter>(url);
  }
  public deleteFilterById(filterId: string): Observable<ICategoryFilter> {
    const url = `${this.server}${this.pointOfSalesMicroServiceName}${this.route}${this.filterRoute}${this.route}${filterId}`;
    return this.http.delete<ICategoryFilter>(url);
  }
}

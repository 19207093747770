import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin.action';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import Debug from 'debug';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse, UserRolesResponse } from '../models/administration/userResponse.model';
import { Observable } from 'rxjs';

const debug = Debug('modeso:tsf-lib-admin-fe:AdminProvider');

@Injectable({
    providedIn: 'root'
})
export class AdminProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public getNewAccsessToken$(): void {
        this.store.dispatch(fromActions.getNewAccessToken());
    }

    public login$(user: LoginUser): Observable<UserResponse> {
        this.store.dispatch(fromActions.login({ payload: user }));
        return this.store.pipe(select(fromFeature.selectFeatureLogin), map((loginRespone: any) => {
            if (loginRespone) {
                return loginRespone;
            } else {
                debug('Login response is undefined or null');
            }
        }));
    }

    public logout(): void {
        this.store.dispatch(fromActions.logout());
    }

    public getError$(): Observable<any> {
        return this.store.pipe(select(fromFeature.featureError),
            map((error: any) => {
                return error;
            })
        );
    }

    public resetStore() {
        this.store.dispatch(fromActions.resetStore());
      }

    public getLoggedInUser$(): Observable<any> {
        return this.store.pipe(select(fromFeature.selectFeatureLogin), map((loginRespone: any) => {
            if (loginRespone) {
                return loginRespone;
            } else {
                debug('Login response if undefined or null');
            }
        }));
    }

    public assertLogin$(): Observable<any> {
        this.store.dispatch(fromActions.getUserRoles());
        return this.store.pipe(select(fromFeature.selectFeatureUserRoles));
    }
    public getUserRoles$(): Observable<any> {
        return this.store.pipe(select(fromFeature.selectFeatureUserRoles));
    }
}

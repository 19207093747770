import {
  Component, Injector, ViewChild, OnInit, ChangeDetectorRef
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {
   AdminManageContentProvider, AllTranslationsDetails, TranslationsByKeyResponseDetails
} from '@modeso/tsf-lib-admin-fe';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateLocalizationByKeyDialog } from './updatelocalizationByKeyDialog/updateLocalizationByKeyDialog';
import Debug from 'debug';
import { UserRoles } from './../../shared/enum/userrole.enum';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { BasePage } from '../base.page';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs';


const debug = Debug('modeso:tsf-admin:ManageContentPage');

const filterPredicate = (data: AllTranslationsDetails, filter: string) => {
  if (data.key.toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  if (data.text['en-us'] && data.text['en-us'].toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  if (data.text['de-ch'] && data.text['de-ch'].toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  if (data.text['fr-ch'] && data.text['fr-ch'].toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  if (data.text['it-ch'] && data.text['it-ch'].toLowerCase().indexOf(filter) !== -1) {
    return true;
  }
  return false;
};

@Component({
  selector: 'app-page-manage-content',
  templateUrl: './manageContent.page.html',
  styleUrls: ['./manageContent.page.scss'],
})
export class ManageContentPage extends BasePage implements OnInit {
  localizations: AllTranslationsDetails[] = [];
  dataSource!: MatTableDataSource <AllTranslationsDetails> ;
  selection = new SelectionModel <any> ();

  // isFAQ: boolean = false;
  isLocalizationsError = false;
  isServerError = false;

  manageContentColumns: string[] = ['select', 'Key', 'de-ch', 'fr-ch', 'en-us', 'it-ch'];
  localizationUpdatedNotificationMessage = 'Localization is updated successfully';
  serverErrorMessage = 'Server Error';
  errorMessage: string = '';
  filterValue = '';
  @ViewChild('localizationsPaginator', {
    static: false
  }) localizationsPaginator!: MatPaginator;

  constructor(
    injector: Injector,
    private adminLocalizationProvider: AdminManageContentProvider,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService) {
    super(injector);
  }

  override ngOnInit() {
    // this.isFAQ = this.route.snapshot.data["isFAQ"];
    debug('Before getAllLocalizations');
    this.getAllLocalizations();
    debug('After getAllLocalizations');
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.filterValue = filterValue;
  }

  getAllLocalizations() {
    this.subscriptions.push(
      this.adminLocalizationProvider.getAllLocalizations$().subscribe((localizationsResponse: AllTranslationsDetails[]) => {
        debug('in getAllLocalizations');
        this.isLocalizationsError = false;
        this.isServerError = false;
        this.localizations = localizationsResponse;
        this.dataSource = new MatTableDataSource <AllTranslationsDetails> (this.localizations);
        this.dataSource.filterPredicate = filterPredicate;
        this.changeDetector.detectChanges();
        this.dataSource.paginator = this.localizationsPaginator;
      })
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
  }

  selectedRow() {
    if (!this.selection.selected[0]) {
      return null;
    }
    return this.selection.selected[0];
  }

  localizationButtonStatus() {
    return !this.selectedRow();
  }

  onUpdateLocalization() {
    const selectedLocalization: AllTranslationsDetails = this.selectedRow();
    const dialogRef = this.dialog.open(UpdateLocalizationByKeyDialog, {
      width: '90%',
      data: {
        localization: selectedLocalization
      }
    });

    dialogRef.afterClosed().subscribe((result: TranslationsByKeyResponseDetails) => {
      if (!result) {
        return;
      }

      this.subscriptions.push(
        this.adminLocalizationProvider.updateLocalizationByKey$(result).pipe(first())
          .subscribe((updateLocalizationByKeyResponse: AllTranslationsDetails[]) => {
            this.isLocalizationsError = false;
            this.isServerError = false;
            this.dataSource = new MatTableDataSource<AllTranslationsDetails>(this.localizations);
            this.changeDetector.detectChanges();
            this.dataSource.filterPredicate = filterPredicate;
            this.dataSource.paginator = this.localizationsPaginator;
            this.openSnackBar(this.localizationUpdatedNotificationMessage);
            this.dataSource.filter = this.filterValue;
          }
        )
      );
          
      this.subscriptions.push(
        this.adminLocalizationProvider.getError$().pipe(first()).
          subscribe((errorResponse: HttpErrorResponse | undefined) => {
            if (errorResponse!.status === 404) {
              this.isServerError = false;
              this.isLocalizationsError = true;
              this.errorMessage = errorResponse!.error.message;
            } else {
              this.isLocalizationsError = false;
              this.isServerError = true;
            }
          }
        )
      );
    });
  }

  hasReadPermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.STOREFINDER_STORE_ADMIN
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }

  hasWritePermission(): boolean {
    const role = this.localStorageService.getUserRole();
    const priviledgedRoles = [
      UserRoles.STOREFINDER_STORE_ADMIN
    ];
    return PermissionHelper.hasPermission(role, priviledgedRoles);
  }
}

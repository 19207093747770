import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ReplaySubject, takeUntil } from 'rxjs';
import { IPointOfSaleVM }  from '../../models/pointofsale.model';
@Component({
  selector: 'tsf-point-of-sale-item',
  templateUrl: './point-of-sale-item.component.html',
  styleUrls: ['./point-of-sale-item.component.scss']
})
export class PointOfSaleItemComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() 
  pointOfSaleClick = new EventEmitter();
  @Output() 
  routeToPosClick = new EventEmitter();
  @Input() 
  pointOfSale!: IPointOfSaleVM;
  locationEnabled!: boolean;
  isMobile: boolean = true;
  constructor(private responsive: BreakpointObserver, private pointOfSaleProvider: PointOfSalesProvider){
    this.responsive.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(takeUntil(this.destroyed$)).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
  ngOnInit(): void {
    this.pointOfSaleProvider.getUserLocationState$()
    .pipe(takeUntil(this.destroyed$)).subscribe((locationEnabled) => {
      this.locationEnabled = locationEnabled
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onPosClicked(){
    this.pointOfSaleClick.emit();
  }

  routeToPos($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.routeToPosClick.emit();
  }
}
<mat-expansion-panel  (opened)="panelOpenState = true"
(closed)="panelOpenState = false">
    <mat-expansion-panel-header>
    <mat-panel-title>
        {{ title }}
    </mat-panel-title>
    <mat-panel-description>
        {{description}}
    </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
</mat-expansion-panel>
  
<form [formGroup]="filterForm" 
(ngSubmit)="onSave()"
    *ngIf="filterForm" class="form">
    <mat-form-field appearance="fill" style="margin-right: 20px;">
        <mat-label>Key</mat-label>
        <input formControlName="key" matInput [readonly]="editMode" [disbaled]="editMode"/>
        <mat-error *ngIf="key.errors?.required">Key is required</mat-error>
        <mat-error *ngIf="key.errors?.pattern">Key can't contain white space</mat-error>
        <mat-error *ngIf="key.errors?.duplicate">Key Already Exist</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select formControlName="valueType" (selectionChange)="updateValidations()">
            <mat-option value="value">
                Value
            </mat-option>
            <mat-option value="yesOrNo">
                Boolean
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div 
    [formGroup]="title"
    >
    <h4>Title</h4>
    <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <input formControlName="de-ch" matInput />
          <mat-error>Title is required</mat-error>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <input formControlName="en-us" matInput />
          <mat-error>Title is required</mat-error>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <input formControlName="fr-ch" matInput />
          <mat-error>Title is required</mat-error>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <input formControlName="it-ch" matInput />
          <mat-error>Title is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngFor="let valueForm of multiLanguage.controls; let i = index">
        <div [formGroup]="valueForm" class="paddingButtom">
          <div class="row">
            <mat-form-field appearance="fill">
              <mat-label>Value {{i + 1}} in german</mat-label>
              <input matInput formControlName="de-ch" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Value {{i + 1}} in English</mat-label>
              <input matInput formControlName="en-us" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Value {{i + 1}} in French</mat-label>
                <input matInput formControlName="fr-ch" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Value {{i +1 }} in Italy</mat-label>
                <input matInput formControlName="it-ch" />
            </mat-form-field>
          </div>
        </div>
      </ng-container>
      <div class="row" >
        <button mat-raised-button (click)="onAddValues()" type="button" [disabled]="valueType.value !== 'value'">
            + Add Values
        </button>
    </div>

    <div class="row" >
        <button mat-raised-button type="submit" [disabled]="filterForm.invalid">
            Save
        </button>
    </div>
</form>
<div class="list-legal-container">
    <div>
      <button class="add-legal" (click)="addLegal()">
        <span>ADD LEGAL</span>
      </button>
    </div>
    
    <div *ngIf="legals && legals.length > 0">
      <table mat-table [dataSource]="legals" class="table">
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
             Legal Name
          </th>
          <td mat-cell *matCellDef="let legal">
            <span>
              {{legal.name}}
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="paymentPartner">
          <th mat-header-cell *matHeaderCellDef>
            Payment Partner
          </th>
          <td mat-cell *matCellDef="let legal">
            {{legal.paymentPartner}}
          </td>
        </ng-container>

        <ng-container matColumnDef="externalLegalId">
          <th mat-header-cell *matHeaderCellDef>
            External Legal Id
          </th>
          <td mat-cell *matCellDef="let legal">
            {{legal.externalLegalId}}
          </td>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="actions" mat-cell *matCellDef="let legal">
            <button class="states" (click)="editLegal(legal.legalId)">Edit</button>
            <button class="states" (click)="deleteLegal(legal.legalId)">Delete</button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
    </div>

    <div *ngIf="legals.length===0">
      <span>There are no legals stored yet or there was an error fetching them.</span>
    </div>
  
  </div>
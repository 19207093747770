import { Action, createAction, props } from '@ngrx/store';
import { ILegal } from '@modeso/types__tsf-ms-pointofsales';

// add legal
export const onAddOrUpdateLegal = createAction(
  '[TSF Add/Update legal] add/update legal',
  props<{ payload: ILegal }>()
);

export const onAddOrUpdateLegalSuccess = createAction(
  '[TSF Add/Update legal successfully] add/update legal successfully',
  props<{ payload: ILegal }>()
);

export const onAddOrUpdateLegalFailed = createAction(
  '[TSF Add/Update legal failed] add/update legal failed',
  props<{ payload: any }>()
);

// list legals
export const onGetLegals = createAction(
  '[TSF get legals] get legals'
);

export const onGetLegalsSuccess = createAction(
  '[TSF get legals successfully] get legals successfully',
  props<{ payload: ILegal[] }>()
);

export const onGetLegalsFailed = createAction(
  '[TSF get legals failed] get legals failed',
  props<{ payload: any }>()
);

export const onDeleteLegal = createAction(
  '[TSF Delete legal] delete legal',
  props<{ payload: string }>()
);

export const onDeleteLegalSuccess = createAction(
  '[TSF Delete legal successfully] delete legal successfully',
  props<{ payload: string }>()
);

export const onDeleteLegalFailed = createAction(
  '[TSF Delete legal failed] delete legal failed',
  props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { take } from 'rxjs';
import { 
  ILegal, IPointOfSales, IPaymentPartner, 
  OpeningHours, PlaceOpeningHoursPeriod, 
  ITextLanguage, IAddress, ICategoryForManagement, ICoordinates, ICategoryFilter 
} from '@modeso/types__tsf-ms-pointofsales';
import { BasePage } from '../../base.page';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicDialogComponent } from '../../../shared/basic-dialog/basic-dialog.component';
import Debug from 'debug';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';

const debug = Debug('modeso:tsf-project-admin:AddPointOfSalesComponent');
declare const google: any;

@Component({
  selector: 'app-add-point-of-sales',
  templateUrl: './add-point-of-sale.component.html',
  styleUrls: ['./add-point-of-sale.component.scss']
})
export class AddPointOfSalesComponent extends BasePage implements OnInit {

  pointofsaleForm!: FormGroup;
  pointOfSaleId!: string;
  pointofsaleInit: any = { 
    name: {} as ITextLanguage, address: {} as IAddress, description : {} as ITextLanguage, 
    merchantUuid : '', location: {} as ICoordinates, legal:'', qrCode: '', 
    category:'', terminalId:'', paymentPartner:'', phone:'', website:'', 
    openingHours: {} as OpeningHours,
    filterProps: {}
  };
  legals!: Array<ILegal>;
  categories!: Array<ICategoryForManagement>;
  paymentPartners!: Array<IPaymentPartner>;
  isAddressInvalid: boolean=false;
  isTypeInvalid: boolean=false;
  type: any;
  dialogRef!: MatDialogRef<BasicDialogComponent>;
  readonly regex24HoursTimePattern: string = '^([0-1][0-9]|2[0-3])[0-5][0-9]$';
  filters: ICategoryFilter[] = [];
  posFilterValues: any = {};
  hasOpeninHours: ICategoryFilter | undefined;
  constructor(
    private injector: Injector, 
    private formBuilder: FormBuilder, 
    private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private activatedRouter: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super(injector);
    this.pointOfSalesProvider.dispatchGetAllCategories();
    this.pointOfSalesProvider.getPaymentPartners$().pipe(take(2)).subscribe((paymentPartners) => {
      this.paymentPartners = paymentPartners && paymentPartners.length > 0 ? paymentPartners : [];
    });

    this.pointOfSalesProvider.getLegals$().pipe(take(2)).subscribe((legals) => {
      this.legals = legals && legals.length > 0 ? legals : [];
    });  
    this.pointOfSalesProvider.getCategories$().pipe(take(2)).subscribe((categories: any) => {
      this.categories = categories && categories.length > 0 ? categories : [];
    });
    this.subscriptions.push(this.pointOfSalesProvider.getPointOfSaleErrors().subscribe((error) => {
      if(!error) return;
      if(error.status === 400 && error.error?.key && error.error?.message.includes('Duplicate Key')){
        const key = error.error.key;
        this.pointofsaleForm.get(key)?.setErrors({'duplicate':true});
      } else if (error.status === 500){
        this.dialogRef = this.dialog.open(BasicDialogComponent);
        this.dialogRef.componentInstance.confirmButtonText = "Ok";
        this.dialogRef.componentInstance.message = 'Error Occured While Saving Point Of Sale';
    
      }
    }));
    const pointOfSaleId = this.activatedRouter.snapshot.params['pointofsaleId'];
    if (pointOfSaleId) {
      this.pointOfSaleId = pointOfSaleId;
      this.pointOfSalesProvider.getPointOfSales$().pipe(take(2)).subscribe((pointofsales) => {
        if(pointofsales && pointofsales.length > 0){
          const pointofsale = pointofsales.find( pointofsale => pointofsale.pointofsaleId === pointOfSaleId );
          this.posFilterValues = {...pointofsale?.filterProps} || {};
          this.pointofsaleInit = pointofsale;
          this.pointofsaleInit = {
            ...this.pointofsaleInit, 
            openingHours: this.populateOpeningHoursToFormFormat(pointofsale?.openingHours)
          }
          const category = this.categories.find((x)=> x.categoryId === this.pointofsaleInit!.category);
          if(category) {
            this.filters = category.filters;
            this.hasOpeninHours= category.filters.find((filter) => filter.filterId ==="openingHours");
          }
          this.initFrom();
        }
      });
    } else {
      this.initFrom();
    }
  }

  initFrom() {
    const pointofsale = this.pointofsaleInit;
    this.pointofsaleForm = this.formBuilder.group({
      name: this.formBuilder.group({
        'en-us': this.formBuilder.control(pointofsale.name['en-us'], [Validators.required]),
        'de-ch': this.formBuilder.control(pointofsale.name['de-ch'], [Validators.required]),
        'fr-ch': this.formBuilder.control(pointofsale.name['fr-ch'], [Validators.required]),
        'it-ch': this.formBuilder.control(pointofsale.name['it-ch'], [Validators.required]),
      }),
      description: this.formBuilder.group({
        'en-us': this.formBuilder.control(pointofsale.description?pointofsale.description['en-us'] : null),
        'de-ch': this.formBuilder.control(pointofsale.description?pointofsale.description['de-ch'] : null),
        'fr-ch': this.formBuilder.control(pointofsale.description?pointofsale.description['fr-ch'] : null),
        'it-ch': this.formBuilder.control(pointofsale.description?pointofsale.description['it-ch'] : null),
      }),
      address: this.formBuilder.group({
        street: this.formBuilder.control(pointofsale.address.street, Validators.required),
        zipCode: this.formBuilder.control(pointofsale.address.zipCode, [Validators.required, 
          Validators.pattern("^[0-9]*$"), Validators.maxLength(4), Validators.minLength(4)]),
        city: this.formBuilder.control(pointofsale.address.city, [Validators.required,
          Validators.pattern("^([^0-9]*)$")]),
      }),
      location: this.formBuilder.group({
        latitude: this.formBuilder.control({value:pointofsale.location.latitude, disabled: true}),
        longitude: this.formBuilder.control({value:pointofsale.location.longitude, disabled: true})
      }),
      phone: this.formBuilder.control(pointofsale.phone),
      website: this.formBuilder.control(pointofsale.website),
      merchantUuid: this.formBuilder.control(pointofsale.merchantUuid, Validators.required),
      qrCode: this.formBuilder.control(pointofsale.qrCode, Validators.required),
      legalId: this.formBuilder.control(pointofsale.legal, Validators.required),
      categoryId: this.formBuilder.control(pointofsale.category, [Validators.required]),
      terminalId: this.formBuilder.control(pointofsale.terminalId, [Validators.required]),
      paymentPartnerId: this.formBuilder.control(pointofsale.paymentPartner),
      openingHours: this.formBuilder.group({
        periods: this.createPeriodsFormArray(pointofsale.openingHours)
      })
    });
  }

  createPeriodsFormArray(openingHours: any): FormArray {
    if(!openingHours?.periods){
      return this.formBuilder.array([]);
    }
    return this.formBuilder.array(
      openingHours?.periods.map( (period: any) => {
        return this.formBuilder.group({
          days: this.formBuilder.control(period.days),
          open: this.formBuilder.control(period.open, [Validators.pattern(this.regex24HoursTimePattern)]),
          close: this.formBuilder.control(period.close, [Validators.pattern(this.regex24HoursTimePattern)]),
        });
      })
    ); 
  }

  get name() {
    return this.pointofsaleForm.get('name');
  }

  get description() {
    return this.pointofsaleForm.get('description');
  }

  get merchantUUID() {
    return this.pointofsaleForm.get('merchantUuid');
  }

  get address() {
    return this.pointofsaleForm.get('address');
  }

  get legalId() {
    return this.pointofsaleForm.get('legalId');
  }

  get location() {
    return this.pointofsaleForm.get('location');
  } 
  
  get qrCode() {
    return this.pointofsaleForm.get('qrCode');
  }

  get categoryId() {
    return this.pointofsaleForm.get('categoryId');
  }

  get terminalId() {
    return this.pointofsaleForm.get('terminalId');
  }

  get paymentPartnerId() {
    return this.pointofsaleForm.get('paymentPartnerId');
  }

  get phone() {
    return this.pointofsaleForm.get('phone');
  }

  get website() {
    return this.pointofsaleForm.get('website');
  }

  get openingHours(): FormGroup {
    return this.pointofsaleForm.get('openingHours') as FormGroup;
  }

  get periods(): FormArray {
    return this.pointofsaleForm.get('openingHours')?.get('periods') as FormArray;
  }

  populateOpeningHoursToFormFormat(openingHours: OpeningHours | undefined) {
    let formPeriods: { days: number[], open: string, close: string }[] = [];

    openingHours?.periods.forEach(period => {
      const dayAndTime = period.open? period.open : period.close;
      const day = dayAndTime.day;

      const formPeriod = formPeriods.find(formPeriod => {
        return formPeriod.open === period.open?.time && formPeriod.close === period.close?.time
      });

      if (formPeriod) {
        formPeriod.days.push(day);
      } else {
        formPeriods.push({ 
          days: [day], 
          open: period.open?.time,
          close: period.close?.time   
        });
      }
    });

    return { periods: formPeriods }
  }
  
  populateOpeningHoursFromFormFormat() : OpeningHours {
    let periods: PlaceOpeningHoursPeriod[] = [];

    this.periods.controls.forEach(periodFormControl => {
      let dayFormControl = periodFormControl.get('days') as FormControl;
      let openFormControl = periodFormControl.get('open') as FormControl;
      let closeFormControl = periodFormControl.get('close') as FormControl;
  
      let days = dayFormControl.value as number[];
      days.forEach(day => {
        periods.push({
          ...openFormControl.value && { open: { day: day, time: openFormControl.value }},
          ...closeFormControl.value && { close: { day: day, time: closeFormControl.value }}
        });
      });
    });

    return { periods: periods } as OpeningHours;
  }

  submit() {
    let pointofsale: IPointOfSales= {
      name: this.name?.value,
      merchantUuid: this.merchantUUID?.value,
      address: this.address?.value,
      location: this.location?.value,
      description: this.description?.value,
      legal: this.legalId?.value,
      qrCode: this.qrCode?.value,
      terminalId: this.terminalId?.value,
      category: this.categoryId?.value,
      paymentPartner: this.paymentPartnerId?.value,
      website: this.website?.value,
      phone: this.phone?.value,
      openingHours: this.populateOpeningHoursFromFormFormat(),
      filterProps: this.posFilterValues
    };
    if (this.pointOfSaleId) {
      pointofsale.pointofsaleId = this.pointOfSaleId;
    }
    this.pointOfSalesProvider.addOrUpdatePointOfSale$(pointofsale);
  }

  backToListPointOfSalesUrl(){
    this.router.navigate(['/manage-pointofsales']);
  }
  

  onOpen24hCheckboxChange(event: MatCheckboxChange, periodForm: FormGroup){
    if(event.checked){
      periodForm.get('open')?.setValue("0000");
      periodForm.get('close')?.setValue(null);
    } else {
      periodForm.get('open')?.setValue("0000");
      periodForm.get('close')?.setValue("0000");
    }
  }

  onClose24hCheckboxChange(event: MatCheckboxChange, periodForm: FormGroup){
    if(event.checked){
      periodForm.get('open')?.setValue(null);
      periodForm.get('close')?.setValue("0000");
    } else {
      periodForm.get('open')?.setValue("0000");
      periodForm.get('close')?.setValue("0000");
    }
  }

  onAddHours(){
    this.periods.push(this.formBuilder.group({
      days: this.formBuilder.control([]),
      open: this.formBuilder.control("0000", [Validators.pattern(this.regex24HoursTimePattern)]),
      close: this.formBuilder.control("0000", [Validators.pattern(this.regex24HoursTimePattern)]),
    }));
  }

  onRemoveHours(periodFormIndex: number){
    this.periods.removeAt(periodFormIndex);
  }
 
  onChange(){
    let geocoder = new google.maps.Geocoder();
    this.isAddressInvalid=false;
    this.isTypeInvalid=false;
    if(this.address?.value.street && this.address.value.zipCode && this.address.value.city && this.address.valid){
      let address = `${this.address?.value.street} ${this.address?.value.zipCode} ${this.address?.value.city}`
      geocoder.geocode( { address },(results:any, status:any) => {
        const control =  this.pointofsaleForm.get('location') as FormGroup;
        if (status == google.maps.GeocoderStatus.OK) {          
          if(results[0].partial_match || (!results[0].types.includes("street_address") && !results[0].types.includes("premise") && !results[0].types.includes("route"))){
            this.isTypeInvalid=true;
            this.type = results[0].types.join(',')
          }
          control.setValue({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
        } else {
          this.isAddressInvalid=true;
        }
      });
    }
  }

  onCategoryChange(){ 
    const category = this.categories.find((cat) => cat.categoryId === this.categoryId?.value);
    if(!category)  return;
    this.filters = category.filters;
    this.hasOpeninHours = category.filters.find(x => x.key==='openingHours') 
  }

  radioChange($event: any, filterId: string) {
    this.posFilterValues[filterId] = $event.value;
  }

  checkBoxChange($event: any, filterId: string, filterValueId: string) {
    if($event.checked) {
      if(!this.posFilterValues[filterId]) {
        this.posFilterValues[filterId] = [];
      }
      this.posFilterValues[filterId].push(filterValueId);
    } else {
      const filterIndex = this.posFilterValues[filterId].indexOf(filterValueId);
      this.posFilterValues[filterId].splice(filterIndex, 1)
    }
   
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserRoles } from '../enum/userrole.enum';
import { LocalStorageService } from '../services/localStorage.service';
import { PermissionHelper } from '../util/permission.helper';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) { }
    canActivate(): boolean {
        if (this.localStorageService.isUserAuthorized()) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class PointOfSalesGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) { }
    canActivate(): boolean {
        if (this.hasWritePermissions()) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
    hasWritePermissions() {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.STOREFINDER_STORE_ADMIN,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

}

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) { }
    canActivate(): boolean {
        if (this.localStorageService.isUserAuthorized()) {
            this.router.navigate(['/']);
            return false;
        } else {
           
            return true;
        }
    }
}


<mat-chip-list selectable multiple>
    <mat-chip (click)="toggleSelection(c)" 
    #c="matChip" 
    *ngFor="let category of categories$ | async" 
    [selected]="category.selected"
    [value]="category.categoryId">
      <mat-chip-avatar>
          <img [src]="category.icon"/>
      </mat-chip-avatar>
      <span class="mat-chip-text">  {{category.description}}</span>
      <mat-icon *ngIf="c.selected" class="cancel-icon">cancel</mat-icon>
    </mat-chip>
</mat-chip-list>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { AllTranslationsResponse } from '../models/manageContent/allTranslationsResponse.model';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';
import { Config } from '@modeso/modeso-lib-core-fe';

@Injectable({
    providedIn: 'root',
})
export class AdminManageContentService {
    private server = '';
    private localizationsMicroserviceName = 'localization';
    private readonly route = '/';
    private readonly getAllLocalizationsUrl = 'all/languages';

    constructor(
        private http: HttpClient, 
        @Inject(AdminConfigService) private config: Config
    ) {
        this.server = config.apiGateway;
    }
    getAllLocalizations() {
        const url = `${this.server}${this.localizationsMicroserviceName}${this.route}${this.getAllLocalizationsUrl}`;
        return this.http.get<AllTranslationsResponse>(url);
    }
    updateLocalizationByKey(translation: { payload: TranslationsByKeyResponseDetails }) {
        const url = `${this.server}${this.localizationsMicroserviceName}${this.route}${translation.payload.key}`;
        return this.http.put(url,  translation.payload.text);
    }
}

<div class="container">
  <form class="login-form" [formGroup]="loginForm" >
    <div *ngIf="showLoginForm">
      <div class="form-group">
        <div class="input-wrapper">
          <input
            id="userName"
            class="form-control"
            type="text"
            formControlName="userName"
            placeholder="User Name"
          />
        </div>
      </div>
  
      <div class="form-group">
        <div class="input-wrapper">
          <input
            id="password"
            class="form-control"
            type="password"
            formControlName="password"
            placeholder="Password"
          />
        </div>
      </div>
      <div class="row errorMessages">
        <span class="unauthorized">{{errorMessage}}</span>
      </div>

      <div class="login-button">
        <button
          id="saveAddressButton"
          [disabled]="buttonStatus()"
          (click)="onLoginUSer()"
        >
          Login
        </button>
      </div>
    </div>


    <div class="login-button">
      <button><a href="{{loginWithNevisUrl}}">Login with Nevis</a></button>
    </div>
  </form>

  
</div>

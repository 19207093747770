import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../base.page';

@Component({
  selector: 'app-page-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage extends BasePage implements OnInit {

  constructor(
    injector: Injector
  ) {
    super(injector)
  }

  override ngOnInit(): void {
  }

}

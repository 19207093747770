import { Injectable } from '@angular/core';
import * as fromPointOfSalesActions from '../actions/pointofsales.action';
import * as fromCategoriesActions from '../actions/category.action';
import * as fromPaymentPartnerActions from '../actions/paymentPartner.action';
import * as fromLegalActions from '../actions/legal.action';
import * as fromFilterActions from '../actions/filter.action';
import * as fromFeature from '../reducers/pointofsales.reducer';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICategory, ICategoryFilter, ILegal, IPaymentPartner, IPointOfSales, ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales'
import { IPointOfSaleDto } from '@modeso/types__tsf-ms-pointofsales';
@Injectable({
    providedIn: 'root'
})
export class PointOfSalesProvider {

    constructor(private store: Store<fromFeature.AppState>) { }

    public addOrUpdatePointOfSale$(body: IPointOfSales ): Observable<string> {
      this.store.dispatch(fromPointOfSalesActions.onAddOrUpdatePointOfSale({ payload: body }));
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSales),
        map((res: any) => {
          return res;
        })
      )
    };

    public deletePointOfSale$(pointofsaleId: string) {
      this.store.dispatch(fromPointOfSalesActions.onDeletePointOfSale({ payload: pointofsaleId }));
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSales),
        map((res: any) => {
          return res;
        })
      )
    }

    public getPointOfSales$() {
      this.store.dispatch(fromPointOfSalesActions.onGetPointOfSales());
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSales));
    }

    public addOrUpdateCategory$(body: ICategory): Observable<string> {
      this.store.dispatch(fromCategoriesActions.onAddOrUpdateCategory({ payload: body }));
      return this.store.pipe(select(fromFeature.selectFeatureCategories),
        map((res: any) => {
          return res;
        })
      )
    };

    public getCategories$() {
      return this.store.pipe(select(fromFeature.selectFeatureCategories));
    }

    public dispatchGetAllCategories() {
      this.store.dispatch(fromCategoriesActions.onGetCategories());
    }

    public deleteCategory$(categoryId: string) {
      this.store.dispatch(fromCategoriesActions.onDeleteCategory({ payload: categoryId }));
      return this.store.pipe(select(fromFeature.selectFeatureCategories),
        map((res: any) => {
          return res;
        })
      )
    }

    public addOrUpdatePaymentPartner$(body: IPaymentPartner ): Observable<string> {
      this.store.dispatch(fromPaymentPartnerActions.onAddOrUpdatePaymentPartner({ payload: body }));
      return this.store.pipe(select(fromFeature.selectFeaturePaymentPartners),
        map((res: any) => {
          return res;
        })
      )
    }

    public getPaymentPartners$() {
      this.store.dispatch(fromPaymentPartnerActions.onGetPaymentPartners());
      return this.store.pipe(select(fromFeature.selectFeaturePaymentPartners));
    }

    public deletePaymentPartner$(paymentPartnerId: string) {
      this.store.dispatch(fromPaymentPartnerActions.onDeletePaymentPartner({ payload: paymentPartnerId }));
      return this.store.pipe(select(fromFeature.selectFeaturePaymentPartners),
        map((res: any) => {
          return res;
        })
      )
    }

    public addOrUpdateLegal$(body: ILegal ): Observable<string> {
      this.store.dispatch(fromLegalActions.onAddOrUpdateLegal({ payload: body }));
      return this.store.pipe(select(fromFeature.selectFeatureLegals),
        map((res: any) => {
          return res;
        })
      );
    }

    public getLegals$() {
      this.store.dispatch(fromLegalActions.onGetLegals());
      return this.store.pipe(select(fromFeature.selectFeatureLegals));
    }

    public deleteLegal$(legalId: string) {
      this.store.dispatch(fromLegalActions.onDeleteLegal({ payload: legalId }));
      return this.store.pipe(select(fromFeature.selectFeatureLegals),
        map((res: any) => {
          return res;
        })
      )
    }

    public dispatchGetAllPointOfSales$(category:string) {
      this.store.dispatch(fromPointOfSalesActions.onGetPointOfSalesByCategory({payload:category}));
    }

    public getAllPointOfSales$(): Observable<IPointOfSaleDto[]> {
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSalesByCategory));
    }

    public importPointOfSales$(body:any): Observable<any> {
      this.store.dispatch(fromPointOfSalesActions.onImportPointOfSales({ payload: body }));
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSalesError),
        map((response: any) => {
          return response;
        })
      );
    }

    public getPointOfSaleErrors(): Observable<any> {
      return this.store.pipe(select(fromFeature.selectFeaturePointOfSalesError),
        map((error: any) => {
          return error;
        })
      );
    }

    public dispatchGetAllFilterCategories() {
      this.store.dispatch(fromCategoriesActions.onGetCategoryFilter());
    }


    public getAllFilterCategories$(): Observable<ICategoryFilter[] | undefined> {
      return this.store.pipe(select(fromFeature.selectFeatureCategoryFilter));
    }

    public dispatchFilterPosByCategory(categories: string[]) {
      this.store.dispatch(fromPointOfSalesActions.onSetFilterPosByCategories({payload: categories}));
    }

    public applyFilterPosByCategory() {
      this.store.dispatch(fromPointOfSalesActions.onApplyFilterPosByCategories());
    }

    public dispatchGetTranslatedCategories() {
      this.store.dispatch(fromCategoriesActions.onGetTranslatedCategories());
    }

    public getTranslatedCategories$(): Observable<ITranslatedCategory[] | undefined> {
      return this.store.pipe(select(fromFeature.selectFeatureTranslatedCategories));
    }

    public getSelectedCategoriesIds$(): Observable<string[] | undefined> {
      return this.store.pipe(select(fromFeature.selectFeatureCurrentSelectedCategoriesIds));
    }

    public getCurrentSelectedCategories$(categoriesIds: string[]): Observable<ITranslatedCategory[] | undefined> {
      return this.store.pipe(select(fromFeature.selectFeatureGetSelectedCategories(categoriesIds)));
    }

    public dispatchSetFilterPos(obj: any){
      this.store.dispatch(fromPointOfSalesActions.onSetFilterPos({payload: obj}));
    }

    public dispatchApplyFilterPos() {
      this.store.dispatch(fromPointOfSalesActions.onApplyFilterPos());
    }

    public getCurrentlyAppliedFilter$() {
      return this.store.pipe(select(fromFeature.selectFeatureCurrentAppliedFilter));
    }

    public getCurrentFilterVisibilityState$() {
      return this.store.pipe(select(fromFeature.selectFeatureFilterVisibility));
    }

    public dispatchApplyFilterVisibility(visiblity: boolean) {
      this.store.dispatch(fromPointOfSalesActions.onShowOrHideFilterPos({payload: visiblity}));
    }

    public dispatchSetUserLocationEnabled(userLocationEnabled: boolean) {
      this.store.dispatch(fromPointOfSalesActions.onSetUserLocationEnabled({payload: userLocationEnabled}));
    }

    public getUserLocationState$(): Observable<boolean> {
      return this.store.pipe(select(fromFeature.selectUserLocationEnabled));
    }

    public dispatchGetAllFilters() {
      this.store.dispatch(fromFilterActions.onGetAllFilters());
      return this.store.pipe(select(fromFeature.selectFeatureFilters));
    }

    public addOrUpdateCategoryFilter(categoryFilter: ICategoryFilter) {
      this.store.dispatch(fromFilterActions.onAddOrUpdateFilter({payload: categoryFilter}));
    }

    public getFilterById(filterId: string) {
      this.store.dispatch(fromFilterActions.onGetFilterById({payload: filterId}));
      return this.store.pipe(select(fromFeature.selectFeatureFilter));
    }


    public getFilterError$() {
      return this.store.pipe(select(fromFeature.selectFeatureFilterError));
    }

    public deleteFilterById(filterId: string) {
      this.store.dispatch(fromFilterActions.ondeleteFilterById({payload: filterId}));
    }

}

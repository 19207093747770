import { Action, createAction, props } from '@ngrx/store';
import { ICategoryFilter } from '@modeso/types__tsf-ms-pointofsales';

// add Filter 
export const onAddOrUpdateFilter = createAction(
  '[TSF Add/Update Filter] add/update Filter',
  props<{ payload: ICategoryFilter }>()
);

export const onAddOrUpdateFilterSuccess = createAction(
  '[TSF Add/Update Filter successfully] add/update Filter successfully',
  props<{ payload: ICategoryFilter }>()
);

export const onAddOrUpdateFilterFailed = createAction(
  '[TSF Add/Update Filter failed] add/update Filter failed',
  props<{ payload: any }>()
);

// list filters
export const onGetAllFilters = createAction(
  '[TSF get filters successfully] get filters',
);

export const onGetFiltersSuccess = createAction(
    '[TSF get filters] get filters successfully',
    props<{ payload: ICategoryFilter[] }>()
);
  
export const onGetFiltersFailed = createAction(
  '[TSF get filters failed] get filters failed',
  props<{ payload: any }>()
);

// by Id
export const onGetFilterById = createAction(
  '[TSF get filters] get filter by Id',
  props<{ payload: string }>()
);

export const onGetFilterByIdSuccess = createAction(
    '[TSF get filters] get filter by Id successfully',
    props<{ payload: ICategoryFilter }>()
);
  
export const onGetFilterByIdFailed = createAction(
  '[TSF get filters] get filter by Id failed',
  props<{ payload: any }>()
);

export const ondeleteFilterById = createAction(
  '[TSF delete filters] delete filter by Id',
  props<{ payload: string }>()
);

export const ondeleteFilterByIdSuccess = createAction(
    '[TSF delete filters] delete filter by Id successfully',
    props<{ payload: ICategoryFilter }>()
);
  
export const ondeleteFilterByIdFailed = createAction(
  '[TSF delete filters b failed Id] delete filter by Id failed',
  props<{ payload: any }>()
);


export interface ActionWithPayload<T> extends Action {
  payload: T;
}

<div
    class="pos-details"
    *ngIf="pos"
>
<div class="pos-details__main-info">
    <img [src]="pos.logo" *ngIf="pos.logo" class="pos-details__main-info__logo"/>
    <div class="pos-details__main-info__text">
       <div class="header">
        <div class="category">{{pos.categoryName}}</div>
    </div>    
    <div class="address">
        <div class="pos-name">{{pos.pointName}}</div>
        <div class="pos-address">{{pos.pointAddress.street}}, {{pos.pointAddress.zipCode}} {{pos.pointAddress.city}}</div>
    </div>
</div>
</div>
<div class="pos-details__service-info">
    <div class="row" *ngIf="Object.keys(pos.openingHours)?.length">
        <img src="assets/opening_hours.svg" style="align-self: flex-start; margin-top: 10px;"/>
        <tsf-pos-opening-hours [openingHours]="pos.openingHours"
        ></tsf-pos-opening-hours>
    </div>
    <div  class="row" *ngIf="pos.website">
      <img src="assets/website.svg" /><a style="text-decoration: underline;" (click)="redirectToWebsite(pos.website)">{{pos.website}}</a>
    </div>
    <div class="row" *ngIf="pos.phone">
        <img src="assets/telephone.svg" /> <a href="tel:{{pos.phone}}">{{pos.phone}}</a >
    </div>
</div>
    <ng-content></ng-content>
</div>
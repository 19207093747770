import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategory, ICategoryFilter } from '@modeso/types__tsf-ms-pointofsales';
import { Observable, take } from 'rxjs';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { BasePage } from '../../base.page';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { v4 as uuidv4 } from "uuid";
import { IUploadURL } from '../../../shared/model/uploadUrl.model';
import { UploadService } from '../../../shared/services/upload.service';
import { FileUploadUtil } from '../../../shared/util/fileutil';
@Component({
  selector: 'app-add-update-category',
  templateUrl: './add-update-category.component.html',
  styleUrls: ['./add-update-category.component.scss']
})
export class AddUpdateCategoryComponent extends BasePage implements OnInit {
  categoryForm!: FormGroup;
  categoryId!: string;
  categoriesInit: any = { name: '', buttonText : {}, description: {}};
  uploadedIcon: any;
  error = false;
  totalFilesSize = 0;
  fileReader!: string | ArrayBuffer | null;
  fileUtil:any;
  categoryFilters$: Observable<ICategoryFilter[] |  undefined>;
   
  constructor(private injector: Injector,private formBuilder: FormBuilder, private router: Router,
              private pointOfSalesProvider: PointOfSalesProvider,
              private activatedRouter: ActivatedRoute,
              private uploadService: UploadService) {
                super(injector);
                this.pointOfSalesProvider.dispatchGetAllCategories();
                this.pointOfSalesProvider.dispatchGetAllFilterCategories();
                const categoryId = this.activatedRouter.snapshot.params['categoryId'];
                this.categoryFilters$ = this.pointOfSalesProvider.getAllFilterCategories$();
                this.fileUtil = new FileUploadUtil();
                if (categoryId) {
                  this.categoryId = categoryId;
                  this.pointOfSalesProvider.getCategories$().pipe(take(2)).subscribe((categories) => {
                    if(categories && categories.length > 0){
                     const category = categories.find((category) => category.categoryId === categoryId);
                     this.categoriesInit = category;
                     this.initFrom();
                    }

                  });
                } else {
                   this.initFrom();
              }
            }

  initFrom() {
    const category = this.categoriesInit;
    this.fileReader = this.categoriesInit.icon;
    this.categoryForm = this.formBuilder.group({
      name: this.formBuilder.control(category.name, Validators.required),
      buttonText: this.formBuilder.group({
        'en-us': this.formBuilder.control(category.buttonText['en-us'], Validators.required),
        'de-ch': this.formBuilder.control(category.buttonText['de-ch'], Validators.required),
        'fr-ch': this.formBuilder.control(category.buttonText['fr-ch'], Validators.required),
        'it-ch': this.formBuilder.control(category.buttonText['it-ch'], Validators.required),
      }),
      description: this.formBuilder.group({
        'en-us': this.formBuilder.control(category.description ? category.description ['en-us'] : "", Validators.required),
        'de-ch': this.formBuilder.control(category.description ? category.description['de-ch']: "", 
        Validators.required),
        'fr-ch': this.formBuilder.control(category.description ? category.description['fr-ch'] : "", Validators.required),
        'it-ch': this.formBuilder.control(category.description ? category.description['it-ch'] : "", Validators.required),
      }),
      tileTitle: this.formBuilder.group({
        'en-us': this.formBuilder.control(category.tileTitle ? category.tileTitle ['en-us'] : ""),
        'de-ch': this.formBuilder.control(category.tileTitle ? category.tileTitle['de-ch']: ""),
        'fr-ch': this.formBuilder.control(category.tileTitle ? category.tileTitle['fr-ch'] : ""),
        'it-ch': this.formBuilder.control(category.tileTitle ? category.tileTitle['it-ch'] : ""),
      }),
      filtersIds: this.formBuilder.control(category.filtersIds || []),
    })
  }


  get name() {
    return this.categoryForm.get('name');
  }
  get buttonText() {
    return this.categoryForm.get('buttonText');
  }

  get description() {
    return this.categoryForm.get('description');
  }

  get filtersIds() {
    return this.categoryForm.get('filtersIds');
  }

  get tileTitle() {
    return this.categoryForm.get('tileTitle');
  }


  submit() {
    if(this.uploadedIcon){
      this.uploadService.getUploadUrl(this.uploadedIcon.fileName)
      .subscribe((urlObj: IUploadURL) => {
        this.uploadedIcon.url = urlObj.fetchUrl
        this.uploadService.uploadFile(urlObj.uploadUrl, this.fileUtil.renameFile(this.uploadedIcon.file, this.uploadedIcon.fileName), urlObj.contentType).subscribe((response) => {});
        this.saveCategory();
      });
    }else{
      this.saveCategory();
    }   
  }

  saveCategory(){
    let category: ICategory = {
        name: this.name?.value,
        buttonText: this.buttonText?.value,
        icon:this.uploadedIcon ? this.uploadedIcon.url: this.fileReader,
        description: this.description?.value,
        filtersIds: this.filtersIds?.value,
        tileTitle: this.tileTitle?.value
      };
    if (this.categoryId) {
        category.categoryId = this.categoryId;
    }
    this.pointOfSalesProvider.addOrUpdateCategory$(category);
    this.backToListCategoriesUrl();
  }

  backToListCategoriesUrl(){
    this.router.navigate(['/manage-categories']);
  }


  public dropped(files: NgxFileDropEntry[]): void {
    this.error = false;
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file( async (file: File) => {
        if (!this.fileUtil.isFileSizeAllowed(file.size) || ! await this.fileUtil.validateFile(file)) {
          this.error = true;
          return;
        }
        const fileUuid = uuidv4();
        const fileName = fileUuid + '.' + droppedFile.relativePath.split('.').pop();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadedIcon = {
            file,
            fileName,
          };
          this.fileReader = reader.result;
        };
      });
    }
  }
}
<div class="activate-location_container" (swipe)="onSwipe($event)"> 
        <div class="line-wrapper" (tap)="isInit = true"> 
            <span class="line"></span>
        </div>
        <div class="store-nearby">
          {{ tileTitle ? tileTitle : ('tsf_tile_title_stores_nearby' | injectedText | async )}} 
        </div>
        <div  *ngIf="!locationEnabled; else checkStoresExist" style="padding: 20px;">
            <div class="your-location">{{'tsf_landing_buy_tile_location_cant_be_detected' | injectedText | async}}</div>
        </div>
        <ng-template #checkStoresExist>
            <div  *ngIf="locationEnabled && !pointOfSales.length; else storesAreInThousands" style="padding: 16px;">
                <div class="no-stores">{{'tsf_main_tile_mobile_view_no_stores_nearby' | injectedText | async}}</div>
            </div>
        </ng-template>
        <ng-template #storesAreInThousands>
            <div  *ngIf="locationEnabled && pointOfSales.length > 1000; else nearbyLocation" style="padding: 16px;">
                <div class="no-stores">{{'tsf_pos_excedeed_1000' | injectedText | async}}</div>
            </div>
        </ng-template>
        <ng-template #nearbyLocation>
            <div class="scrollable-area" (scroll)="onScroll($event)">
                <tsf-point-of-sale-item
                    *ngIf="isInit && pointOfSales && pointOfSales.length; else showRestOfSales" 
                    (click)="onPointOfSaleClicked(pointOfSales[0])"  
                    (routeToPosClick)="onRouteToPos(pointOfSales[0])"
                    [pointOfSale]="pointOfSales[0]">
                </tsf-point-of-sale-item>
                <ng-template #showRestOfSales>
                    <ng-container *ngFor="let point of pointOfSales;">
                        <tsf-point-of-sale-item
                        (routeToPosClick)="onRouteToPos(point)"
                        (click)="onPointOfSaleClicked(point)" 
                        [pointOfSale]="point">
                        </tsf-point-of-sale-item>
                    </ng-container>
                </ng-template>
            </div>
        </ng-template>


</div>





import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tsf-core-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  @Input() title!: string;
  @Input() description!: string;
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}

<div class="payment-partner-container">
  <form class="form" [formGroup]="paymentPartnerForm" (ngSubmit)="submit()" *ngIf="paymentPartnerForm">

        <div class="row">
          <!-- de-ch -->
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput />
            <mat-error>Name is required</mat-error>
          </mat-form-field>
        </div>

    <div class="row">
      <mat-form-field appearance="fill" style="width:422px">
        <mat-label>Check-in URL</mat-label>
        <input formControlName="checkInUrl" matInput />
        <mat-error *ngIf="paymentPartnerForm.get('checkInUrl').errors?.required">
          Check-in URL is required
        </mat-error>
        <mat-error *ngIf="paymentPartnerForm.get('checkInUrl').errors?.pattern">
          Check-in URL is invalid
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row" >
    
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input formControlName="username" matInput />
        <mat-error>Username is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password" />
        <mat-error>Password is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Confirm Password</mat-label>
        <input formControlName="confirmPassword" matInput  type="password" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="paymentPartnerForm.hasError('doesntMatch')" >Passwords don't match</mat-error>
      </mat-form-field>
    </div>


    <div style="margin-top: 10px;">
      <button type="submit" color="success" mat-button [disabled]="paymentPartnerForm.invalid">
        Save
      </button>
      <button type="submit" (click)="backToListPaymentPartnersUrl()" mat-raised-button style="margin-left: 10px">Cancel</button>
    </div>
  </form>
</div>

import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as AdminFromActions from './../actions/admin.action';
import { UserResponse, UserRolesResponse } from '../models/administration/userResponse.model';
import * as fromAdminManageContentActions from './../actions/admin-manageContent.action';
import { AllTranslationsDetails } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { reportResponse } from '../services/admin-report.service';

export const adminFeatureKey = 'modesoAdminMicroservice';

// State Declarations - START

export interface FeatureState {
    login: UserResponse | undefined;
    localizations: AllTranslationsDetails[];
    error: HttpErrorResponse | undefined;
    userRoles: UserRolesResponse | undefined;
    report: reportResponse;
}

export interface AppState {
    modesoAdminMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoAdminMicroservice;

export const selectFeatureLogin = createSelector(
    selectFeature,
    (state: FeatureState) => state.login
);

export const selectFeatureLocalizations = createSelector(
    selectFeature,
    (state: FeatureState) => state.localizations
);

export const featureError = createSelector(
    selectFeature,
    (state: FeatureState) => state.error
);

export const selectFeatureUserRoles = createSelector(
    selectFeature,
    (state: FeatureState) => state.userRoles);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    login: undefined,
    localizations: [],
    error: undefined,
    userRoles: undefined,
    report: {} as reportResponse,
};

const adminReducer = createReducer(
    initialState,
    
    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromAdminManageContentActions.getAllLocalizations, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onGetAllLocalizationsSuccessfully, (state, action) =>
        ({ ...state, localizations: action.payload, error: undefined })),
    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({ ...state, error: action.payload })),
    on(fromAdminManageContentActions.updateLocalizationByKey, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onUpdateLocalizationByKeySuccessfully, (state, action) => {
        const index =  state.localizations.findIndex((translation: AllTranslationsDetails) => 
        translation.key == action.payload.translations.key);
        const updatedLocalization = [...state.localizations];
        if(index > -1) {
            const mutated  = {...updatedLocalization[index]};
            mutated.text =  action.payload.translations.text;
            updatedLocalization[index] = mutated;
        };
        return { ...state, localizations: [...updatedLocalization], error: undefined}
    }),
    on(fromAdminManageContentActions.onUpdateLocalizationByKeyFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminFromActions.login, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onLoginSuccessfully, (state, action) => ({ ...state, login: action.payload, error: undefined })),
    on(AdminFromActions.onLoginFailed, (state, action) => ({ ...state, login: {}, error: action.payload })),

    on(AdminFromActions.logout, (state) => ({ ...state })),
    on(AdminFromActions.onLogoutSuccessfully, (state, action) => ({ ...state, login: {}, error: undefined })),
    on(AdminFromActions.onLogoutFailed, (state, action) => ({ ...state, login: {}, error: action.payload })),

    on(AdminFromActions.getNewAccessToken, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenSuccessfully, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenFailed, (state, action) => ({ ...state, error: action.payload })),

    on(AdminFromActions.getUserRoles, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetUserRolesSuccessfully, (state, action) => ({
        ...state, userRoles: { roles: action.payload } as unknown as UserRolesResponse,
        error: undefined
    })),
    on(AdminFromActions.onGetUserRolesFailed, (state, action) => ({
        ...state, userRoles: undefined,
        error: action.payload
    })),
    on(AdminFromActions.resetStore, (state) => ({ ...initialState })),

);

export function reducer(state: FeatureState | undefined, action: Action) {
    return adminReducer(state, action);
}

export const featureReport = createSelector(
    selectFeature,
    (state: FeatureState) => state.report
);

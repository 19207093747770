import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BasePage } from '../../base.page';
import { HourFormatPipe, PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ILegal, IPointOfSales , ICategory, PointOfSaleOpeningHourMapper } from '@modeso/types__tsf-ms-pointofsales';
import { take } from 'rxjs';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import {  MapPOSToExportColumns, POSExportColumnNames, IMapper, RomanValues, DayName } from './export-point-of-sales-columns-names';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirm-dialog.component';



@Component({
  selector: 'app-list-point-of-sales',
  templateUrl: './list-point-of-sale.component.html',
  styleUrls: ['./list-point-of-sale.component.scss']
})
export class ListPointOfSalesComponent extends BasePage implements OnInit {

  pointofsales!:IPointOfSales[];
  pointofsalesOriginal!:IPointOfSales[];
  dataSource!: MatTableDataSource<IPointOfSales>;
  displayedColumns: string[] = ['name', 'merchantUuid','legal' , 'category' ,'buttons'];
  legals!:Array<ILegal>;
  categories!:Array<ICategory>;
  exportPosData:any=[];
  dialogRef!: MatDialogRef<ConfirmationDialogComponent>;
    
  @ViewChild('paginator', { static: false }) paginator: MatPaginator | any;

  constructor(
    private injector: Injector, private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private dialog: MatDialog,
    private hourFormatPipe: HourFormatPipe
  ) {
    super(injector);
  }

  override ngOnInit() {
    this.pointOfSalesProvider.dispatchGetAllCategories();
    this.subscriptions.push(
      this.pointOfSalesProvider.getPointOfSales$().subscribe((pointOfSales) => {    
        if(pointOfSales){
          this.pointofsales = pointOfSales;
          this.pointofsalesOriginal = pointOfSales;

          this.subscriptions.push(
            this.pointOfSalesProvider.getCategories$().pipe(take(2)).subscribe((categories) => {
              if(categories && categories.length){
                this.categories = categories  
              }
            })
          );

          this.subscriptions.push(
            this.pointOfSalesProvider.getLegals$().pipe(take(2)).subscribe((legals) => {
              if(!legals || !legals.length){
                return
              }
              this.legals = legals;

              this.pointofsales = pointOfSales.map((pointofsale) => {
                const legal = this.legals?.filter((legal) => legal.legalId === pointofsale.legal)[0];
                const category = this.categories?.filter( (category) => category.categoryId === pointofsale.category)[0];

                return {
                  ...pointofsale,
                  legal: legal?.name,
                  category: category?.name
                };
              });

              this.dataSource = new MatTableDataSource<IPointOfSales>(this.pointofsales);
              this.dataSource.paginator = this.paginator;
              this.mapPosToExport();  
            })
          );
        }
      })
    )
  }

  addPointofsale(){
    this.router.navigate([`/add-pointofsale`]);
  }

  editPointofsale(pointofsaleId:string){
    this.router.navigate([`/add-pointofsale`, { pointofsaleId} ]);
  }

  deletePointofsale(pointofsaleId:string){
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent);
    this.dialogRef.componentInstance.confirmButtonText = "Delete";
    this.dialogRef.componentInstance.confirmTitle = "Delete Point of Sale";
    const posName = this.pointofsales.filter( pointofsale => pointofsale.pointofsaleId === pointofsaleId )[0].name['en-us'];
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to delete ${posName}?`;

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe( result => {
        if(result){
          this.pointOfSalesProvider.deletePointOfSale$(pointofsaleId);
        }
      })
    );
  }

  importPointofsales(){
    this.router.navigate([`/import-pointofsales`]);
  }

  exportPointofsales(){
    const fileName = `storefinder_pos_export_${moment().format('YYYYMMDDHHmmss')}.xlsx`;
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportPosData,{ ​header​:​[​​...POSExportColumnNames]​});
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'test');
		XLSX.writeFile(wb, fileName);
  }

  mapPosToExport(){
    this.exportPosData = this.pointofsalesOriginal
    .map((pointofsale)=> {
      let posObject:any = {}
      let {externalLegalId, name} = this.legals?.filter(legal=>legal.legalId === pointofsale.legal)[0];
      Object.entries(pointofsale).map(([key, value]) => {
        let columnName = MapPOSToExportColumns[key as keyof typeof MapPOSToExportColumns];
        if(key=== 'openingHours'){
          posObject = this.mapOpeneingHoursToExport(pointofsale, posObject);
        }
        if(key === 'address'){
          posObject[MapPOSToExportColumns['address.zipCode']] = value.zipCode  
          posObject[MapPOSToExportColumns['address.city']] = value.city  
          posObject[MapPOSToExportColumns['address.street']] = value.street       
        }  
        
        if(key === 'description'){
          posObject[MapPOSToExportColumns["description['de-ch']"]] = value['de-ch']  
          posObject[MapPOSToExportColumns["description['it-ch']"]] = value['it-ch'] 
          posObject[MapPOSToExportColumns["description['fr-ch']"]] = value['fr-ch']   
          posObject[MapPOSToExportColumns["description['en-us']"]] = value['en-us']        
     
        }  
        
        if(key === 'name'){
          posObject[MapPOSToExportColumns["name['de-ch']"]] = value['de-ch']  
          posObject[MapPOSToExportColumns["name['it-ch']"]] = value['it-ch'] 
          posObject[MapPOSToExportColumns["name['fr-ch']"]] = value['fr-ch']   
          posObject[MapPOSToExportColumns["name['en-us']"]] = value['en-us']      
        }

        if(columnName){
          if(key === 'legal'){
            posObject[MapPOSToExportColumns["legal"]] = externalLegalId;
            posObject[MapPOSToExportColumns["legalName"]] = name
          }else{
            posObject[columnName]=value
          }
        } 
      })
      let orderedPos:IMapper = Object.keys(posObject)
      .sort((a, b) => POSExportColumnNames.indexOf(a) - POSExportColumnNames.indexOf(b))
      .reduce(
        (obj:IMapper, key) => { 
          obj[key] = posObject[key]; 
          return obj;
        }, 
        {}
      ); 
      return orderedPos;
    })
  }
  private mapOpeneingHoursToExport(pointofsale: IPointOfSales, posObject: any) {
    if(!pointofsale.openingHours) return;
    const openingHourDict: {[key: string]: {open: string, close: string}[]} =
     PointOfSaleOpeningHourMapper.mapModelToResponseDTOWithOpeningHours(
      pointofsale.openingHours.periods
    );
    Object.keys(DayName).forEach((day: any) => {;
      const dayName = DayName[day];
      const columnOpen24hr = `${dayName}_Open_24h`;
      posObject[columnOpen24hr] = ''
      const columnCloseAllDay = `${dayName}_Close`;
      posObject[columnCloseAllDay] = '';
      const columnOpenTimePeriodOne = `${dayName}_Open_time_I`;
      posObject[columnOpenTimePeriodOne] = '';
      const columnCloseTimePeriodOne = `${dayName}_Close_time_I`;
      posObject[columnCloseTimePeriodOne] = '';
      const columnOpenTimePeriodTwo = `${dayName}_Open_time_II`;
      posObject[columnOpenTimePeriodTwo] = '';
      const columnCloseTimePeriodTwo = `${dayName}_Close_time_II`;
      posObject[columnCloseTimePeriodTwo] = '';
    });
    Object.keys(openingHourDict).forEach((key: any) => {
      const day = DayName[key];
      if (openingHourDict[key].length === 1 && !openingHourDict[key][0].open) {
        const columnName = `${day}_Close`
        posObject[columnName] = 'true';
        return;
      } else if (openingHourDict[key].length === 1 && !openingHourDict[key][0].close) {
        const columnName = `${day}_Open_24h`
        posObject[columnName] = 'true';
        return;
      } else if(openingHourDict[key].length) {
        openingHourDict[key].forEach((openingHour: {open: string, close: string}, index) => {
          const romanNumber = this.getRomanNumberValue(index);
          const columnNameOpen = `${day}_Open_time_${romanNumber}`;
          posObject[columnNameOpen] = this.hourFormatPipe.transform(openingHour.open);
          const columnNameClose = `${day}_Close_time_${romanNumber}`;
          posObject[columnNameClose] = this.hourFormatPipe.transform(openingHour.close);
        });
    }});
    return posObject;
    
  }
    
  private getRomanNumberValue(index: number) {
    if(index > RomanValues.length) {
      return `${index}`;
    }
    return RomanValues[index];

  }

}

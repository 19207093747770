export const environment = {
  production: true,
  maintenance: false,
  modeso: {
    apiGateway: 'https://api.storefinder-int.twint.ch/api/',
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch'],
  enableDebugging: 'modeso:*, tsf:*',
  allowLoginWithUsernameAndPassword:true
};


import { Component, OnInit, Input } from '@angular/core';
import { PointOfSaleUtils } from '../../utils/utilties';
import moment from 'moment';
import { IOpeningHoursVM } from '../../models/openingHoursVM.model';
@Component({
  selector: 'tsf-pos-opening-hours',
  templateUrl: './pos-opening-hours.component.html',
  styleUrls: ['./pos-opening-hours.component.scss']
})
export class PosOpeningHoursComponent {

  @Input() openingHours!: IOpeningHoursVM;
  todayDay!: number;
  constructor() {}
  getIsOpenPosStatus() {
    const now = Date.now();
    this.todayDay = moment(now).weekday() - 1;
    const todayHour = moment(now).format('hhmm');
    const localizationKeyOfTitle = PointOfSaleUtils.isOpenNow(this.openingHours, this.todayDay, todayHour) 
    ? 'tsf_pos_details_view_open_now' : 'tsf_pos_details_view_closed'
    return localizationKeyOfTitle;
  }

}

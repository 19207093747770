import { OnInit, Component, Injector, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import Debug from 'debug';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
const debug = Debug('modeso:template:BasePage');

@Component({
  selector: 'modeso-base-page',
  template: `<ng-content></ng-content>`,
})
export class BasePage implements OnInit, OnDestroy {
  
  private activatedRoute: ActivatedRoute;
  protected subscriptions: Array<Subscription> = [];

  constructor(injector: Injector) {
    debug('base loaded');
    this.activatedRoute = injector.get(ActivatedRoute);
  }

  ngOnInit(): void {
    this.setDebuggingMode();
  }

  setDebuggingMode() {
    localStorage['debug'] = environment.enableDebugging;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}

<div mat-dialog-content>
  <form class="update-localization-by-key-form" [formGroup]="updateLocalizationByKeyForm">
    <div class="flex-row">
      <div class="form-group">
        <label>Key</label>
        <div class="input-wrapper">
          <input id="key" class="form-control" type="text" formControlName="key" />
        </div>
      </div>
    </div>
    
    <div class="flex-row">
      <div class="form-controls">
        <label>de-ch</label>
        <div class="input-wrapper">
          <lib-modeso-core-markdown-input
            id="de-ch"
            class="form-control"
            type="text"
            controlName="de-ch"
          ></lib-modeso-core-markdown-input>
          <span [innerHtml]="updateLocalizationByKeyForm.get('de-ch')?.value | markdownToSafeHtml"></span>
        </div>
      </div>

      <div class="form-controls">
        <label>en-us</label>
        <div class="input-wrapper">
          <lib-modeso-core-markdown-input
            id="en-us"
            class="form-control"
            type="text"
            controlName="en-us"
          ></lib-modeso-core-markdown-input>
          <span [innerHtml]="updateLocalizationByKeyForm.get('en-us')?.value | markdownToSafeHtml"></span>
        </div>
      </div>

      <div class="form-controls">
        <label>fr-ch</label>
        <div class="input-wrapper">
          <lib-modeso-core-markdown-input
            id="fr-ch"
            class="form-control"
            type="text"
            controlName="fr-ch"
          ></lib-modeso-core-markdown-input>
          <span [innerHtml]="updateLocalizationByKeyForm.get('fr-ch')?.value | markdownToSafeHtml"></span>
        </div>
      </div>

      <div class="form-controls">
        <label>it-ch</label>
        <div class="input-wrapper">
          <lib-modeso-core-markdown-input
            id="it-ch"
            class="form-control"
            type="text"
            controlName="it-ch"
          ></lib-modeso-core-markdown-input>
          <span [innerHtml]="updateLocalizationByKeyForm.get('it-ch')?.value | markdownToSafeHtml"></span>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions class="dialog-buttons">
  <div class="action-button">
    <button mat-button (click)="onNoClick()" class="button">Cancel</button>
    <button
      mat-button
      [mat-dialog-close]="data"
      [disabled]="buttonStatus()"
      cdkFocusInitial
      (click)="save()"
      class="button"
    >
      Confirm
    </button>
  </div>
</div>

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { PointOfSalesProvider } from '../../providers/pointofsales.provider';
import { ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales';
import { combineLatest, map, Observable, of, ReplaySubject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


interface ITranslatedCategoryVM extends ITranslatedCategory {
  selected: boolean;
}
@Component({
  selector: 'tsf-pos-category-filter',
  templateUrl: './pos-category-filter.component.html',
  styleUrls: ['./pos-category-filter.component.scss']
})
export class PointOfSalesCategoryFilterComponent implements OnInit, OnDestroy {

  categories$!: Observable<ITranslatedCategoryVM[] | undefined>;
  filteredCategories!: string[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isMobile!: boolean;
  selectedCategories!: string[];
  constructor(private pointOfSaleProvider: PointOfSalesProvider, private responsive: BreakpointObserver) { 
    this.filteredCategories = [];
  }

  ngOnInit(): void {
    this.categories$ = combineLatest<any>([this.pointOfSaleProvider.getTranslatedCategories$(),
       this.pointOfSaleProvider.getSelectedCategoriesIds$()]).pipe(map(((value: any) => {
         if(!value) return;
         const categories = value[0];
         const selectedCategories = value[1];
         if(!selectedCategories ||!selectedCategories.length) return categories;
         const categoriesVM: ITranslatedCategoryVM[] = [];
         categories.forEach((category: ITranslatedCategory) => {
            const categoryVM: ITranslatedCategoryVM = {...category, selected: false};
            if(selectedCategories.includes(category.categoryId)) {
              categoryVM.selected = true;
              this.filteredCategories = [...this.filteredCategories, category.categoryId];
            }
            categoriesVM.push(categoryVM);
          })
          return categoriesVM;
    })))
    this.responsive.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(takeUntil(this.destroyed$)).subscribe((breakPoint) => {
      if(breakPoint.matches) this.isMobile = true;
      else this.isMobile = false;
    });

  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
    this.filteredCategories = chip.selected ? [...this.filteredCategories, chip.value] :
                                  this.filteredCategories.filter((value) => chip.value !== value);
    this.pointOfSaleProvider.dispatchFilterPosByCategory(this.filteredCategories);
    if(!this.isMobile) this.pointOfSaleProvider.applyFilterPosByCategory();
  }
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { InjectedTextFeautureModule } from '@modeso/modeso-lib-core-fe';
import { PointOfSaleService } from '@modeso/tsf-lib-pointofsales-fe';
import { PointOfSaleItemComponent } from './components/point-of-sale-item/point-of-sale-item.component';
import { PointOfSaleTileComponent } from './components/point-of-sale-tile/point-of-sale-tile.component';
import { PointOfSaleConfig, PointOfSaleConfigService } from './tsf-pointofsale.config';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects'
import { PointOfSalesEffects } from '../lib/effects/pointofsales.effect';
import * as fromReducer from './reducers/pointofsales.reducer';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';  
import { MatInputModule } from '@angular/material/input'; 
import { MatListModule } from '@angular/material/list';
import { SearchComponent } from './components/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TsfLibCoreFeModule } from '@modeso/tsf-lib-core-fe';
import { HourFormatPipe } from './utils/pipes/hourFormat.pipe';
import { PosDetailViewComponent } from './components/pos-detail-view/pos-detail-view.component';
import { PosOpeningHoursComponent } from './components/pos-opening-hours/pos-opening-hours.component';
import { GetDayFromIndexPipe} from './utils/pipes/dayFromIndex.pipe';
import { PointOfSalesCategoryFilterComponent } from './components/pos-category-filter/pos-category-filter.component';
import { MatChipsModule } from '@angular/material/chips';
import { PosAllFiltersComponent } from './components/pos-all-filters/pos-all-filters.component';
import { MatRadioModule } from '@angular/material/radio';
import { SearchItemResultComponent } from './components/search/search-item-result/search-item-result.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  providers:[
    PointOfSaleService,
    HourFormatPipe
  ],
  declarations: [
    PointOfSaleItemComponent, 
    PointOfSaleTileComponent,
    SearchComponent,
    HourFormatPipe,
    PosDetailViewComponent,
    PosOpeningHoursComponent,
    GetDayFromIndexPipe,
    HourFormatPipe,
    PointOfSalesCategoryFilterComponent,
    PosAllFiltersComponent,
    SearchItemResultComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    InjectedTextFeautureModule,
    ReactiveFormsModule,
    TsfLibCoreFeModule,
    EffectsModule.forFeature([PointOfSalesEffects]),
    StoreModule.forFeature(fromReducer.pointofsaleFeatureKey, fromReducer.reducer),
    MatChipsModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCheckboxModule
  ],
  exports: [PointOfSaleTileComponent, 
    PosDetailViewComponent,
    GetDayFromIndexPipe, 
    HourFormatPipe,
    PointOfSalesCategoryFilterComponent,
    SearchComponent,
    PosAllFiltersComponent,
    PosOpeningHoursComponent,
    SearchComponent,
    PointOfSaleItemComponent
  ]
})

export class PointOfSaleFeModule {
  static forRoot(config: PointOfSaleConfig): ModuleWithProviders<PointOfSaleFeModule> {
    return {
      ngModule: PointOfSaleFeModule,
      providers: [
        PointOfSaleService,
        {
          provide: PointOfSaleConfigService,
          useValue: config
        }
      ]
    };
  }
}
import { Component, Injector, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BasePage } from '../../base.page';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ICategory } from '@modeso/types__tsf-ms-pointofsales';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirm-dialog.component';


@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss'],
  
})
export class ListCategoriesComponent extends BasePage implements OnInit {

  categories!:ICategory[];
  dataSource!: MatTableDataSource<ICategory>;
  displayedColumns: string[] = ['name', 'buttonText', 'buttons'];
  dialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private injector: Injector, private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private dialog: MatDialog
  ) {
    super(injector);
  }

  override ngOnInit() {
    this.pointOfSalesProvider.dispatchGetAllCategories();
    this.subscriptions.push(
      this.pointOfSalesProvider.getCategories$().subscribe((categories) => {
        if(categories){
          this.categories = categories;
          this.dataSource = new MatTableDataSource<ICategory>(this.categories);
        }
      })
    );
  }

  addCategory(){
    this.router.navigate([`/add-category`]);
  }

  editCategory(categoryId:string){
    this.router.navigate([`/add-category`, { categoryId} ]);
  }

  deleteCategory(categoryId:string){
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent);
    this.dialogRef.componentInstance.confirmButtonText = "Delete";
    this.dialogRef.componentInstance.confirmTitle = "Delete Category";
    const categoryName = this.categories.filter( category => category.categoryId === categoryId )[0].name;
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to delete ${categoryName}?`;

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe( result => {
        if(result){
          this.pointOfSalesProvider.deleteCategory$(categoryId);
        }
      })
    );
  }
}

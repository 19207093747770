import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IPointOfSaleVM }  from '../../models/pointofsale.model';
import Debug from "debug";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ReplaySubject, takeUntil } from 'rxjs';

const debug: Debug.Debugger = Debug("modeso:tsf-lib-pointofsales:search");
@Component({
  selector: 'tsf-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy  {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output()
  emitRouteToPos = new EventEmitter<IPointOfSaleVM>();
  @Output()
  emitShowTile = new EventEmitter<boolean>();;
  @Output()
  pointOfSaleClick: EventEmitter<IPointOfSaleVM> = new EventEmitter();
  @Input() 
  pointOfSale!: IPointOfSaleVM;
  @Input() 
  locationEnabled!: boolean;
 
  @Input() 
  set allPointOfSales(value: IPointOfSaleVM[]) {
    this.currentPointOfSales = value;
  };

  @Input() 
  set nearbyLocations(value: IPointOfSaleVM[]) {
   this.nearbyLocationsVM = value;
  };
  get nearbyLocations() {
   return this.nearbyLocationsVM 
  };
  currentPointOfSales: IPointOfSaleVM[] = [];
  filteredPointOfSales: IPointOfSaleVM[] = [];
  searchQuery: String = "";
  showFilter: boolean = false;
  showPosResult!: boolean;
  nearbyLocationsVM: any;
  hasCategory!: boolean;
  showSelectedPos!: boolean;
  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
  
  isMobile: boolean = true;

  constructor(private responsive: BreakpointObserver, private pointOfSalesProvider: PointOfSalesProvider){
    const categoryName = sessionStorage.getItem('category') || 'all';
    this.hasCategory = categoryName !== 'all';
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.responsive.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.pointOfSalesProvider.getSelectedCategoriesIds$().pipe(takeUntil(this.destroyed$)).subscribe(selectedCategories => {
      if(!selectedCategories || !selectedCategories.length){
         this.showPosResult = false;
      } else {
        this.showPosResult = true;
      }
    })
    this.allPointOfSales?.sort((p1, p2) => p1.pointName.localeCompare(p2.pointName));

    this.pointOfSalesProvider.getCurrentFilterVisibilityState$().pipe(takeUntil(this.destroyed$))
          .subscribe(filterVisibilty => this.showFilter = filterVisibilty);
  }
  
  onSearchQueryChange(searchQuery:string){
    this.pointOfSalesProvider.dispatchApplyFilterVisibility(false);
    this.searchQuery = searchQuery;
    if(this.searchQuery.trim() === ""){
      this.filteredPointOfSales = this.currentPointOfSales;
      this.emitShowTile.emit(true)
      return;
    }
    this.emitShowTile.emit(false);
    this.searchOnCurrentPos();
  }

  onShowFiltersClick(){
    this.pointOfSalesProvider.dispatchApplyFilterVisibility(!this.showFilter);
  }

  searchOnCurrentPos() {
    const filterValue = this.searchQuery.trim().toLowerCase();
    this.filteredPointOfSales = this.currentPointOfSales.filter(pointOfSale => 
      pointOfSale.pointName.toLowerCase().includes(filterValue) ||
      pointOfSale.pointAddress.street.toLowerCase().includes(filterValue) ||
      pointOfSale.pointAddress.zipCode.toString().toLowerCase().includes(filterValue) ||
      pointOfSale.pointAddress.city.toLowerCase().includes(filterValue)
    );
  }

  onRouteToPos($event: IPointOfSaleVM) {
    this.emitRouteToPos.emit($event)
  }
  
  applyFilter() {    
    this.pointOfSalesProvider.applyFilterPosByCategory();
    this.pointOfSalesProvider.dispatchApplyFilterPos();
    this.pointOfSalesProvider.dispatchApplyFilterVisibility(false);
    this.emitShowTile.emit(true);
    this.showSelectedPos = false;
  }

  resetFilter() {
    this.pointOfSalesProvider.dispatchSetFilterPos(null);
    this.pointOfSalesProvider.dispatchFilterPosByCategory([]);
    this.pointOfSalesProvider.dispatchApplyFilterVisibility(false);
    this.emitShowTile.emit(true);
  }

  onPosClicked(pointOfSale: IPointOfSaleVM) {
    this.showSelectedPos = true
    this.pointOfSaleClick.emit(pointOfSale);
    if(this.isMobile){
      this.searchQuery = ''
    }
    this.emitShowTile.emit(true);
  }

  hideFilter($event: Event) {
    if($event.target === $event.currentTarget) {
      this.pointOfSalesProvider.dispatchApplyFilterVisibility(false); 
    }
  }
}

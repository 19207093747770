import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PointOfSaleService } from '../services/pointofsale.service';
import * as pointofsalesActions from '../actions/pointofsales.action';
import * as categoryActions from '../actions/category.action';
import * as paymentPartnerActions from '../actions/paymentPartner.action';
import * as legalActions from '../actions/legal.action';
import * as filterActions from '../actions/filter.action';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { ICategory, ICategoryFilter, ILegal, IPaymentPartner, IPointOfSales } from '@modeso/types__tsf-ms-pointofsales';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

const debug = Debug('modeso:tsf-lib-pointofsales-fe:PointOfSalesEffects');


@Injectable()
export class PointOfSalesEffects {

  constructor(private actions$: Actions, private service: PointOfSaleService,
     private router: Router, private snackBar: MatSnackBar) { }

  onAddOnUpdatePointOfSale$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onAddOrUpdatePointOfSale.type),
      mergeMap(
        (action: pointofsalesActions.ActionWithPayload<IPointOfSales>) => {
          return this.service.addOrUpdatePointOfSale(action.payload)
            .pipe(
              map(
                response => (pointofsalesActions.onAddOrUpdatePointOfSaleSuccess({ payload: response })),
              ),
              catchError((error) => of(pointofsalesActions.onAddOrUpdatePointOfSaleFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnAddOnUpdatePointOfSale$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onAddOrUpdatePointOfSaleFailed.type),
      tap(
        (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  OnAddOnUpdatePointOfSale$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onAddOrUpdatePointOfSaleSuccess.type),
      tap(
        (action: pointofsalesActions.ActionWithPayload<any>) => this.router.navigate(['/manage-pointofsales']),
      ),
    ), { dispatch: false },
  );

  onGetPointOfSales$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onGetPointOfSales.type),
      mergeMap(
        () => {
          return this.service.getPointOfSales()
            .pipe(
              map(
                response => (pointofsalesActions.onGetPointOfSalesSuccess({ payload: response })),
              ),
              catchError((error) => of(pointofsalesActions.onGetPointOfSalesFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnGetPointOfSales$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onGetPointOfSalesFailed.type),
      tap(
        (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onDeletePointOfSales$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onDeletePointOfSale.type),
      mergeMap(
        (action: pointofsalesActions.ActionWithPayload<string>) => {
          return this.service.deletePointOfSale(action.payload)
            .pipe(
              map(
                response => (pointofsalesActions.onDeletePointOfSaleSuccess({ payload: action.payload })),
              ),
              catchError((error) => of(pointofsalesActions.onDeletePointOfSaleFailed({ payload: error })))
            );
        }
      )
    )
  );

  onAddOnUpdateCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onAddOrUpdateCategory.type),
      mergeMap(
        (action: categoryActions.ActionWithPayload<ICategory>) => {
          return this.service.addOrUpdateCategory(action.payload)
            .pipe(
              map(
                response => (categoryActions.onAddOrUpdateCategorySuccess({ payload: response })),
              ),
              catchError((error) => of(categoryActions.onAddOrUpdateCategoryFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnAddOnUpdateCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onAddOrUpdateCategoryFailed.type),
      tap(
        (action: categoryActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onGetCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onGetCategories.type),
      mergeMap(
        () => {
          return this.service.getCategory()
            .pipe(
              map(
                response => (categoryActions.onGetCategoriesSuccess({ payload: response })),
              ),
              catchError((error) => of(categoryActions.onGetCategoriesFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnGetCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onGetCategoriesFailed.type),
      tap(
        (action: categoryActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onDeleteCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onDeleteCategory.type),
      mergeMap(
        (action: categoryActions.ActionWithPayload<string>) => {
          return this.service.deleteCategory(action.payload)
            .pipe(
              map(
                response => (categoryActions.onDeleteCategorySuccess({ payload: action.payload })),
              ),
              catchError((error) => of(categoryActions.onDeleteCategoryFailed({ payload: error })))
            );
        }
      )
    )
  );


  onAddOnUpdatePaymentPartner$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentPartnerActions.onAddOrUpdatePaymentPartner.type),
      mergeMap(
        (action: paymentPartnerActions.ActionWithPayload<IPaymentPartner>) => {
          return this.service.addOrUpdatePaymentPartner(action.payload)
            .pipe(
              map(
                response => (paymentPartnerActions.onAddOrUpdatePaymentPartnerSuccess({ payload: response })),
              ),
              catchError((error) => of(paymentPartnerActions.onAddOrUpdatePaymentPartnerFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnAddOnUpdatePaymentPartner$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentPartnerActions.onAddOrUpdatePaymentPartnerFailed.type),
      tap(
        (action: paymentPartnerActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onGetPaymentPartners$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentPartnerActions.onGetPaymentPartners.type),
      mergeMap(
        () => {
          return this.service.getPaymentPartner()
            .pipe(
              map(
                response => (paymentPartnerActions.onGetPaymentPartnersSuccess({ payload: response })),
              ),
              catchError((error) => of(paymentPartnerActions.onGetPaymentPartnersFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnGetPaymentPartners$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentPartnerActions.onGetPaymentPartnersFailed.type),
      tap(
        (action: paymentPartnerActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onDeletePaymentPartner$ = createEffect(
    () => this.actions$.pipe(
      ofType(paymentPartnerActions.onDeletePaymentPartner.type),
      mergeMap(
        (action: paymentPartnerActions.ActionWithPayload<string>) => {
          return this.service.deletePaymentPartner(action.payload)
            .pipe(
              map(
                response => (paymentPartnerActions.onDeletePaymentPartnerSuccess({ payload: action.payload })),
              ),
              catchError((error) => of(paymentPartnerActions.onDeletePaymentPartnerFailed({ payload: error })))
            );
        }
      )
    )
  );


  onAddOnUpdateLegal$ = createEffect(
    () => this.actions$.pipe(
      ofType(legalActions.onAddOrUpdateLegal.type),
      mergeMap(
        (action: legalActions.ActionWithPayload<ILegal>) => {
          return this.service.addOrUpdateLegal(action.payload)
            .pipe(
              map(
                response => (legalActions.onAddOrUpdateLegalSuccess({ payload: response })),
              ),
              catchError((error) => of(legalActions.onAddOrUpdateLegalFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnAddOnUpdateLegal$ = createEffect(
    () => this.actions$.pipe(
      ofType(legalActions.onAddOrUpdateLegalFailed.type),
      tap(
        (action: legalActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onGetLegals$ = createEffect(
    () => this.actions$.pipe(
      ofType(legalActions.onGetLegals.type),
      mergeMap(
        () => {
          return this.service.getLegal()
            .pipe(
              map(
                response => (legalActions.onGetLegalsSuccess({ payload: response })),
              ),
              catchError((error) => of(legalActions.onGetLegalsFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnGetLegals$ = createEffect(
    () => this.actions$.pipe(
      ofType(legalActions.onGetLegalsFailed.type),
      tap(
        (action: legalActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onDeleteLegal$ = createEffect(
    () => this.actions$.pipe(
      ofType(legalActions.onDeleteLegal.type),
      mergeMap(
        (action: legalActions.ActionWithPayload<string>) => {
          return this.service.deleteLegal(action.payload)
            .pipe(
              map(
                response => (legalActions.onDeleteLegalSuccess({ payload: action.payload })),
              ),
              catchError((error) => of(legalActions.onDeleteLegalFailed({ payload: error })))
            );
        }
      )
    )
  );
  
  onGetPointOfSalesByCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onGetPointOfSalesByCategory.type),
      mergeMap(
        (action: pointofsalesActions.ActionWithPayload<string>) => {
          return this.service.getPointOfSalesByCategory(action.payload)
            .pipe(
              map(
                response => (pointofsalesActions.onGetPointOfSalesByCategorySuccess({ payload: response })),
              ),
              catchError((error) => of(pointofsalesActions.onGetPointOfSalesByCategoryFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnGetPointOfSalesByCategory$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onGetPointOfSalesByCategoryFailed.type),
      tap(
        (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
      ),
    ), { dispatch: false },
  );

  onImportPointOfSales$ = createEffect(
    () => this.actions$.pipe(
      ofType(pointofsalesActions.onImportPointOfSales.type),
      mergeMap(
        (action: any) => {
          return this.service.importPointOfSale(action.payload)
            .pipe(
              map(
                response => (pointofsalesActions.onImportPointOfSalesSuccess({ payload: response })),
              ),
              catchError((error) => of(pointofsalesActions.onImportPointOfSalesFailed({ payload: error })))
            );
        }
      )
    )
  );

  onImportPointOfSalesFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(pointofsalesActions.onImportPointOfSalesFailed.type),
        tap(
            (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
        ),
    )
    , { dispatch: false },
  );

  onGetCategoryFilter$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onGetCategoryFilter.type),
      mergeMap(
        () => {
          return this.service.getCategoryFilters()
            .pipe(
              map(
                response => (categoryActions.onGetCategoryFilterSuccess({ payload: response })),
              ),
              catchError((error) => of(categoryActions.onGetCategoryFilterFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetCategoryFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(categoryActions.onGetCategoryFilterFailed.type),
        tap(
            (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
        ),
    )
    , { dispatch: false },
  );

  onGetTranslatedCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(categoryActions.onGetTranslatedCategories.type),
      mergeMap(
        () => {
          return this.service.getTranslatedCategories()
            .pipe(
              map(
                response => (categoryActions.onGetTranslatedCategoriesSuccess({ payload: response })),
              ),
              catchError((error) => of(categoryActions.onGetTranslatedCategoriesFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetTranslatedCategoriesFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(categoryActions.onGetTranslatedCategoriesFailed.type),
        tap(
            (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
        ),
    )
    , { dispatch: false },
  );

  onGetFilters$ = createEffect(
    () => this.actions$.pipe(
      ofType(filterActions.onGetAllFilters.type),
      mergeMap(
        () => {
          return this.service.getFilters()
            .pipe(
              map(
                response => {
                  return (filterActions.onGetFiltersSuccess({ payload: response }))
                },
              ),
              catchError((error) => of(filterActions.onGetFiltersFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetFiltersFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.onGetFiltersFailed.type),
        tap(
            (action: pointofsalesActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload),
        ),
    )
    , { dispatch: false },
  );

  onAddOrUpdateFilter$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.onAddOrUpdateFilter.type),
        mergeMap(
          (action: pointofsalesActions.ActionWithPayload<ICategoryFilter>) => {
            return this.service.addOrUpdateFilters(action.payload)
              .pipe(
                map(
                  response => 
                     (filterActions.onAddOrUpdateFilterSuccess({ payload: response }))
                  ,
                ),
                catchError((error) => {
                  return of(filterActions.onAddOrUpdateFilterFailed({ payload: error }))
                })
              );
          }
        )
    )
  );

  onAddOrUpdateFilterSuccess$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.onAddOrUpdateFilterSuccess.type),
        tap(
          (action: pointofsalesActions.ActionWithPayload<ICategoryFilter>) => this.router.navigateByUrl('filters'),
      ),
    )
    , { dispatch: false },
  );

  onGetCategoryFilterById$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.onGetFilterById.type),
        mergeMap(
          (action: pointofsalesActions.ActionWithPayload<string>) => {
            return this.service.getFilterById(action.payload)
              .pipe(
                map(
                  response => 
                     (filterActions.onGetFilterByIdSuccess({ payload: response }))
                  ,
                ),
                catchError((error) => of(filterActions.onGetFilterByIdFailed({ payload: error })))
              );
          }
        )
    )
  );

  onDeleteFilter$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.ondeleteFilterById.type),
        mergeMap(
          (action: pointofsalesActions.ActionWithPayload<string>) => {
            return this.service.deleteFilterById(action.payload)
              .pipe(
                map(
                  response => 
                     (filterActions.ondeleteFilterByIdSuccess({ payload: response }))
                  ,
                ),
                catchError((error) => {
                  return of(filterActions.ondeleteFilterByIdFailed({ payload: error }))
                })
              );
          }
        )
    )
  );

  onDeleteFiltersFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(filterActions.ondeleteFilterByIdFailed.type),
        tap(
            (action: pointofsalesActions.ActionWithPayload<any>) => this.snackBar.open('Failed to delete Filter', 'ok'),
        ),
    )
    , { dispatch: false },
  );

  public handleOnLoadAllErrors(error:any) {
    debug(error);
    return error;
  }

}

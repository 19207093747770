import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent implements OnInit {
  public confirmButtonText: string = '';
  public message: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}

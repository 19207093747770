<div class="add-pointofsale-container">
  <form class="form" *ngIf="pointofsaleForm" [formGroup]="pointofsaleForm">
    <div [formGroup]="pointofsaleForm?.get('name')" class="paddingButtom">
      <span>Name</span>
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <input formControlName="de-ch" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <input formControlName="en-us" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <input formControlName="fr-ch" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <input formControlName="it-ch" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div [formGroup]="pointofsaleForm?.get('description')" class="paddingButtom">
      <span>Description</span>
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <textarea class="textarea-field" formControlName="de-ch" matInput> </textarea>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <textarea class="textarea-field" formControlName="en-us" matInput> </textarea>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <textarea class="textarea-field" formControlName="fr-ch" matInput> </textarea>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <textarea class="textarea-field" formControlName="it-ch" matInput> </textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="fill">
          <mat-label>Category</mat-label>
          <mat-select (selectionChange)="onCategoryChange()"
           formControlName="categoryId" name="categoryId"  [disabled]="categories.length === 0">
              <mat-option *ngFor="let category of categories" [value]="category.categoryId">
              {{category.name}}
              </mat-option>
          </mat-select>
          <mat-hint *ngIf="categories.length === 0" class="no-data">You must add a category first!</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Legal</mat-label>
        <mat-select formControlName="legalId" [disabled]="legals.length === 0">
            <mat-option *ngFor="let legal of legals" [value]="legal.legalId">
            {{legal.name}}
            </mat-option>
        </mat-select>
        <mat-hint *ngIf="legals.length === 0" class="no-data">You must add a legal first!</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label> Payment Partner </mat-label>
        <mat-select formControlName="paymentPartnerId" [disabled]="paymentPartners.length === 0">
            <mat-option [value]="''"></mat-option>
            <mat-option *ngFor="let paymentPartner of paymentPartners" [value]="paymentPartner.paymentPartnerId">
            {{paymentPartner.name}}
            </mat-option>
        </mat-select>
      </mat-form-field>
  
    </div>

    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Merchant UUID</mat-label>
        <input formControlName="merchantUuid" matInput />     
    
      </mat-form-field>
      <span *ngIf="merchantUUID.errors">
        <mat-error *ngIf="merchantUUID.errors.duplicate">This Merchant UUID Already exist</mat-error>
      </span> 
      <mat-form-field appearance="fill">
        <mat-label>Qr Code</mat-label>
        <input formControlName="qrCode" matInput />
        <mat-error>Qr Code is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Terminal ID</mat-label>
        <input formControlName="terminalId" matInput />
      </mat-form-field>
    </div>

    <div class="paddingButtom">
      <span>Contact</span>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Phone</mat-label>
          <input formControlName="phone" matInput/>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
          <mat-label>Website</mat-label>
          <input formControlName="website" matInput/>
        </mat-form-field>
      </div>
    </div>
    <div [formGroup]="pointofsaleForm?.get('address')" class="paddingButtom">
      <span>Address</span>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Street</mat-label>
          <input formControlName="street" matInput (change)="onChange()"/>
          <mat-error>Street is required</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
          <mat-label>Zip Code</mat-label>
          <input formControlName="zipCode" matInput (change)="onChange()" />
          <mat-error *ngIf="pointofsaleForm.get('address').get('zipCode').errors?.required">
            Zip Code is required
          </mat-error>
          <mat-error *ngIf="pointofsaleForm.get('address').get('zipCode').errors?.pattern
              || pointofsaleForm.get('address').get('zipCode').errors?.minlength
              || pointofsaleForm.get('address').get('zipCode').errors?.maxlength">
            Only 4 digit number is allowed
          </mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
          <mat-label>City</mat-label>
          <input formControlName="city" matInput (change)="onChange()" />
          <mat-error *ngIf="pointofsaleForm.get('address').get('city').errors?.required">
            City is required
          </mat-error>
          <mat-error *ngIf="pointofsaleForm.get('address').get('city').errors?.pattern">
            Numbers are not allowed
          </mat-error>
        </mat-form-field>

        <span *ngIf="isTypeInvalid" class="no-data" >Invalid address, couldn't find an exact match.</span>
        <span *ngIf="isAddressInvalid" class="no-data" >Invalid Address!</span>

      </div>
    </div>

    <div [formGroup]="pointofsaleForm?.get('location')" class="paddingButtom">
      <span>Location</span>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Latitude</mat-label>
          <input formControlName="latitude" matInput/>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
          <mat-label>Longitude</mat-label>
          <input formControlName="longitude" matInput/>
        </mat-form-field>
      </div>
    </div>

    <div class="paddingButtom" *ngIf="hasOpeninHours">
      <span>Opening Hours</span>
      <ng-container *ngFor="let periodForm of periods.controls; let periodFormIndex = index">
        <div [formGroup]="periodForm" class="paddingButtom">
          <div class="row">
            <mat-button-toggle-group multiple class="day-btn-group" formControlName="days">
              <mat-button-toggle class="day-btn" [value]="0">M</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="1">T</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="2">W</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="3">T</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="4">F</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="5">S</mat-button-toggle>
              <mat-button-toggle class="day-btn" [value]="6">S</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="row">
            <mat-checkbox [checked]="periodForm.controls.close.value === null" (change)="onOpen24hCheckboxChange($event, periodForm)">
              Open 24h
            </mat-checkbox>
            <mat-checkbox [checked]="periodForm.controls.open.value === null" (change)="onClose24hCheckboxChange($event, periodForm)">
              Close 24h
            </mat-checkbox>
          </div>
          <div class="row" *ngIf="periodForm.controls.open.value !== null 
                              && periodForm.controls.close.value !== null">
            <mat-form-field appearance="fill">
              <mat-label>Open time</mat-label>
              <input matInput formControlName="open" />
              <mat-error *ngIf="periodForm.controls.open.errors?.pattern">
                Time must be 4 digits in 24 hours system (e.g., 0900, 1500, 2359)
              </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="fill">
              <mat-label>Close time</mat-label>
              <input matInput formControlName="close" />
              <mat-error *ngIf="periodForm.controls.close.errors?.pattern">
                Time must be 4 digits in 24 hours system (e.g., 0900, 1500, 2359)
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row paddingButtom" >
          <button mat-mini-fab (click)="onRemoveHours(periodFormIndex)" class="action-btn" >
            -
          </button>
          Remove Hours
        </div>
      </ng-container>
      
      <div class="row" >
        <button mat-mini-fab (click)="onAddHours()" class="action-btn">
          +
        </button>
        Add Hours
      </div>

    </div>
    <div style="margin-top: 15px; font-size: large;" *ngIf="filters.length">
      <mat-label style="font-weight: bold;">Filters</mat-label>
      <ng-container *ngFor="let filter of filters">
        <div *ngIf="filter.key !== 'openingHours'">
          <mat-label class="radio-label" style="display: block;">{{filter.title['en-us']}}</mat-label>
          <ng-container *ngIf="filter.valueType === 'yesOrNo' else valueTemp;">
            <mat-radio-group style="display: block;">
              <mat-radio-button [value]="true" 
              [checked]="pointofsaleInit.filterProps ? pointofsaleInit.filterProps[filter.filterId] === true: null" 
              (change)="radioChange($event, filter.filterId)">
               <span style="font-weight: 100;">Yes</span>
              </mat-radio-button>
              <mat-radio-button 
              [value]="false"  
              [checked]="pointofsaleInit.filterProps ? pointofsaleInit.filterProps[filter.filterId] === false: null" 
              (change)="radioChange($event, filter.filterId)">
              <span style="font-weight: 100;">No</span>
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-template #valueTemp>
              <mat-checkbox 
              *ngFor="let value of filter.values"
              [checked]="pointofsaleInit.filterProps ? pointofsaleInit.filterProps[filter.filterId]?.includes(value.filterValueId) : null"
               [value]="value.filterValueId" (change)="checkBoxChange($event, filter.filterId, value.filterValueId)">
               
               <span style="font-weight: 100;margin-right: 10px;">{{ value['en-us'] }}</span>

              </mat-checkbox>
          </ng-template>
        </div>
      </ng-container>      
    </div>
    <button type="submit" (click)="submit()" [disabled]="pointofsaleForm.invalid || isAddressInvalid || isTypeInvalid" color="success" mat-button>Save</button>
    <button type="submit" (click)="backToListPointOfSalesUrl()"  mat-raised-button style="margin-left: 10px">Cancel</button>
  </form>
</div>

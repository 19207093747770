
<table class="table table-hover" *ngIf="filtersVm">
    <thead>
      <th>Key</th>
      <th>Filter Type</th>
      <th>Title EN</th>
      <th>Title DE</th>
      <th>Title FR</th>
      <th>Title IT</th>
      <th>Values In EN</th>
      <th>Values In DE</th>
      <th>Values In FR</th>
      <th>Values In IT</th>
      <th>Actions</th>
    </thead>
    <tbody>
      <tr *ngFor="let filter of filtersVm" style="background-color: white;">
        <td>{{filter.key}}</td>
        <td>{{filter.valueType === 'value' ? 'Value': 'Boolean'}}</td>
        <td>{{filter.title['en-us']}}</td>
        <td>{{filter.title['de-ch']}}</td>
        <td>{{filter.title['it-ch']}}</td>
        <td>{{filter.title['fr-ch']}}</td>
        <td>{{filter.valueInEnglish}}</td>
        <td>{{filter.valueInGerman}} </td>
        <td>{{filter.valueInFrench}}</td>
        <td>{{filter.valueInItaly}}</td>
        <td class="d-flex-nowrap" >
          <button *ngIf="!filter.isCustom" mat-button-icon style="margin-left: 5px;" [routerLink]="['../add-filter/' + filter.filterId]">
            Edit 
          </button>
          <button *ngIf="!filter.isCustom" mat-button-icon (click)="deleteFilter(filter.filterId)">
            Delete
          </button>
        </td>
      </tr>
    </tbody>
</table>
<div class="action-container">
  <a mat-raised-button [routerLink]="['../add-filter']">
      <mat-icon>add</mat-icon>
  </a>
</div>
   
  
import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ICategoryFilter } from '@modeso/types__tsf-ms-pointofsales';
import { BasePage } from '../../base.page';

@Component({
  selector: 'app-add-new-filter',
  templateUrl: './add-new-filter.component.html',
  styleUrls: ['./add-new-filter.component.scss']
})
export class AddNewFilterComponent extends BasePage implements OnInit {
  public filterForm!: FormGroup;
  filterId: string | null;
  initFilter = {} as ICategoryFilter;
  editMode?: boolean;
  constructor(private fb: FormBuilder, private posProvider: PointOfSalesProvider, private router: ActivatedRoute, 
    private injector: Injector){
    super(injector);
    this.filterId = this.router.snapshot.paramMap.get('id');
    
  }

  override ngOnInit(): void {
    this.subscriptions.push(this.posProvider.getFilterError$().subscribe((error) => {
      if(!error) return;
      if(error.error?.message?.includes('Duplicate Key Error')) {
        this.key?.setErrors({duplicate: true});
      }
   }));
    if(this.filterId) {
      this.editMode = true;
      this.subscriptions.push(this.posProvider.getFilterById(this.filterId).subscribe((x)=> {
          if(!x) return;
          this.initFilter = x;
          this.initForm();
        }));
    }
    this.initForm();
  }

  get title() {
    return this.filterForm.get('title')
  }
  get key() {
    return this.filterForm.get('key')
  }
  get valueType() {
    return this.filterForm.get('valueType')
  }
  get multiLanguage() {
    return this.filterForm.get('values')?.get('multiLanguage') as FormArray;
  }
  onAddValues() {
    this.multiLanguage!.push(this.fb.group({
        "en-us": this.fb.control([], Validators.required),
        "de-ch": this.fb.control([], Validators.required),
        "fr-ch": this.fb.control([], Validators.required),
        "it-ch": this.fb.control([], Validators.required),
    }));
  }

  createValueFormArray(values: any): FormArray {
    if(!values.length){
      return this.fb.array([]);
    }
    return this.fb.array(
      values?.map( (value: any) => {
        return this.fb.group({
          "en-us": this.fb.control(value["en-us"], Validators.required),
          "de-ch": this.fb.control(value["de-ch"], Validators.required),
          "fr-ch": this.fb.control(value["fr-ch"], Validators.required),
          "it-ch": this.fb.control(value["it-ch"], Validators.required),
          filterValueId: this.fb.control(value["filterValueId"])
        });
      })
    ); 
  }

    onSave() {
      let value: ICategoryFilter = {
        ...this.filterForm.value, 
        values: this.multiLanguage.value, 
      };
      if(this.filterId) {
          value = {
            ...value,
            filterId: this.filterId,
          };
      }
      this.posProvider.addOrUpdateCategoryFilter(value);
    }

    updateValidations() {
      if(this.valueType?.value === "value") {
        const groupItems: any = (this.multiLanguage as FormArray).controls;
          if(!groupItems.length) {
            this.multiLanguage.push(this.fb.group({
              "en-us": this.fb.control([], Validators.required),
              "de-ch": this.fb.control([], Validators.required),
              "fr-ch": this.fb.control([], Validators.required),
              "it-ch": this.fb.control([], Validators.required),
          }))
        }
        for(let item of groupItems) {
            (item.controls["de-ch"] as FormControl).setValidators(Validators.required);
            item.controls["fr-ch"].setValidators(Validators.required);
            item.controls["it-ch"].setValidators(Validators.required);
            item.controls["en-us"].setValidators(Validators.required);
            (item.controls["de-ch"] as FormControl).updateValueAndValidity();
            (item.controls["it-ch"] as FormControl).updateValueAndValidity();
            (item.controls["fr-ch"] as FormControl).updateValueAndValidity();
            (item.controls["en-us"] as FormControl).updateValueAndValidity();
        }
      } else {
        this.multiLanguage.clear();

        this.multiLanguage.controls.forEach(c => c.clearValidators());
      }
    }

    private initForm() {
      this.filterForm = this.fb.group({
        valueType: new FormControl(this.initFilter.valueType || '', [Validators.required]),
        title: new FormGroup({
          "en-us": new FormControl(this.initFilter.title ? this.initFilter.title['en-us']: "", [Validators.required]),
          "de-ch": new FormControl(this.initFilter.title ? this.initFilter.title['de-ch']: "", [Validators.required]),
          "fr-ch": new FormControl(this.initFilter.title ? this.initFilter.title['fr-ch']: "", [Validators.required]),
          "it-ch": new FormControl(this.initFilter.title ? this.initFilter.title['it-ch']: "", [Validators.required]),
        }),
        values: this.fb.group({
          multiLanguage: this.createValueFormArray(this.initFilter.values || [])
        }),
        key: new FormControl(this.initFilter.key || '', [Validators.required, Validators.pattern(/^\S*$/)]),
      });
  
    }
}



import { createAction, props, Action } from '@ngrx/store';
import { AnalyticsReportRequest } from '../models/analytics-reports/analyticsReportRequest.model';

// TODO change reportResponse type alias to correct model classes
type reportResponse = {};

export const getReport = createAction(
    '[Admin] getReport',
    props<{ payload: AnalyticsReportRequest }>()
);

export const onGetReportSuccessfully = createAction(
    '[Admin Report] getReport successfully',
    props<{ payload: reportResponse }>()
);

export const onGetReportFailed = createAction(
    '[Admin Report] getReport failed',
    props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}

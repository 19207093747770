import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hourFormat' })
export class HourFormatPipe implements PipeTransform {
  transform(value: string): string {
    const hours = value.substring(0, 2);
    const minutes = value.substring(2);
    return `${hours}:${minutes}`;
  }
}

export const POSExportColumnNames = [
    "Name_German",
    "Name_English",
    "Name_French",
    "Name_Italian",
    "Description_German",
    "Description_English",
    "Description_French",
    "Description_Italian",
    "Legal Uuid",
    "Legal Name",
    "Merchant_UUID",
    "Qr_Code",
    "Terminal Id",
    "Street",
    "Zip_Code",
    "City",
    "Website",
    "Phone"
];

export type IMapper = {
    [key: string]: string;    
}

export const MapPOSToExportColumns ={
    "name['de-ch']":"Name_German",
    "name['en-us']":"Name_English",
    "name['fr-ch']":"Name_French",
    "name['it-ch']":"Name_Italian",
    "description['de-ch']":'Description_German',
    "description['en-us']":'Description_English',
    "description['fr-ch']":'Description_French',
    "description['it-ch']":'Description_Italian',
    "legal": "Legal Uuid",
    "legalName":"Legal Name",
    "merchantUuid":"Merchant_UUID",
    "qrCode":"Qr_Code",
    "terminalId":"Terminal Id" ,
    "address.zipCode":"Zip_Code",
    "address.city":"City",
    "address.street":"Street",
    "website":"Website",
    "phone":"Phone",
}

export const DayName: {[key: number]: string} = {
    0: "Monday",
    1: "Tuesday", 
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday"
}

export const RomanValues: string[] = ['I','II'];

  
import { Injectable, InjectionToken, Inject } from '@angular/core';
import Debug from 'debug';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay, take, finalize } from 'rxjs/operators';
import { AdminProvider } from '@modeso/tsf-lib-admin-fe';

const debug = Debug('dgoods:session:AuthHandler');

export interface IAuthHandlerDelegate {
    onSessionExpired():void;
}

export const AUTH_INTERCEPTOR = new InjectionToken<IAuthHandlerDelegate>('AUTH_INTERCEPTOR');
@Injectable()
export class AuthHandler implements HttpInterceptor {
    refreshRequestIsRunning: boolean = false;

    constructor(private sessionProviders: AdminProvider,
        @Inject(AUTH_INTERCEPTOR) private authHandlerDelegate: IAuthHandlerDelegate) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq;
        if (!request.url.includes('storage.googleapis.com')) {

            modifiedReq = request.clone({
                headers: request.headers.set('content-language', 'en-us'),
                withCredentials: true,
            });
        } else {
            modifiedReq = request.clone({});
        }


        return next.handle(modifiedReq).pipe(
            catchError(err => {
                if (err.status === 440) {
                    if (this.authHandlerDelegate) {
                        debug('onSessionExired');
                        debug(request.url);
                        this.authHandlerDelegate.onSessionExpired();
                    } else {
                        debug('onSessionExired event not fired. No delegate defined for the AuthHandler.');
                    }
                }

                return throwError(() => err);
            }),
            retryWhen(errors => errors
                .pipe(
                    concatMap((error) => {
                        if ((error.status === 401)) {
                            debug('JWT Token exired, lease new one');
                            debug(request.url);
                            this.sessionProviders.getNewAccsessToken$();
                            return of(error.status);
                        }
                        return throwError(() => error);
                    }),
                    take(2),
                    delay(1000)
                )
            )
        );

    }
}



import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminService } from '../services/admin.service';
import * as fromActions from '../actions/admin.action';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminEffects');


@Injectable()
export class AdminEffects {
    constructor(private actions$: Actions, private service: AdminService) { }
    
    login$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.login.type),
            mergeMap(
                (payload) => {
                    return this.service.login(payload)
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onLoginSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onLoginFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onLoginFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    logout$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.logout),
            mergeMap(
                () => {
                    return this.service.logout()
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onLogoutSuccessfully();
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onLogoutFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnLogout$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onLogoutFailed),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    getNewAccessToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getNewAccessToken.type),
            mergeMap(
                () => {
                    return this.service.getNewAccesToken()
                        .pipe(
                            map(
                                response => {
                                    return fromActions.onGetNewAccessTokenSuccessfully();
                                }
                            ),
                            catchError((error) => {
                                return of(fromActions.onGetNewAccessTokenFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

    errorOnGetNewAccessToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetNewAccessTokenFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    handleOnLoadAllErrors(error: any) {
        debug(error);
        return error;
    }

    // tslint:disable-next-line:member-ordering
    errorOnGetAdminUsers$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetAdminUsersFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    errorOnGetUserRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.onGetUserRolesFailed.type),
            tap(
                (action: fromActions.ActionWithPayload<HttpErrorResponse>) => this.handleOnLoadAllErrors(action.payload)
            )
        ), { dispatch: false }
    );

    getUserRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getUserRoles.type),
            mergeMap(
                () => {
                    return this.service.getUserRoles()
                        .pipe(
                            map(
                                (response:any) => {
                                    return fromActions.onGetUserRolesSuccessfully({ payload: response });
                                }
                            ),
                            catchError((error: HttpErrorResponse) => {
                                return of(fromActions.onGetUserRolesFailed({ payload: error }));
                            })
                        );
                }
            )
        )
    );

}



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayFromIndex' })
export class GetDayFromIndexPipe implements PipeTransform {
  dayInText: {[key: number]: string} = {
    0: 'tsf_pos_details_open_hours_monday',
    1: 'tsf_pos_details_open_hours_tuesday',
    2: 'tsf_pos_details_open_hours_wednesday',
    3: 'tsf_pos_details_open_hours_thursday',
    4: 'tsf_pos_details_open_hours_friday',
    5: 'tsf_pos_details_open_hours_saturday',
    6: 'tsf_pos_details_open_hours_sunday'
  }
  transform(value: number): string {
     return this.dayInText[value];
  }
}

<div class="list-categories-container">
    <div>
      <button class="add-category" (click)="addCategory()">
        <span>ADD CATEGORY</span>
      </button>
    </div>
    
    <div *ngIf="categories && categories.length > 0">
      <table mat-table [dataSource]="categories" class="table">
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
             Category Name
          </th>
          <td mat-cell *matCellDef="let category">
            <span>
              {{category.name}}
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="buttonText">
          <th mat-header-cell *matHeaderCellDef>
            Button Text
          </th>
          <td mat-cell *matCellDef="let category">
            {{category.buttonText['en-us']}}
          </td>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="actions" mat-cell *matCellDef="let category">
            <button class="states" (click)="editCategory(category.categoryId)">Edit</button>
            <button class="states" (click)="deleteCategory(category.categoryId)">Delete</button>
          </td>
        </ng-container>

  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
    </div>

    <div *ngIf="categories.length===0">
      <span>There are no categories stored yet or there was an error fetching them.</span>
    </div>
  
  </div>
import { createAction, props, Action } from '@ngrx/store';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse } from '../models/administration/userResponse.model';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */
export const login = createAction(
    '[Admin] login',
    props<{ payload: LoginUser }>()
);
export const onLoginSuccessfully = createAction(
    '[Admin] login successfully',
    props<{ payload: UserResponse }>()
);

export const onLoginFailed = createAction(
    '[Admin] login failed',
    props<{ payload: any }>()
);

export const logout = createAction(
    '[Admin] logout'
);

export const onLogoutSuccessfully = createAction(
    '[Admin] logout successfully',
);

export const onLogoutFailed = createAction(
    '[Admin] logout failed',
    props<{ payload: any }>()
);

export const getNewAccessToken = createAction(
    '[Admin] getNewAccessToken'
);

export const onGetNewAccessTokenSuccessfully = createAction(
    '[Admin] getNewAccessToekn successfully',
);

export const onGetNewAccessTokenFailed = createAction(
    '[Admin] getNewAccessToken failed',
    props<{ payload: any }>()
);

export const getAdminUsers = createAction(
    '[Admin] getAdminUsers'
);

export const onGetAdminUsersFailed = createAction(
    '[Admin] onGetAdminUsersFailed',
    props<{ payload: HttpErrorResponse }>()
);

export const getUserRoles = createAction(
    '[Admin] getUserRoles'
);

export const onGetUserRolesSuccessfully = createAction(
    '[Admin] onGetUserRolesSuccessfully',
    props<{ payload: string[] }>()
);

export const onGetUserRolesFailed = createAction(
    '[Admin] onGetUserRolesFailed',
    props<{ payload: HttpErrorResponse }>()
);

export const resetStore = createAction('[TSF reset Store] Reset Store');


/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
    payload: T;
}

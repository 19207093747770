import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminProvider, UserRolesResponse } from '@modeso/tsf-lib-admin-fe';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { BasePage } from '../base.page';

@Component({
  selector: 'app-saml-assert',
  templateUrl: './samlAssert.page.html',
  styleUrls: ['./samlAssert.page.scss']
})
export class SamlAssertPage extends BasePage implements OnInit {

  isError = false;
  errorMessage = 'Error Occured';

  constructor(injector: Injector, private adminProvider: AdminProvider,
    private localStorageService: LocalStorageService, private router: Router) {
    super(injector);
  }

  override ngOnInit() {
    this.assertLogin();
    this.initErrors();
  }

  assertLogin() {
    this.subscriptions.push(
      this.adminProvider.assertLogin$().subscribe((response: UserRolesResponse) => {
        if (response && response.roles) {
          this.isError = false;
          this.localStorageService.setAuthorization();
          this.router.navigate(['/']);
        }
      })
    );
  }

  initErrors() {
    this.subscriptions.push(
      this.adminProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {
        if (errorResponse) {
          this.isError = true;
        }
      })
    );
  }
}

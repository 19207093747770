import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as PointOfSalesFromActions from '../actions/pointofsales.action';
import * as CategoriesFromActions from '../actions/category.action';
import * as PaymentPartnerFromActions from '../actions/paymentPartner.action';
import * as LegalActions from '../actions/legal.action';
import * as FiltersActions from '../actions/filter.action';
import { ICategory, ILegal, IPaymentPartner, IPointOfSales, IPointOfSaleDto, ICategoryFilter, ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales';
import { PointOfSaleUtils } from '../utils/utilties';
export const pointofsaleFeatureKey = 'modesoPointOfSaleMicroservice';

// State Declarations - START

export interface FeatureState {
    pointOfSalesDto: IPointOfSaleDto[];
    allPointOfSales: IPointOfSaleDto[];
    pointOfSales: IPointOfSales[];
    pointOfSalesError:undefined;
    categories: ICategory[];
    categoryError: undefined;
    paymentPartners: IPaymentPartner[];
    paymentPartnerError: undefined;
    legals: ILegal[];
    legalError: undefined;
    categoryFilters?: ICategoryFilter[];
    translatedCategories?: ITranslatedCategory[];
    currentSelectedCategories?: string[];
    currentAppliedFilter: any;
    showFilter: boolean;
    userLocationEnabled: boolean;
    filters?: ICategoryFilter[];
    filterById?: ICategoryFilter;
    addOrUpdateCategoryFilterError: any; 
}

export interface AppState {
    modesoPointOfSaleMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoPointOfSaleMicroservice;


export const selectFeaturePointOfSales = createSelector(
    selectFeature,
    (state: FeatureState) => state.pointOfSales
);

export const selectFeaturePointOfSalesError = createSelector(
    selectFeature,
    (state: FeatureState) => state.pointOfSalesError
);


export const selectFeatureCategories = createSelector(
    selectFeature,
    (state: FeatureState) => state.categories
);

export const selectFeatureCategoryError = createSelector(
    selectFeature,
    (state: FeatureState) => state.categoryError
);

export const selectFeaturePaymentPartners = createSelector(
    selectFeature,
    (state: FeatureState) => state.paymentPartners
);

export const selectFeaturePaymentPartnersError = createSelector(
    selectFeature,
    (state: FeatureState) => state.paymentPartnerError
);

export const selectFeatureLegals = createSelector(
    selectFeature,
    (state: FeatureState) => state.legals
);

export const selectFeatureLegalsError = createSelector(
    selectFeature,
    (state: FeatureState) => state.legalError
);
export const selectFeatureFilters = createSelector(
    selectFeature,
    (state: FeatureState) => state.filters
);
export const selectFeaturePointOfSalesByCategory = createSelector(
    selectFeature,
    (state: FeatureState) => state.pointOfSalesDto
);
export const selectFeatureCategoryFilter = createSelector(
    selectFeature,
    (state: FeatureState) => state.categoryFilters
);

export const selectFeatureTranslatedCategories = createSelector(
    selectFeature,
    (state: FeatureState) => state.translatedCategories
);

export const selectFeatureCurrentSelectedCategoriesIds = createSelector(
    selectFeature,
    (state: FeatureState) => state.currentSelectedCategories
);

export const selectFeatureCurrentAppliedFilter = createSelector(
    selectFeature,
    (state: FeatureState) => state.currentAppliedFilter
);
export const selectFeatureFilterVisibility = createSelector(
    selectFeature,
    (state: FeatureState) => state.showFilter
);

export const selectFeatureFilter = createSelector(
    selectFeature,
    (state: FeatureState) => state.filterById
);

export const selectFeatureFilterError = createSelector(
    selectFeature,
    (state: FeatureState) =>{
        return state.addOrUpdateCategoryFilterError
    }
);
export const selectFeatureGetSelectedCategories = (categoriesIds: string[]) => createSelector(
    selectFeature,
    selectFeatureTranslatedCategories,
    (state: FeatureState, translatedCategories: ITranslatedCategory[] | undefined) => {
       const data = translatedCategories?.filter(category => categoriesIds.includes(category.categoryId));
       return data;
    }
);


export const selectUserLocationEnabled = createSelector(
    selectFeature,
    (state: FeatureState) => state.userLocationEnabled
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    pointOfSales:[],
    pointOfSalesError:undefined,
    categories: [],
    categoryError:undefined,
    paymentPartners: [],
    paymentPartnerError:undefined,
    legals: [],
    legalError:undefined,
    pointOfSalesDto: [],
    allPointOfSales: [],
    categoryFilters: undefined,
    translatedCategories: undefined,
    currentSelectedCategories: [],
    currentAppliedFilter: undefined,
    showFilter: false,
    userLocationEnabled: false,
    filters: undefined,
    addOrUpdateCategoryFilterError: undefined
};

const pointofsalesReducer = createReducer(
    initialState,
    // point of sales
    on(PointOfSalesFromActions.onAddOrUpdatePointOfSale, (state) => ({ ...state , pointOfSalesError:undefined })),
    on(PointOfSalesFromActions.onAddOrUpdatePointOfSaleSuccess, (state, action) => {

        const updatedArray = [...state.pointOfSales];
        const updatedIndex = state.pointOfSales.findIndex((pointOfSales) =>{
                return pointOfSales.pointofsaleId === action.payload.pointofsaleId
        });
        
        updatedIndex === -1 ?
        updatedArray.push({...action.payload}) :  
        updatedArray[updatedIndex] = {
            ...updatedArray[updatedIndex],
            ...action.payload
        };
        
        return { ...state, pointOfSales: [...updatedArray] , pointOfSalesError:undefined};
    }),
    on(PointOfSalesFromActions.onAddOrUpdatePointOfSaleFailed, (state, action) => ({ ...state , 
        pointOfSalesError: action.payload})),

    on(PointOfSalesFromActions.onGetPointOfSales, (state) => ({ ...state , pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onGetPointOfSalesSuccess, (state, action) => ( { ...state , pointOfSales: action.payload , pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onGetPointOfSalesFailed, (state, action) => ({ ...state , pointOfSalesError:action.payload })),

    on(PointOfSalesFromActions.onGetPointOfSalesByCategory, (state) => ({ ...state , 
        pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onGetPointOfSalesByCategorySuccess, (state, action) => ( { ...state ,
         pointOfSalesDto: action.payload, allPointOfSales: action.payload , pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onGetPointOfSalesByCategoryFailed, (state, action) => ({ ...state , 
        pointOfSalesError:action.payload })),

    on(PointOfSalesFromActions.onSetFilterPosByCategories, (state, action) => {
        const filteredCategories = action.payload;
        if(!filteredCategories.length) {
            return {...state, pointOfSalesDto: [...state.allPointOfSales], 
                currentSelectedCategories: undefined, categoryFilters: undefined}
        }
        return {
            ...state,
            currentSelectedCategories: filteredCategories
        }
    }),
    on(PointOfSalesFromActions.onApplyFilterPosByCategories, (state) => {
        if(!state.currentSelectedCategories) {
            return {...state, pointOfSalesDto: [...state.allPointOfSales], 
                currentSelectedCategories: undefined, categoryFilters: undefined}
        }
        const filteredCategories = state.currentSelectedCategories;
        const filteredPosesByCategories =
         state.allPointOfSales.filter((pos) => filteredCategories.includes(pos.category));
        const filteredPos = state.currentAppliedFilter ?
         PointOfSaleUtils.filterPos(filteredPosesByCategories, state.currentAppliedFilter) 
        : filteredPosesByCategories;
        return {
            ...state,
            pointOfSalesDto: filteredPos,
        }
    }),
    on(PointOfSalesFromActions.onImportPointOfSales, (state) => ({ ...state , pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onImportPointOfSalesSuccess, (state, action) => ( { ...state  , pointOfSalesError:{...action.payload,status:200}})),
    on(PointOfSalesFromActions.onImportPointOfSalesFailed, (state, action) => ({ ...state , pointOfSalesError:{...action.payload.error,status:action.payload.status}})),

    on(PointOfSalesFromActions.onDeletePointOfSale, (state) => ({ ...state, pointOfSalesError:undefined})),
    on(PointOfSalesFromActions.onDeletePointOfSaleSuccess, (state, action) => {
        const updatedPointOfSales = state.pointOfSales.filter( pointOfSale => {
            return pointOfSale.pointofsaleId !== action.payload
        });
        return {
            ...state,
            pointOfSales: updatedPointOfSales
        }
    }),
    on(PointOfSalesFromActions.onDeletePointOfSaleFailed, (state, action) => ({ ...state, pointOfSalesError: action.payload})),
    on(PointOfSalesFromActions.onSetFilterPos, 
        (state, action) => {
            if(!action.payload) {
                return {...state, currentAppliedFilter: undefined}
            }
            let newAppliedFilter = {...state.currentAppliedFilter};
            newAppliedFilter[action.payload['key']] = action.payload['value'];       
            return {...state, currentAppliedFilter: newAppliedFilter}
    }),
    on(PointOfSalesFromActions.onApplyFilterPos, 
        (state) => {
            let newFilteredArray;
            let newAppliedFilter = {...state.currentAppliedFilter};
            if(Object.keys(newAppliedFilter).length) {
              const filteredPosesByCategories = state.allPointOfSales.filter((pos) => 
               state.currentSelectedCategories?.includes(pos.category));
              newFilteredArray = PointOfSaleUtils.filterPos(filteredPosesByCategories, newAppliedFilter);
            } else {
                newFilteredArray = [...state.pointOfSalesDto];
            }
            return {...state, pointOfSalesDto: newFilteredArray}
        }
    ),
    on(PointOfSalesFromActions.onShowOrHideFilterPos, (state, action) => ({...state, showFilter: action.payload})),
    on(PointOfSalesFromActions.onSetUserLocationEnabled, (state, action) => ({ ...state, userLocationEnabled: action.payload })),
    // categories
    on(CategoriesFromActions.onAddOrUpdateCategory, (state) => ({ ...state , categoryError:undefined })),
    on(CategoriesFromActions.onAddOrUpdateCategorySuccess, (state, action) => {
        const updatedArray = [...state.categories];
        const updatedIndex = state.categories.findIndex((category) =>{
                return category.categoryId === action.payload.categoryId
        });
        
        updatedIndex === -1 ?
        updatedArray.push({...action.payload}) :  
        updatedArray[updatedIndex] = {
            ...updatedArray[updatedIndex],
            ...action.payload
        };
        
        return { ...state, categories: [...updatedArray] , categoryError:undefined};
    }),
    on(CategoriesFromActions.onAddOrUpdateCategoryFailed, (state, action) => ({ ...state , categoryError:action.payload})),
    on(CategoriesFromActions.onGetCategories, (state) => ({ ...state , categoryError:undefined})),
    on(CategoriesFromActions.onGetCategoriesSuccess, (state, action) => ( { ...state , categories: action.payload , categoryError:undefined})),
    on(CategoriesFromActions.onGetCategoriesFailed, (state, action) => ({ ...state , categoryError:action.payload })),

    on(CategoriesFromActions.onDeleteCategory, (state) => ({ ...state, categoryError:undefined})),
    on(CategoriesFromActions.onDeleteCategorySuccess, (state, action) => {
        const updatedCategories = state.categories.filter( category => {
            return category.categoryId !== action.payload
        });
        return {
            ...state,
            categories: updatedCategories
        }
    }),
    on(CategoriesFromActions.onDeleteCategoryFailed, (state, action) => ({ ...state, categoryError: action.payload})),

    on(CategoriesFromActions.onGetCategoryFilter, (state) => ({ ...state})),
    on(CategoriesFromActions.onGetCategoryFilterSuccess, (state, action) => {
        return {
            ...state,
            categoryFilters: action.payload
        }
    }),
    on(CategoriesFromActions.onGetCategoryFilterFailed, (state, action) => ({ ...state, categoryError: action.payload})),

    
    on(CategoriesFromActions.onGetTranslatedCategories, (state) => ({ ...state})),
    on(CategoriesFromActions.onGetTranslatedCategoriesSuccess, (state, action) => {
        return {
            ...state,
            translatedCategories: action.payload
        }
    }),
    on(CategoriesFromActions.onGetTranslatedCategoriesFailed, (state, action) => ({ ...state, categoryError: action.payload})),

     // payment partners
    on(PaymentPartnerFromActions.onAddOrUpdatePaymentPartner, (state) => ({ ...state , paymentPartnerError:undefined })),
    on(PaymentPartnerFromActions.onAddOrUpdatePaymentPartnerSuccess, (state, action) => {
        const updatedArray = [...state.paymentPartners];
        const updatedIndex = state.paymentPartners.findIndex((paymentPartner) =>{
                return paymentPartner.paymentPartnerId === action.payload.paymentPartnerId
        });
        
        updatedIndex === -1 ?
        updatedArray.push({...action.payload}) :  
        updatedArray[updatedIndex] = {
            ...updatedArray[updatedIndex],
            ...action.payload
        };
         
        return { ...state, paymentPartners: [...updatedArray] , paymentPartnerError:undefined};
    }),
    
    on(PaymentPartnerFromActions.onAddOrUpdatePaymentPartnerFailed, (state, action) => ({ ...state , paymentPartnerError:action.payload})),
 
 
    on(PaymentPartnerFromActions.onGetPaymentPartners, (state) => ({ ...state , paymentPartnerError:undefined})),
    on(PaymentPartnerFromActions.onGetPaymentPartnersSuccess, (state, action) => ( { ...state , paymentPartners: action.payload , paymentPartnerError:undefined})),
    on(PaymentPartnerFromActions.onGetPaymentPartnersFailed, (state, action) => ({ ...state , paymentPartnerError:action.payload })),

    on(PaymentPartnerFromActions.onDeletePaymentPartner, (state) => ({ ...state, paymentPartnerError:undefined})),
    on(PaymentPartnerFromActions.onDeletePaymentPartnerSuccess, (state, action) => {
        const updatedPaymentPartners = state.paymentPartners.filter( paymentPartner => {
            return paymentPartner.paymentPartnerId !== action.payload
        });
        return {
            ...state,
            paymentPartners: updatedPaymentPartners
        }
    }),
    on(PaymentPartnerFromActions.onDeletePaymentPartnerFailed, (state, action) => ({ ...state, paymentPartnerError: action.payload})),
 

     // legals
    on(LegalActions.onAddOrUpdateLegal, (state) => ({ ...state , legalError:undefined })),
    on(LegalActions.onAddOrUpdateLegalSuccess, (state, action) => {
        const updatedArray = [...state.legals];
        const updatedIndex = state.legals.findIndex((legal) =>{
                return legal.legalId === action.payload.legalId
        });
         
        updatedIndex === -1 ?
        updatedArray.push({...action.payload}) :  
        updatedArray[updatedIndex] = {
            ...updatedArray[updatedIndex],
            ...action.payload
        };
         
        return { ...state, legals: [...updatedArray] , legalError:undefined};
    }),
    on(LegalActions.onAddOrUpdateLegalFailed, (state, action) => ({ ...state , legalError:action.payload})),
    on(LegalActions.onGetLegals, (state) => ({ ...state , legalError:undefined})),
    on(LegalActions.onGetLegalsSuccess, (state, action) => ( { ...state , legals: action.payload , legalError:undefined})),
    on(LegalActions.onGetLegalsFailed, (state, action) => ({ ...state , legalError:action.payload })),
 
    on(LegalActions.onDeleteLegal, (state) => ({ ...state, legalError:undefined})),
    on(LegalActions.onDeleteLegalSuccess, (state, action) => {
        const updatedLegals = state.legals.filter( legal => {
            return legal.legalId !== action.payload
        });
        return {
            ...state,
            legals: updatedLegals
        }
    }),
    on(LegalActions.onDeleteLegalFailed, (state, action) => ({ ...state, legalError: action.payload})),

    on(FiltersActions.onGetAllFilters, (state) => ({ ...state, error: undefined, filters: undefined})),
    on(FiltersActions.onGetFiltersSuccess, (state, action) => ({...state, filters: action.payload})),
    on(FiltersActions.onGetFiltersFailed, (state, action) => ({ ...state, error: action.payload, filters: undefined})),

    on(FiltersActions.onAddOrUpdateFilter, (state) => ({...state,})),
    on(FiltersActions.onAddOrUpdateFilterSuccess, (state, action) => {
       return {...state, addOrUpdateCategoryFilterError: undefined}
    }),
    on(FiltersActions.onAddOrUpdateFilterFailed, (state, action) =>  {
        return {...state, addOrUpdateCategoryFilterError: action.payload}
     }),
    on(FiltersActions.onGetFilterById, (state) => ({...state, error: undefined})),
    on(FiltersActions.onGetFilterByIdSuccess, (state, action) => {
       return {...state, filterById: action.payload}
    }),
    on(FiltersActions.onGetFilterByIdFailed, (state, action) => ({ ...state, error: action.payload})),

    on(FiltersActions.ondeleteFilterById, (state) => ({...state, error: undefined})),
    on(FiltersActions.ondeleteFilterByIdSuccess, (state, action) => {
        const index = state.filters!.findIndex(x=> x.filterId === action.payload.filterId);
        let newArray = [...state.filters!];
        if(index > -1) 
           newArray.splice(index, 1);
       return {...state, filters: newArray}
    }),
    on(FiltersActions.ondeleteFilterByIdFailed, (state, action) => ({ ...state, error: action.payload}))
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return pointofsalesReducer(state, action);
}

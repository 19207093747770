import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from '../base.page'
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { ModesoCoreModule } from '@modeso/modeso-lib-core-fe';

@Component({
    selector: 'app-page-contentManagement',
    templateUrl: './contentManagement.page.html',
    styleUrls: ['./contentManagement.page.scss']
})
export class ContentManagementPage extends BasePage implements OnInit {
    background: string = '#0076bf';
    navLinks = [
        {
            label: 'Text and Labels',
            link: 'text-and-labels',
            index: 0
        }, 
        // {
        //     label: 'FAQ',
        //     link: 'faq',
        //     index: 1
        // }
    ];
    activeLink = this.navLinks[0].link;
    constructor(
      injector: Injector) {
      super(injector);
    }

    override ngOnInit() {
      super.ngOnInit();
    }
}

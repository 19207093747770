import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { SidebarComponent } from './skeleton/sidebar/sidebar.component';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
import { HomePage } from './pages/home/home.page';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select'; 
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { UpdateLocalizationByKeyDialog } from './pages/manageContent/updatelocalizationByKeyDialog/updateLocalizationByKeyDialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminConfig, AdminModule } from '@modeso/tsf-lib-admin-fe';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListPointOfSalesComponent } from './pages/PointOfSalesManagement/list-point-of-sales/list-point-of-sale.component';
import { MatMenuModule } from '@angular/material/menu';
import { AddPointOfSalesComponent } from './pages/PointOfSalesManagement/add-point-of-sales/add-point-of-sale.component';
import { AddUpdateCategoryComponent } from './pages/CategoryManagement/add-update-category/add-update-category.component';
import { ListCategoriesComponent } from './pages/CategoryManagement/list-categories/list-categories.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ListPaymentPartnerComponent } from './pages/PaymentPartnerManagement/list-payment-partner/list-payment-partner.component';
import { AddUpdatePaymentPartnerComponent } from './pages/PaymentPartnerManagement/add-update-payment-partner/add-update-payment-partner.component';
import { PointOfSaleFeModule , PointOfSaleConfig  } from '@modeso/tsf-lib-pointofsales-fe';
import { AddUpdateLegalComponent } from './pages/LegalManagement/add-update-legal/add-update-legal.component';
import { ListLegalComponent } from './pages/LegalManagement/list-legal/list-legal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatIconModule } from '@angular/material/icon';
import { AUTH_INTERCEPTOR, AuthHandler } from './shared/interceptors/interceptors';
import { SessionOrchestrator } from './shared/orchestrator/orchestrator';
import { ModesoCoreModule } from '@modeso/modeso-lib-core-fe';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginPage } from './pages/login/login.page';
import { ImportPointOfSalesComponent } from './pages/PointOfSalesManagement/import-point-of-sales/import-point-of-sales.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle'; 
import { ConfirmationDialogComponent } from './shared/dialogs/confirm-dialog.component';
import { BasicDialogComponent } from './shared/basic-dialog/basic-dialog.component';
import { FilterComponent } from './pages/filter/filter.component';
import { AddNewFilterComponent } from './pages/filter/add-new-filter/add-new-filter.component';
import {MatRadioModule} from '@angular/material/radio';
import { ConfirmDeleteComponent } from './shared/confirm-delete/confirm-delete.component';
const adminConfig: AdminConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'admin'
};

const pointofsaleConfig: PointOfSaleConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'pointofsale'
};

@NgModule({
  declarations: [
    AppComponent, 
    ContentAreaComponent,
    ContentManagementPage,
    HeaderComponent, 
    HomePage,
    ManageContentPage,
    SidebarComponent,
    UpdateLocalizationByKeyDialog,
    ListPointOfSalesComponent,
    AddPointOfSalesComponent,
    AddUpdateCategoryComponent,
    ListCategoriesComponent,
    ListPaymentPartnerComponent,
    AddUpdatePaymentPartnerComponent,
    AddUpdateLegalComponent,
    ListLegalComponent,
    LoginPage,
    ImportPointOfSalesComponent,
    ConfirmationDialogComponent,
    BasicDialogComponent,
    FilterComponent,
    AddNewFilterComponent,
    ConfirmDeleteComponent
  ],
  imports: [
    AdminModule.forRoot(adminConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([]),
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableExporterModule,
    MatTableModule,
    MatTabsModule,
    MatRadioModule,
    ModesoCoreModule,
    NgxFileDropModule,
    NgxMaterialTimepickerModule,
    PointOfSaleFeModule.forRoot(pointofsaleConfig),
    ReactiveFormsModule,
    StoreModule.forRoot({}),
    // StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmDeleteComponent
  ]
})
export class AppModule {}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { LoginUser } from '../models/administration/login.model';
import { UserResponse } from '../models/administration/userResponse.model';
import Debug from 'debug';
import { Config } from '@modeso/modeso-lib-core-fe';

const debug = Debug('modeso:tsf-lib-admin-fe:AdminService');

export interface AdminResponse {
    user: UserResponse;
}

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    private server = '';
    private microserviceName = 'admin';
    private readonly route = '/';
    private readonly loginUrl = 'login';
    private readonly logoutUrl = 'logout';
    private readonly accesstoken = 'token';
    private readonly userRoles = 'userRoles';

    constructor(
        private http: HttpClient, 
        @Inject(AdminConfigService) private config: Config
    ) {
        this.server = config.apiGateway;
    }

    login(user: { payload: LoginUser }) {
        const url = `${this.server}${this.microserviceName}${this.route}${this.loginUrl}`;
        return this.http.post<UserResponse>(url, user.payload);
    }

    logout() {
        const url = `${this.server}${this.microserviceName}${this.route}${this.logoutUrl}`;
        return this.http.get(url);
    }

    getNewAccesToken() {
        const url = `${this.server}${this.microserviceName}${this.route}${this.accesstoken}`;
        return this.http.get(url);
    }


    getUserRoles() {
        const url = `${this.server}${this.microserviceName}${this.route}${this.userRoles}`;
        return this.http.get(url);
    }
}

import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import Debug from 'debug';
import { AdminProvider } from '@modeso/tsf-lib-admin-fe';
import { Subscription } from 'rxjs';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { LocalStorageService } from '../../shared/services/localStorage.service';
const debug = Debug('modeso:storefinder-admin:SidebarComponent');



interface INavigationItems {
  link: [string];
  text: string;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscriptions = new Array<Subscription>();
  public navigation = new Array<INavigationItems>();
  isUserLoggedIn!: boolean;

  constructor(
    private localStorageService: LocalStorageService, 
    private adminProvider: AdminProvider,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.adminProvider.getLoggedInUser$().subscribe(this.updateUserRole.bind(this)));
    this.subscriptions.push(this.adminProvider.getUserRoles$().subscribe(this.setUserRolesInStorage.bind(this)));
    this.subscriptions.push(LocalStorageService.UserRoleObserver$.subscribe(this.updateNavigation.bind(this)));
  }

  ngAfterViewChecked(): void {
    this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  setUserRolesInStorage(response: any) {
    if (response && response.roles) {
      this.localStorageService.setUserRole(response.roles);
    }
  }

  public hasRole(roles: string[], role: string) {
    switch (role) {
      case 'STOREFINDER_STORE_ADMIN':
        return roles.some(role => role === UserRoles.STOREFINDER_STORE_ADMIN);
      case 'UNDEFINED':
        return roles.some(role => role === UserRoles.UNDEFINED);
    }
    // TODO check no return statement
    return false;
  }

  updateNavigation(roles: string[]) {
    debug('updateNavigation for role;');
    debug(roles);
    const pointOfSale = 'Point of Sale' ;
    const manageContent = 'Manage Content';
    const legal = 'Legal';
    const category = 'Category'; 
    const paymentPartner = 'Payment Partner';
    const filterManagement = 'Manage Filter';

    const navigationItems: INavigationItems[] = [
      { link: ['/'], text: 'Home' },
    ];

    navigationItems.push(
      { link: ['/manageContent/text-and-labels'], text: manageContent },
      { link: ['/filters'], text: filterManagement },
      { link: ['/manage-categories'], text: category },
      { link: ['/manage-paymentpartners'], text: paymentPartner },
      { link: ['/manage-legals'], text: legal },
      { link: ['/manage-pointofsales'], text: pointOfSale },
    );

    debug(navigationItems);
    this.navigation = navigationItems;
  }

  updateUserRole(user:any) {
    debug('updateUserRole:');
    debug(user);
    if (user) {
      this.localStorageService.setUserRole(user.role);
    } else {
      debug('take from localstorage:');
      if (this.localStorageService.getUserRole() !== undefined) {
        this.updateNavigation(this.localStorageService.getUserRole());
      }
    }
  }

  getRole() {
    const roles = this.localStorageService.getUserRole();
    if (roles) {
      let roleStr = '';
      for (const role of roles) {
        switch (role) {
          case UserRoles.STOREFINDER_STORE_ADMIN:
            roleStr = roleStr + 'Store Admin, ';
            break;
          default:
            roleStr = roleStr + 'UNDEFINED, ';
            break;
        }
      }
      return roleStr.substring(0, roleStr.length - 2);
    }
  }
}

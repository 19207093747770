import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUpdateCategoryComponent } from './pages/CategoryManagement/add-update-category/add-update-category.component';
import { ListCategoriesComponent } from './pages/CategoryManagement/list-categories/list-categories.component';
import { ContentManagementPage } from './pages/contentManagement/contentManagement.page';
import { HomePage } from './pages/home/home.page';
import { AddUpdateLegalComponent } from './pages/LegalManagement/add-update-legal/add-update-legal.component';
import { ListLegalComponent } from './pages/LegalManagement/list-legal/list-legal.component';
import { LoginPage } from './pages/login/login.page';
import { ManageContentPage } from './pages/manageContent/manageContent.page';
import { AddPointOfSalesComponent } from './pages/PointOfSalesManagement/add-point-of-sales/add-point-of-sale.component';
import { ListPointOfSalesComponent } from './pages/PointOfSalesManagement/list-point-of-sales/list-point-of-sale.component';
import { AddUpdatePaymentPartnerComponent } from './pages/PaymentPartnerManagement/add-update-payment-partner/add-update-payment-partner.component';
import { ListPaymentPartnerComponent } from './pages/PaymentPartnerManagement/list-payment-partner/list-payment-partner.component';
import { ImportPointOfSalesComponent } from './pages/PointOfSalesManagement/import-point-of-sales/import-point-of-sales.component';
import { SamlAssertPage } from './pages/samlAssert/samlAssert.page';
import { AuthGuard, NoAuthGuard, PointOfSalesGuard } from './shared/guards/auth.guard';
import { FilterComponent } from './pages/filter/filter.component';
import { AddNewFilterComponent } from './pages/filter/add-new-filter/add-new-filter.component';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'saml/assert',
    component: SamlAssertPage,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'manageContent',
    component: ContentManagementPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'text-and-labels',
        component: ManageContentPage
      },
    ]
  },
  {
    path: 'manage-pointofsales',
    component: ListPointOfSalesComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'add-pointofsale',
    component: AddPointOfSalesComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'manage-categories',
    component: ListCategoriesComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'add-category',
    component: AddUpdateCategoryComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'manage-paymentpartners',
    component: ListPaymentPartnerComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'add-paymentpartners',
    component: AddUpdatePaymentPartnerComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'manage-legals',
    component: ListLegalComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'add-legal',
    component: AddUpdateLegalComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'import-pointofsales',
    component: ImportPointOfSalesComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ]

  },
  {
    path: 'filters',
    component: FilterComponent,
    canActivate: [AuthGuard, PointOfSalesGuard ],

  },
  { path: 'add-filter/:id', component: AddNewFilterComponent },
  { path: 'add-filter', component: AddNewFilterComponent,},
  {
    path: '**',
    component: HomePage,
    canActivate: [ AuthGuard]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, Injector, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ILegal, IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';
import { BasePage } from '../../base.page';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './../../../shared/dialogs/confirm-dialog.component';



@Component({
  selector: 'app-list-legal',
  templateUrl: './list-legal.component.html',
  styleUrls: ['./list-legal.component.scss']
})
export class ListLegalComponent extends BasePage implements OnInit {

  legals!:ILegal[];
  dataSource!: MatTableDataSource<ILegal>;
  displayedColumns: string[] = ['name', 'paymentPartner', 'externalLegalId' ,'buttons'];
  paymentPartners!:Array<IPaymentPartner> ;
  dialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private injector: Injector, private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private dialog: MatDialog
  ) {
    super(injector);
  }

  override ngOnInit() {
    this.subscriptions.push(
      this.pointOfSalesProvider.getLegals$().subscribe((legals) => {

        if(legals){
          this.legals = legals;
          this.subscriptions.push(
            this.pointOfSalesProvider.getPaymentPartners$().pipe(take(2)).subscribe((paymentPartners) => { 
              if(paymentPartners && paymentPartners.length){
                this.paymentPartners = paymentPartners  
              }              
              this.legals = legals.map((legal) => {
                const paymentPartner = this.paymentPartners?.filter( (paymentPartner) => paymentPartner.paymentPartnerId === legal.paymentPartner)[0];
                return {
                  ...legal,
                  paymentPartner: paymentPartner?.name,
                };

              });
            })
          )
        
          this.dataSource = new MatTableDataSource<ILegal>(this.legals);
        }
      })
    );
  }

  addLegal(){
    this.router.navigate([`/add-legal`]);
  }

  editLegal(legalId:string){
    this.router.navigate([`/add-legal`, { legalId } ]);
  }

  deleteLegal(legalId:string){
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent);
    this.dialogRef.componentInstance.confirmButtonText = "Delete";
    this.dialogRef.componentInstance.confirmTitle = "Delete Legal";
    const legalName = this.legals.filter( legal => legal.legalId === legalId )[0].name;
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to delete ${legalName}?`;

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe( result => {
        if(result){
          this.pointOfSalesProvider.deleteLegal$(legalId);
        }
      })
    );
  }

}

import { Action, createAction, props } from '@ngrx/store';
import { ICategory, ICategoryFilter, ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales';

// add category
export const onAddOrUpdateCategory = createAction(
  '[TSF Add/Update category] add/update category',
  props<{ payload: ICategory }>()
);

export const onAddOrUpdateCategorySuccess = createAction(
  '[TSF Add/Update category successfully] add/update category successfully',
  props<{ payload: ICategory }>()
);

export const onAddOrUpdateCategoryFailed = createAction(
  '[TSF Add/Update category failed] add/update category failed',
  props<{ payload: any }>()
);

// list categories
export const onGetCategories = createAction(
  '[TSF get categories] get categories'
);

export const onGetCategoriesSuccess = createAction(
  '[TSF get categories successfully] get categories successfully',
  props<{ payload: ICategory[] }>()
);

export const onGetCategoriesFailed = createAction(
  '[TSF get categories failed] get categories failed',
  props<{ payload: any }>()
);

export const onDeleteCategory = createAction(
  '[TSF Delete category] delete category',
  props<{ payload: string }>()
);

export const onDeleteCategorySuccess = createAction(
  '[TSF Delete category successfully] delete Category successfully',
  props<{ payload: string }>()
);

export const onDeleteCategoryFailed = createAction(
  '[TSF Delete category failed] delete Category failed',
  props<{ payload: any }>()
);

export const onGetCategoryFilter = createAction(
  '[TSF Get category filter] Get category filters',
);

export const onGetCategoryFilterSuccess = createAction(
  '[TSF Get category filter] Get category filters successfully',
  props<{ payload: ICategoryFilter[] }>()
);

export const onGetCategoryFilterFailed = createAction(
  '[TSF Get category filter] Get category filters failed',
  props<{ payload: any }>()
);

export const onGetTranslatedCategories = createAction(
  '[TSF Get translated category] Get translated categories filters',
);

export const onGetTranslatedCategoriesSuccess = createAction(
  '[TSF Get translated category] Get translated categories successfully',
  props<{ payload: ITranslatedCategory[] }>()
);

export const onGetTranslatedCategoriesFailed = createAction(
  '[TSF Get category filter] Get category filters failed',
  props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

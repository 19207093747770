<div class="legal-container">
    <form class="form" [formGroup]="legalForm" (ngSubmit)="submit()" *ngIf="legalForm">

      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>External Legal Id</mat-label>
          <input formControlName="externalLegalId" matInput />
          <mat-error>External Legal Id is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label> Payment Partner </mat-label>
            <mat-select formControlName="paymentPartnerId" [disabled]="paymentPartners.length === 0">
                <mat-option *ngFor="let paymentPartner of paymentPartners" [value]="paymentPartner.paymentPartnerId">
                {{paymentPartner.name}}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="paymentPartners.length === 0" class="no-data">You must add a payment partner first!</mat-hint>
        </mat-form-field>
      </div>
  
        <div class="upload-container">
          <ngx-file-drop style="width: 120px;" dropZoneLabel="Drop 8 here"
          (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div>
              <button
              mat-raised-button
              type="button"
              [disabled]="fileReader"
              (click)="openFileSelector()"
              [class.disabled]="fileReader"
              class="upload-btn">
              Add Image
            </button>
            </div>
          </ng-template>
        </ngx-file-drop>
        <div class="add-image-div-img">
          <div [ngClass]="{'upload-rectangle': !fileReader}"></div>
          <div class="row">
            <div *ngIf="fileReader" class="col" style="margin-left: -7px;">
              <img [src]="fileReader" class="upload-image" />
              <a (click)="fileReader=undefined" matSuffix>
                <mat-icon class="cancel-mat-icon">cancel</mat-icon>
              </a>
            </div>
          </div>
      </div>
    </div>
        <div style="margin-bottom: 10px;">
          <span class="error" *ngIf="error">Error while uploading file, incompatible format or file size exceeds 15MB.</span>
        </div>
      <div style="margin-top: 10px;">
        <button type="submit" color="success" mat-button [disabled]="legalForm.invalid || !fileReader">
          Save
        </button>
        <button type="submit" (click)="backToListLegalsUrl()" mat-raised-button style="margin-left: 10px">Cancel</button>
      </div>
    </form>
</div>
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ICategoryFilter, ITextLanguage, FilterValue } from '@modeso/types__tsf-ms-pointofsales';
import { ConfirmDeleteComponent } from '../../shared/confirm-delete/confirm-delete.component';
import { BasePage } from '../base.page';


export interface ICategoryFilterVM {
  key: string;
  title: ITextLanguage;
  valueInEnglish?: string;
  valueInGerman?: string;
  valueInFrench?: string;
  valueInItaly?: string;
  valueType: string;
  filterId: string;
  isCustom: boolean;
}
@Component({
  selector: 'app-filter-component',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends BasePage implements OnInit  {
  public filters!: ICategoryFilter[];
  public filtersVm!: ICategoryFilterVM[];
  dialogRef!: MatDialogRef<ConfirmDeleteComponent>;
  constructor(private posProvider: PointOfSalesProvider, private injector: Injector, private dialog: MatDialog) {
    super(injector);
    
  }

  override ngOnInit() {
    this.subscriptions.push(this.posProvider.dispatchGetAllFilters().subscribe(filters => {
      if(!filters) return;
      const addedFilters = filters as ICategoryFilter[];
      this.filtersVm =  addedFilters.map((filter) => {
          if(filter.valueType === 'value') {
            let valueInEnglish = (filter.values as FilterValue[]).map((x) => x['en-us']);
            let valueInFrench = (filter.values as FilterValue[]).map((x) => x['fr-ch']);
            let valueInGerman = (filter.values as FilterValue[]).map((x) => x['de-ch']);
            let valueInItaly = (filter.values as FilterValue[]).map((x) => x['it-ch']);
            return {
              title: filter.title,
              key: filter.key,
              valueType: filter.valueType,
              valueInEnglish: valueInEnglish.join('\n'),
              valueInFrench: valueInFrench.join('\n'),
              valueInGerman: valueInGerman.join('\n'),
              valueInItaly: valueInItaly.join('\n'),
              filterId: filter.filterId, 
              isCustom: filter.isCustom   
            } as ICategoryFilterVM;
          } else {
            return {
              title: filter.title,
              valueType: filter.valueType,
              filterId: filter.filterId,
              key: filter.key,
              isCustom: filter.isCustom
            } as ICategoryFilterVM;
          }
      });
    
    }));
 
  }

  deleteFilter(filterId: string) {
    this.dialogRef =  this.dialog.open(ConfirmDeleteComponent, {
      data: {filterId}
    })
  }

}
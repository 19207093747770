import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  public cancelButtonText: string = 'Cancel'
  public confirmTitle: string = '';
  public confirmButtonText: string = '';
  public confirmMessage: string = '';
}
import { Action, createAction, props } from '@ngrx/store';
import { IPointOfSales} from '@modeso/types__tsf-ms-pointofsales'
import { IPointOfSaleDto } from '@modeso/types__tsf-ms-pointofsales';


export const onAddOrUpdatePointOfSale = createAction(
  '[TSF Add/Update pointOfSale] add/update pointOfSales',
  props<{ payload: IPointOfSales }>()
);

export const onAddOrUpdatePointOfSaleSuccess = createAction(
  '[TSF Add/Update pointOfSale successfully] add/update pointOfSale successfully',
  props<{ payload: IPointOfSales }>()
);

export const onAddOrUpdatePointOfSaleFailed = createAction(
  '[TSF Add/Update pointOfSale failed] add/update pointOfSale failed',
  props<{ payload: any }>()
);

export const onDeletePointOfSale = createAction(
  '[TSF Delete pointOfSale] delete pointOfSales',
  props<{ payload: string }>()
);

export const onDeletePointOfSaleSuccess = createAction(
  '[TSF Delete pointOfSale successfully] delete pointOfSale successfully',
  props<{ payload: string }>()
);

export const onDeletePointOfSaleFailed = createAction(
  '[TSF Delete pointOfSale failed] delete pointOfSale failed',
  props<{ payload: any }>()
);

// list pointOfSales
export const onGetPointOfSales = createAction(
  '[TSF get pointOfSales] get pointOfSales'
);

export const onGetPointOfSalesSuccess = createAction(
  '[TSF get pointOfSales successfully] get pointOfSales successfully',
  props<{ payload: IPointOfSales[] }>()
);

export const onGetPointOfSalesFailed = createAction(
  '[TSF get pointOfSales failed] get pointOfSales failed',
  props<{ payload: any }>()
);

export const onGetPointOfSalesByCategory = createAction(
  '[TSF get pointOfSales] get pointOfSales by category',
  props<{ payload: string }>()
);

export const onGetPointOfSalesByCategorySuccess = createAction(
  '[TSF get pointOfSales successfully] get pointOfSales successfully by category',
  props<{ payload: IPointOfSaleDto[] }>()
);

export const onGetPointOfSalesByCategoryFailed = createAction(
  '[TSF get pointOfSales failed] get pointOfSales failed with by category',
  props<{ payload: any }>()
);

export const onImportPointOfSales = createAction(
  '[TSF import pos] import pos',
  props<{ payload: any }>()
);

export const onImportPointOfSalesSuccess = createAction(
  '[TSF import pos successfully] import pos successfully',
  props<{ payload:any }>()
);

export const onImportPointOfSalesFailed = createAction(
  '[TSF import pos failed] import pos failed',
  props<{ payload: any }>()
);

export const onSetFilterPosByCategories = createAction(
  '[TSF filter by categories] point of sale filtered by categories',
  props<{ payload: string[] }>()
);

export const onApplyFilterPosByCategories = createAction(
  '[TSF set filter by categories] point of sale set filtered by categories'
);

export const onSetFilterPos = createAction(
  '[TSF filter pos] point of sale filteration',
  props<{ payload: any }>()
);

export const onApplyFilterPos = createAction(
  '[TSF apply filter pos] apply point of sale filteration'
);

export const onShowOrHideFilterPos = createAction(
  '[TSF show or hide filter pos] point of sale show or hide',
  props<{ payload: boolean }>())
export const onSetUserLocationEnabled = createAction(
  '[TSF set user location] set user location enabled', 
  props<{payload: boolean}>()
);
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

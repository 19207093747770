import { Component, Injector, OnInit } from '@angular/core';
import { IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';
import { MatTableDataSource } from '@angular/material/table';
import { BasePage } from '../../base.page';
import { Router } from '@angular/router';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirm-dialog.component';


@Component({
  selector: 'app-list-payment-partner',
  templateUrl: './list-payment-partner.component.html',
  styleUrls: ['./list-payment-partner.component.scss']
})
export class ListPaymentPartnerComponent extends BasePage implements OnInit {

  paymentPartners!:IPaymentPartner[];
  dataSource!: MatTableDataSource<IPaymentPartner>;
  displayedColumns: string[] = ['name', 'checkInUrl', 'username' ,'buttons'];
  dialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private injector: Injector, private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private dialog: MatDialog
  ) {
    super(injector);
  }

  override ngOnInit() {
    this.subscriptions.push(
      this.pointOfSalesProvider.getPaymentPartners$().subscribe((paymentPartners) => {
        if(paymentPartners){
          this.paymentPartners = paymentPartners;
          this.dataSource = new MatTableDataSource<IPaymentPartner>(this.paymentPartners);
        }
      })
    );
  }

  addPaymentPartner(){
    this.router.navigate([`/add-paymentpartners`]);
  }

  editPaymentPartner(paymentPartnerId:string){
    this.router.navigate([`/add-paymentpartners`, { paymentPartnerId} ]);
  }

  deletePaymentPartner(paymentPartnerId:string){
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent);
    this.dialogRef.componentInstance.confirmButtonText = "Delete";
    this.dialogRef.componentInstance.confirmTitle = "Delete Payment Partner";
    const paymentPartnerName = this.paymentPartners.filter( paymentPartner => paymentPartner.paymentPartnerId === paymentPartnerId )[0].name;
    this.dialogRef.componentInstance.confirmMessage = `Are you sure you want to delete ${paymentPartnerName}?`;

    this.subscriptions.push(
      this.dialogRef.afterClosed().subscribe( result => {
        if(result){
          this.pointOfSalesProvider.deletePaymentPartner$(paymentPartnerId);
        }
      })
    );
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ICategory, IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';
import { take } from 'rxjs';
import { BasePage } from '../../base.page';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-import-point-of-sales',
  templateUrl: './import-point-of-sales.component.html',
  styleUrls: ['./import-point-of-sales.component.scss']
})
export class ImportPointOfSalesComponent extends BasePage implements OnInit {

  importForm!: FormGroup;
  categories!:Array<ICategory>;
  paymentPartners!:Array<IPaymentPartner>;
  uploadedFile!:any;
  error:boolean = false;
  pointOfSalesErrors!:Array<any>;
  dataToBeExported!:Array<any>;
  columnsNames!:Array<string>;
  isImportFinished=false;
  noErrors=false;
  fileName!:string;
  isInternalError:boolean = false;
  invalidFile:boolean = false;
  isTimeoutError:boolean = false;
  constructor(private injector:Injector,private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private formBuilder: FormBuilder,) {
    super(injector);

  }

  override ngOnInit(){

    this.pointOfSalesProvider.dispatchGetAllCategories();
    this.pointOfSalesProvider.getCategories$().pipe(take(2)).subscribe((categories) => {
      this.categories = categories && categories.length > 0 ? categories : [];
     });

    this.pointOfSalesProvider.getPaymentPartners$().pipe(take(2)).subscribe((paymentPartners) => {
    this.paymentPartners = paymentPartners && paymentPartners.length > 0 ? paymentPartners : [];
    });

    this.initFrom();
  }

  initFrom() {
    this.importForm = this.formBuilder.group({
      paymentPartnerId: this.formBuilder.control(null, Validators.required),
      categoryId: this.formBuilder.control(null, [Validators.required]),
    })
  }


  get paymentPartnerId() {
    return this.importForm.get('paymentPartnerId');
  }
  get categoryId() {
    return this.importForm.get('categoryId');
  }

  public dropped(files: NgxFileDropEntry[]): void {
    this.error = false;
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file( async (file: File) => {
        fileEntry.file((file: File) => {
          this.fileName = droppedFile.fileEntry.name;
          if (!this.isFileAllowed(droppedFile.fileEntry.name)) {
            this.error = true;
          }else{
            this.uploadedFile=droppedFile;
          }
        });
      });
    }
  }

  importPos() {
    let pointofsale = {
      category:this.categoryId?.value,
      paymentPartner:this.paymentPartnerId?.value,
      file:this.uploadedFile
    };
    this.isImportFinished = true;

    this.pointOfSalesProvider.importPointOfSales$(pointofsale).pipe(take(2))
    .subscribe((res:any) => {
      this.clearData();
      if (res) {
        this.updateBooleans();
        if(res.pointofsalesErrors?.length===0 && res.status.toString() === '200'){
          this.noErrors = true;
        }else{
          this.pointOfSalesProvider.getPointOfSaleErrors().subscribe((res) => {
              if (res) {
                this.updateBooleans();
                if(res.status.toString() === '400' || res.status.toString() === '200'){
                  if(res.pointofsalesErrors.length > 0){
                    this.handleImportFailure(res.pointofsalesErrors,res.columnsNames)
                  } else {
                    this.invalidFile = true;
                  }
                }
                if (res.status.toString() === '500'){
                  this.isInternalError= true;
                }
                if (res.status.toString() === '502' || res.status.toString() === '504'){
                  this.isTimeoutError= true;
                }
              }
          })
        }
      }
    })      
      
  }

  exportToExcel($event:any) {
    const fileName = `${this.fileName.substring(0,this.fileName.lastIndexOf("."))}_errors.xlsx`;
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataToBeExported,{​header​:​[​​...this.columnsNames,'message']​});
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'test');
		XLSX.writeFile(wb, fileName);
  }

  private isFileAllowed(fileName:string) {
    let isFileAllowed = true;
    const allowedFiles = ['.xlsx', '.xls'];
    const regex = /(?:\.([^.]+))?$/;
      const extension = regex.exec(fileName);
      if (undefined !== extension && null !== extension) {
        if (!allowedFiles.includes(extension[0])) {
          return isFileAllowed = false;
        }
      }
    return isFileAllowed;
  }


  private handleImportFailure(pointofsalesErrors:any,columnsNames:any){
    this.isImportFinished = false;
    this.pointOfSalesErrors = pointofsalesErrors;
    this.columnsNames = columnsNames;
    this.noErrors = false;
    this.dataToBeExported = this.pointOfSalesErrors.slice().sort((a,b) => a.rowIndex - b.rowIndex).map((pos)=> {
      return {...pos.data,message:pos.message};
    })
  }

  private clearData(){
    this.dataToBeExported = [];
    this.uploadedFile = undefined;
    this.pointOfSalesErrors= [];
  }

  private updateBooleans(){
    this.noErrors=false;
    this.isInternalError=false;
    this.isImportFinished = false;
    this.invalidFile=false;
    this.isTimeoutError = false;
  }
}

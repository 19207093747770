import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { BasePage } from '../../base.page';
import { IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';
import { ErrorStateMatcher } from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.touched);
    const invalidParent = !!(control && control.parent && control.parent?.hasError('doesntMatch') && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-add-update-payment-partner',
  templateUrl: './add-update-payment-partner.component.html',
  styleUrls: ['./add-update-payment-partner.component.scss']
})
export class AddUpdatePaymentPartnerComponent extends BasePage implements OnInit {

  paymentPartnerForm!: FormGroup;
  paymentPartnerId!: string;
  paymentPartnerInit: any = { name: '', checkInUrl : '' , username:'' , password:'' , confirmPassword:''};
  checkInPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\\=]*)/;
  matcher = new MyErrorStateMatcher();

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder, 
    private router: Router,
    private pointOfSalesProvider: PointOfSalesProvider,
    private activatedRouter: ActivatedRoute
  ){
    super(injector);
    const paymentPartnerId = this.activatedRouter.snapshot.params['paymentPartnerId'];
    if (paymentPartnerId) {
      this.paymentPartnerId = paymentPartnerId;
      this.pointOfSalesProvider.getPaymentPartners$().pipe(take(2)).subscribe((paymentPartners) => {
        if(paymentPartners && paymentPartners.length > 0){
          const paymentPartner = paymentPartners.find((paymentpartner) => paymentpartner.paymentPartnerId === paymentPartnerId);
          this.paymentPartnerInit = paymentPartner;
          this.initFrom();
        }
      });
    }else{
      this.initFrom();
    }
  }

  initFrom() {
    const paymentPartner = this.paymentPartnerInit;
    this.paymentPartnerForm = this.formBuilder.group({
      name: this.formBuilder.control(paymentPartner.name, Validators.required),   
      checkInUrl: this.formBuilder.control(paymentPartner.checkInUrl, [Validators.required, Validators.pattern(this.checkInPattern)]),
      username: this.formBuilder.control(paymentPartner.username, Validators.required),
      password: this.paymentPartnerId? this.formBuilder.control(null): this.formBuilder.control(paymentPartner.password,Validators.required),
      confirmPassword: this.paymentPartnerId? this.formBuilder.control(null): this.formBuilder.control(paymentPartner.password),
    },{
      validators: this.checkPassword
    });
  }


  checkPassword: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let password = group.get('password')?.value;
    let confirmPassword = group.get('confirmPassword')?.value
    return password === confirmPassword ? null : { doesntMatch: true }
  }

  get name() {
    return this.paymentPartnerForm.get('name');
  }
  get checkInUrl() {
    return this.paymentPartnerForm.get('checkInUrl');
  }

  get username() {
    return this.paymentPartnerForm.get('username');
  }
  get password() {
    return this.paymentPartnerForm.get('password');
  }

  get confirmPassword() {
    return this.paymentPartnerForm.get('confirmPassword');
  }

  submit() {
    let paymentPartner: IPaymentPartner = {
      name: this.name?.value,
      checkInUrl: this.checkInUrl?.value,
      username: this.username?.value,
    };
    if (this.paymentPartnerId) {
      paymentPartner.paymentPartnerId = this.paymentPartnerId;
    }

    if(this.password?.value){
      paymentPartner.password = this.password?.value
    }

    this.pointOfSalesProvider.addOrUpdatePaymentPartner$(paymentPartner);
    this.backToListPaymentPartnersUrl();
  }

  backToListPaymentPartnersUrl(){
    this.router.navigate(['/manage-paymentpartners']);
  }
}

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { IPointOfSaleVM }  from '../../../models/pointofsale.model';
import { PointOfSalesProvider }  from '../../../providers/pointofsales.provider';
@Component({
  selector: 'tsf-search-item-result',
  templateUrl: './search-item-result.component.html',
  styleUrls: ['./search-item-result.component.scss']
})
export class SearchItemResultComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() 
  pointOfSaleClick = new EventEmitter();
  @Output() 
  routeToPosClick = new EventEmitter();
  @Input() 
  pointOfSale!: IPointOfSaleVM;
  locationEnabled!: boolean;
  isMobile: boolean = true;
  constructor(private responsive: BreakpointObserver, private pointOfSaleProvider: PointOfSalesProvider){
    this.responsive.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(takeUntil(this.destroyed$)).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
  ngOnInit(): void {
    this.pointOfSaleProvider.getUserLocationState$()
    .pipe(takeUntil(this.destroyed$)).subscribe((locationEnabled: boolean) => this.locationEnabled = locationEnabled);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onPosClicked(){
    this.pointOfSaleClick.emit();
  }
}

import { Action, createAction, props } from '@ngrx/store';
import { IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';

// add payment partner
export const onAddOrUpdatePaymentPartner = createAction(
  '[TSF Add/Update payment partner] add/update payment partner',
  props<{ payload: IPaymentPartner }>()
);

export const onAddOrUpdatePaymentPartnerSuccess = createAction(
  '[TSF Add/Update payment partner successfully] add/update payment partner successfully',
  props<{ payload: IPaymentPartner }>()
);

export const onAddOrUpdatePaymentPartnerFailed = createAction(
  '[TSF Add/Update payment partner failed] add/update payment partner failed',
  props<{ payload: any }>()
);

// list payment partner
export const onGetPaymentPartners = createAction(
  '[TSF get payment partners] get payment partners'
);

export const onGetPaymentPartnersSuccess = createAction(
  '[TSF get payment partners successfully] get payment partners successfully',
  props<{ payload: IPaymentPartner[] }>()
);

export const onGetPaymentPartnersFailed = createAction(
  '[TSF get payment partners failed] get payment partners failed',
  props<{ payload: any }>()
);

export const onDeletePaymentPartner = createAction(
  '[TSF Delete paymentPartner] delete payment partner',
  props<{ payload: string }>()
);

export const onDeletePaymentPartnerSuccess = createAction(
  '[TSF Delete paymentPartner successfully] delete payment partner successfully',
  props<{ payload: string }>()
);

export const onDeletePaymentPartnerFailed = createAction(
  '[TSF Delete paymentPartner failed] delete payment partner failed',
  props<{ payload: any }>()
);


export interface ActionWithPayload<T> extends Action {
  payload: T;
}

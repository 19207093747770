import { Component, OnDestroy, OnInit } from '@angular/core';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ICategoryFilter, ITranslatedCategory, ITranslatedCategoryFilter } from '@modeso/types__tsf-ms-pointofsales';
import { of, ReplaySubject, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'tsf-pos-all-filters',
  templateUrl: './pos-all-filters.component.html',
  styleUrls: ['./pos-all-filters.component.scss']
})
export class PosAllFiltersComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  filters: ITranslatedCategoryFilter[] =[];
  currentlySelectedFilter: any = {};

  constructor(private pointOfSaleProvider: PointOfSalesProvider) { }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.pointOfSaleProvider.getCurrentlyAppliedFilter$().pipe(take(1)).subscribe((filter) => {
      if(!filter) return;
      this.currentlySelectedFilter = {...filter};
    });
    this.pointOfSaleProvider.getSelectedCategoriesIds$().pipe(takeUntil(this.destroyed$),
    switchMap(categoriesIds => {
      if(categoriesIds) {
       return this.pointOfSaleProvider.getCurrentSelectedCategories$(categoriesIds);
      }
      return of([]);
    })).subscribe((categories) => {
      if(!categories) return;
      this.filters = this.mapFilterToKey(categories);
    });
}

  public trackByFilterId(index: number, item: ITranslatedCategoryFilter){
    return item.filterId; 
  }

  public radioChange(filter: ICategoryFilter, value: any){
    this.currentlySelectedFilter[filter.filterId] = value;
    this.pointOfSaleProvider.dispatchSetFilterPos({key: filter.filterId, value});
  }

  private mapFilterToKey(categories: ITranslatedCategory[]): ITranslatedCategoryFilter[] {
    const newFilters: ITranslatedCategoryFilter[]  = [];
    for (const category of categories) {
      for(const filter of category.filters) {
        if(!newFilters.some(newFilter=>newFilter.filterId===filter.filterId)){
          newFilters.push(filter);
        }
      }
    }
    return newFilters;
  }

  checkBoxChange(filterId: string, filterValueId: string, $event: any) {
    if($event.checked) {
      if(!this.currentlySelectedFilter[filterId]) {
        this.currentlySelectedFilter[filterId] = [];
      }
      this.currentlySelectedFilter[filterId]= [... this.currentlySelectedFilter[filterId], filterValueId];
    } else {
      const filterIndex = this.currentlySelectedFilter[filterId].indexOf(filterValueId);
      const updatedArray = [...this.currentlySelectedFilter[filterId]];
      updatedArray.splice(filterIndex, 1);
      this.currentlySelectedFilter[filterId] = [...updatedArray]
    }
    this.pointOfSaleProvider.dispatchSetFilterPos({key: filterId,  value: this.currentlySelectedFilter[filterId]});
  }
}

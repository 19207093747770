<div class="import-pointofsale-container">
    <form class="form"  [formGroup]="importForm" *ngIf="importForm">
      <div class="row">
        <mat-form-field appearance="fill" style="width:300px">
            <mat-label>Category</mat-label>
            <mat-select formControlName="categoryId" name="categoryId" [disabled]="categories.length === 0" >
                <mat-option *ngFor="let category of categories" [value]="category.categoryId">
                {{category.name}}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="categories.length === 0" class="no-data">You must add a category first!</mat-hint>
        </mat-form-field>
  
        <mat-form-field appearance="fill"  style="width:300px">
            <mat-label> Payment Partner </mat-label>
            <mat-select formControlName="paymentPartnerId" [disabled]="paymentPartners.length === 0" >
                <mat-option *ngFor="let paymentPartner of paymentPartners" [value]="paymentPartner.paymentPartnerId">
                {{paymentPartner.name}}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="paymentPartners.length === 0" class="no-data">You must add a payment partner first!</mat-hint>
        </mat-form-field>

        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div style="width: 180px">
                <button class="browse-button" type="button" (click)="openFileSelector()"  class="browse-btn">
                    Browse
                </button>
                <span *ngIf="error" class="no-data">Only XLSX files are allowed</span>
            </div>
            </ng-template>
        </ngx-file-drop>
        <span *ngIf="uploadedFile?.fileEntry.name" class="file-name-span">{{uploadedFile.fileEntry.name}}</span>

        <button type="submit" class="mat-success"  (click)="importPos()" [disabled]="importForm.invalid || !uploadedFile " mat-button>Import</button>

       </div>

    </form>

    <div *ngIf="pointOfSalesErrors && pointOfSalesErrors.length>0"  >
        <div class="invalid-texts">Invalid rows found, please download the Excel file to see the error messages.</div>
        <button type="button"   (click)="exportToExcel($event)"  mat-button>Download Excel with errors</button>
    </div>


    <div *ngIf="invalidFile"  >
        <div class="invalid-texts">Uploaded file is empty!</div>
    </div>


    <div *ngIf="noErrors">
        <span  class="success-text">Excel file imported successfully!</span>
    </div>
    <div *ngIf="isInternalError">
        <span  class="invalid-texts">Internal server error, please contact an administrator!</span>
    </div>
    <div *ngIf="isTimeoutError">
        <span  class="invalid-texts">There was an internal server error while processing the file. If a large file has been uploaded, the request might have timed out</span>
    </div>


    <div *ngIf="isImportFinished" class="mat-spinner">
       <mat-spinner style="margin:auto;"></mat-spinner>
    </div>
  </div>
  

  
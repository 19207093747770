import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategory, ILegal, IPaymentPartner } from '@modeso/types__tsf-ms-pointofsales';
import { BasePage } from '../../base.page';
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { take } from 'rxjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { v4 as uuidv4 } from "uuid";
import { IUploadURL } from '../../../shared/model/uploadUrl.model';
import { UploadService } from '../../../shared/services/upload.service';
import { FileUploadUtil } from '../../../shared/util/fileutil';


@Component({
  selector: 'app-add-update-legal',
  templateUrl: './add-update-legal.component.html',
  styleUrls: ['./add-update-legal.component.scss']
})
export class AddUpdateLegalComponent extends BasePage implements OnInit {

  
  legalForm!: FormGroup;
  legalId!: string;
  legalInit: any = { name: '', category : '' ,  paymentPartner:'' , externalLegalId:''};
  paymentPartners!:Array<IPaymentPartner>;
  uploadedIcon: any;
  error = false;
  totalFilesSize = 0;
  fileReader!: string | ArrayBuffer | null;
  fileUtil:any;

  constructor(private injector: Injector,private formBuilder: FormBuilder, private router: Router,
              private pointOfSalesProvider: PointOfSalesProvider,
              private activatedRouter: ActivatedRoute,
              private uploadService: UploadService) {
                super(injector);
                this.fileUtil = new FileUploadUtil();

                this.pointOfSalesProvider.getPaymentPartners$().pipe(take(2)).subscribe((paymentPartners) => {
                  this.paymentPartners = paymentPartners && paymentPartners.length > 0 ? paymentPartners : [];
                 });

                const legalId = this.activatedRouter.snapshot.params['legalId'];
                if (legalId) {
                  this.legalId = legalId;
                   this.pointOfSalesProvider.getLegals$().pipe(take(2)).subscribe((legals) => {
                 
                     if(legals && legals.length > 0){
                      const legal = legals.find((legal) => legal.legalId === legalId);
                      this.legalInit = legal;
                      this.initFrom();
                     }
                    });

                }else{
                  this.initFrom();

                }
               
              }

  initFrom() {
    const legal = this.legalInit;
    this.fileReader = this.legalInit.icon;
    this.legalForm = this.formBuilder.group({
      name: this.formBuilder.control(legal.name, [Validators.required]),      
      paymentPartnerId: this.formBuilder.control(legal.paymentPartner, [Validators.required]),
      externalLegalId: this.formBuilder.control(legal.externalLegalId, [Validators.required])
      });
  }


  get name() {
    return this.legalForm.get('name');
  }


  get paymentPartnerId() {
    return this.legalForm.get('paymentPartnerId');
  }


  get externalLegalId() {
    return this.legalForm.get('externalLegalId');
  }


  submit() {
    if(this.uploadedIcon){
      this.uploadService.getUploadUrl(this.uploadedIcon.fileName)
      .subscribe((urlObj: IUploadURL) => {
        this.uploadedIcon.url = urlObj.fetchUrl
        this.uploadService.uploadFile(urlObj.uploadUrl, this.fileUtil.renameFile(this.uploadedIcon.file, this.uploadedIcon.fileName), urlObj.contentType).subscribe((response) => {});
        this.saveLegal();
      });
    }else{
      this.saveLegal();
    }

  }

  saveLegal(){
    let legal: ILegal = {
      name: this.name?.value,
      paymentPartner: this.paymentPartnerId?.value,
      externalLegalId:this.externalLegalId?.value,
      icon:this.uploadedIcon? this.uploadedIcon.url: this.fileReader
    };

    if (this.legalId) {
      legal.legalId = this.legalId;
      
    }
    this.pointOfSalesProvider.addOrUpdateLegal$(legal);
    this.backToListLegalsUrl();
  }

  backToListLegalsUrl(){
    this.router.navigate(['/manage-legals']);
  }


  public dropped(files: NgxFileDropEntry[]): void {

    this.error = false;
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file( async (file: File) => {
        if (!this.fileUtil.isFileSizeAllowed(file.size) || ! await this.fileUtil.validateFile(file)) {
          this.error = true;
          return;
        }
        const fileUuid = uuidv4();
        const fileName = fileUuid + '.' + droppedFile.relativePath.split('.').pop();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.uploadedIcon = {
            file,
            fileName,
          };
          this.fileReader = reader.result;
          
        };
      });
    }
  }

}

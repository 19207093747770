import { fromBuffer, FileTypeResult } from 'file-type/core';

export class FileUploadUtil {
     
    renameFile(originalFile: File, newName: string): File {
        return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
    }
    
    async validateFile(file:File) {
        const realFileType = await this.getTrueFileType(file);
        if (!realFileType) {
          return false;
        }
        if (realFileType.mime === 'image/jpeg' ||
          realFileType.mime === 'image/png') {
          return true;
        }
        return false;
    }
    
    private async getTrueFileType(file:any): Promise<FileTypeResult | undefined> {
      return file.arrayBuffer().then((buffer:any) => fromBuffer(buffer));
    }
    
    isFileSizeAllowed(size:number) {
      if (size < 15000000) {
        return true;
      }
      return false;
    }
}

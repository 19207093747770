<div class="add-update-category-container">
  <form class="form" *ngIf="categoryForm" [formGroup]="categoryForm">
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput />
          <mat-error>Name is required</mat-error>
        </mat-form-field>
    </div>

    <div [formGroup]="categoryForm?.get('buttonText')" class="paddingButtom">
      <span>Button Text</span>
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <textarea class="textarea-field" formControlName="de-ch" matInput> </textarea>
          <mat-error>Button Text is required</mat-error>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <textarea class="textarea-field" formControlName="en-us" matInput> </textarea>
          <mat-error>Button Text is required</mat-error>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <textarea class="textarea-field" formControlName="fr-ch" matInput> </textarea>
          <mat-error>Button Text is required</mat-error>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <textarea class="textarea-field" formControlName="it-ch" matInput> </textarea>
          <mat-error>Button Text is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div [formGroup]="categoryForm?.get('description')" class="paddingButtom">
      <span>Description</span>
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <textarea class="textarea-field" formControlName="de-ch" matInput> </textarea>
          <mat-error>Description is required</mat-error>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <textarea class="textarea-field" formControlName="en-us" matInput> </textarea>
          <mat-error>Description is required</mat-error>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <textarea class="textarea-field" formControlName="fr-ch" matInput> </textarea>
          <mat-error>Description is required</mat-error>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <textarea class="textarea-field" formControlName="it-ch" matInput> </textarea>
          <mat-error>Description is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div [formGroup]="categoryForm?.get('tileTitle')" class="paddingButtom">
      <span>Tile Title</span>
      <div class="row">
        <!-- de-ch -->
        <mat-form-field appearance="fill">
          <mat-label>German</mat-label>
          <textarea class="textarea-field" formControlName="de-ch" matInput> </textarea>
        </mat-form-field>

        <!-- en-us -->
        <mat-form-field appearance="fill">
          <mat-label>English</mat-label>
          <textarea class="textarea-field" formControlName="en-us" matInput> </textarea>
        </mat-form-field>

        <!-- fr-ch -->
        <mat-form-field appearance="fill">
          <mat-label>French</mat-label>
          <textarea class="textarea-field" formControlName="fr-ch" matInput> </textarea>
        </mat-form-field>

        <!-- it-ch -->
        <mat-form-field appearance="fill">
          <mat-label>Italian</mat-label>
          <textarea class="textarea-field" formControlName="it-ch" matInput> </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <mat-form-field  >
        <mat-label>Filters</mat-label>
        <mat-select formControlName="filtersIds"  multiple>
           <mat-option *ngFor="let categoryFilter of categoryFilters$ | async" 
           [value]="categoryFilter.filterId"> {{categoryFilter.title['en-us']}}</mat-option>
        </mat-select>
      </mat-form-field> 
    </div>
    <div class="upload-container">
          <ngx-file-drop dropZoneLabel="Drop 8 here"
          (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div>
              <button
              mat-raised-button
              type="button"
              [disabled]="fileReader"
              (click)="openFileSelector()"
              [class.disabled]="fileReader"
              class="upload-btn">
              Add Image
            </button>
            </div>
          </ng-template>
        </ngx-file-drop>

        <div class="add-image-div-img">
          <div [ngClass]="{'upload-rectangle': !fileReader}"></div>
          <div class="row">
            <div *ngIf="fileReader" class="col" style="margin-left: -3px;">
              <img [src]="fileReader" class="upload-image" />
              <a (click)="fileReader=undefined;" matSuffix>
                <mat-icon class="cancel-mat-icon">cancel</mat-icon>
              </a>
            </div>
          </div>
      </div>

      <div style="margin-bottom: 10px;">
        <span class="error" *ngIf="error">Error while uploading file, incompatible format or file size exceeds 15MB.</span>
      </div>
    </div>
    <button type="submit" (click)="submit()" [disabled]="categoryForm.invalid || !fileReader" color="success" mat-button>Save</button>
    <button type="submit" (click)="backToListCategoriesUrl()"  mat-raised-button style="margin-left: 10px">Cancel</button>
  </form>
</div>

import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';
import { IPointOfSaleVM } from "../../models/pointofsale.model"
@Component({
  selector: 'tsf-pos-detail-view',
  templateUrl: './pos-detail-view.component.html',
  styleUrls: ['./pos-detail-view.component.scss']
})
export class PosDetailViewComponent implements OnInit {
  pos!: IPointOfSaleVM;
  @Output() dialogClose: EventEmitter<void> = new EventEmitter();

  @Input() set pointOfSale(value: IPointOfSaleVM) {
    if(value) this.pos = value;
  }
  
  ngOnInit(): void {
  }

  emitDialogClose() {
    this.dialogClose.emit();
  }

  get Object() {
    return Object;
  }
 

  redirectToWebsite(website: string) {
    window.location.href = website

  }
}

import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { LocalStorageService } from '../../shared/services/localStorage.service'
import { Router } from '@angular/router';
import { AdminProvider } from '@modeso/tsf-lib-admin-fe';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewChecked {

  isUserLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private adminProvider: AdminProvider,
    private localStorageService: LocalStorageService,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngAfterViewChecked(): void {
    this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
    this.changeDetector.detectChanges();
  }

  onLogout() {
    this.adminProvider.logout();
    this.localStorageService.removeAuthorization();
    this.router.navigate(['/login']);
  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IPointOfSaleVM }  from '../../models/pointofsale.model';
import Debug from "debug";
import { PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { ReplaySubject, takeUntil } from 'rxjs';

const debug: Debug.Debugger = Debug("modeso:tsf-lib-pointofsales:point-of-sale-tile");

@Component({
  selector: 'tsf-point-of-sale-tile',
  templateUrl: './point-of-sale-tile.component.html',
  styleUrls: ['./point-of-sale-tile.component.scss']
})
export class PointOfSaleTileComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  @Input() pointOfSales!: IPointOfSaleVM[];
  @Input() tileTitle!: string;
  @Output() 
  pointOfSaleClick: EventEmitter<IPointOfSaleVM> = new EventEmitter();
  @Output() 
  pointOfSaleFromSearchClick: EventEmitter<IPointOfSaleVM> = new EventEmitter();
  @Output() 
  routeToPosClicked: EventEmitter<IPointOfSaleVM> = new EventEmitter();
  isInit = true;
  locationEnabled!: boolean;
  constructor(private pointOfSaleProvider: PointOfSalesProvider) {}

  onPointOfSaleClicked(pointOfSale: IPointOfSaleVM) {
    this.pointOfSaleClick.emit(pointOfSale);
  }
  onSwipe($event: any) {
     if( Math.abs($event.deltaY) > 40 && $event.deltaY > 0 ) {
       this.isInit = true;
     } else {
      this.isInit = false;
     }
  }

  onRouteToPos(pointofsale: IPointOfSaleVM) {
    this.routeToPosClicked.emit(pointofsale);
  }

  ngOnInit(): void {
    this.pointOfSaleProvider.getUserLocationState$()
    .pipe(takeUntil(this.destroyed$)).subscribe((locationEnabled) => this.locationEnabled = locationEnabled);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  

  onScroll($event:any){
    this.isInit = false;
  }
}

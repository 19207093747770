<div [ngClass]="{'desktop-row': !isMobile, 'mobile-row': isMobile}"
 (click)="onPosClicked()">
    <img [src]="pointOfSale.logo" *ngIf="pointOfSale.logo" class="point-icon" >
    <div class="point-info" [ngClass]="{'desktop-style.point-info': !isMobile}">
        <div *ngIf="!isMobile" class="point-info__category">
            {{ pointOfSale.categoryText.toUpperCase() }}
        </div>
        <div class="point-info__name" [ngClass]="{'mobile-style': isMobile}">{{pointOfSale.pointName}}</div>
        <div  class="point-info__address" [ngClass]="{'mobile-style': isMobile}">
            <ng-container *ngIf="isMobile; else notMobile;">
                {{ pointOfSale.pointAddress.street }}, 
                {{ pointOfSale.pointAddress.zipCode }} {{ pointOfSale.pointAddress.city }}
            </ng-container>
            <ng-template #notMobile>
                {{ pointOfSale.pointAddress.street }} <br>
                {{ pointOfSale.pointAddress.zipCode }} {{ pointOfSale.pointAddress.city }}
            </ng-template>
        </div>
    </div>
    <img *ngIf="isMobile"
        class="direction-row" [ngClass]="{ disabled: !locationEnabled}"  
        src="assets/twint-icons-directions.svg" (click)="routeToPos($event)"
    >
</div>